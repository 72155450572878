import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { Subject } from 'rxjs';
import { DataTableDirective } from 'angular-datatables';
import { FormGroup, FormBuilder, FormArray, AbstractControl, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { InvoiceService } from 'src/app/services/invoice.service';
import { AreaService } from './../../services/area.service';
import { CityService } from 'src/app/services/city.service';
import { StateService } from './../../services/state.service';
import { NgxUiLoaderService } from 'ngx-ui-loader';

declare var $: any;
@Component({
  selector: 'app-customer-invoice',
  templateUrl: './customer-invoice.component.html',
  styleUrls: ['./customer-invoice.component.css']
})
export class CustomerInvoiceComponent implements OnInit, OnDestroy {
  invoiceForm: FormGroup;
  Invoice: any[];
  splitInvoice: any[];
  // filter
  StateFilter: any[];
  CityFilter: any[];
  AreaFilter: any[];

  @ViewChild(DataTableDirective, { static: false })
  dtElement: DataTableDirective;
  // tslint:disable-next-line: no-inferrable-types
  isDtInitialized: boolean = false;
  dtOptions: DataTables.Settings = {};
  dtTrigger: Subject<any> = new Subject();
  ngOnDestroy(): void {
    this.dtTrigger.unsubscribe();
  }
  constructor(
    private service: InvoiceService,
    private tosterService: ToastrService,
    private fb: FormBuilder,
    private stateService: StateService,
    private cityService: CityService,
    private areaService: AreaService,
    private loader: NgxUiLoaderService,
  ) {
    this.invoiceForm = this.fb.group({
    });
  }

  ngOnInit() {
    this.dtOptions = {
      pagingType: 'full_numbers',
      pageLength: 10,
      processing: true,
      stateSave: true
    };
    this.getAllInvoice();
    this.getAllActiveState();
  }
  getAllInvoice() {
    this.service.getAllInvoice().subscribe((res: any) => {
      this.Invoice = res;
      if (this.isDtInitialized) {
        this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
          dtInstance.destroy();
          this.dtTrigger.next();
        });
      } else {
        this.isDtInitialized = true;
        this.dtTrigger.next();
      }
    });
  }
  getSplitInvoiceById(custID: number) {
    this.service.getSplitInvoiceById(custID).subscribe((res: any) => {
      this.splitInvoice = res;
    });
  }
  // filteration
  getAllActiveState() {
    this.stateService.getActiveState().subscribe(res => {
      this.StateFilter = res;
    }, err => {
      this.tosterService.error(err.message);
    });
  }
  getFactiveCity(s_id) {
    this.loader.start();
    this.AreaFilter = null;
    this.cityService.getActiveCityById(s_id).subscribe(res => {
      this.loader.stop();
      this.CityFilter = res;
    }, err => {
      this.loader.stop();
      this.tosterService.error(err.message);
    });
  }
  getFactiveArea(c_id) {
    this.loader.start();
    this.areaService.getActiveAreaByID(c_id).subscribe(res => {
      this.loader.stop();
      this.AreaFilter = res;
    }, err => {
      this.loader.stop();
      this.tosterService.error(err.message);
    });
  }
  getFilterInvoice(state, city, area, status) {
    if (state !== '' && city !== '' && area !== '' && status !== '') {
      this.loader.start();
      this.service.getFilterInvoice(state, city, area, status).subscribe(res => {
        if (this.isDtInitialized) {
          this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
            dtInstance.destroy();
            this.dtTrigger.next();
          });
        } else {
          this.isDtInitialized = true;
          this.dtTrigger.next();
        }
        this.Invoice = res;
        this.loader.stop();
      }, err => {
        this.loader.stop();
        this.tosterService.error(err.message);
      });
    } else {
      this.tosterService.info('choose all fields');
    }
  }
}
