import { FloorService } from './../../services/floor.service';
import { BlockService } from './../../services/block.service';
import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { Subject } from 'rxjs';
import { DataTableDirective } from 'angular-datatables';
import { FormGroup, FormBuilder, FormArray, AbstractControl, Validators } from '@angular/forms';
import { AreaService } from './../../services/area.service';
import { StateService } from './../../services/state.service';
import { CountryService } from './../../services/country.service';
import { CityService } from './../../services/city.service';
import { ApartmentService } from 'src/app/services/apartment.service';
import { ToastrService } from 'ngx-toastr';
import { NgxUiLoaderService } from 'ngx-ui-loader';
declare var jQuery: any;
@Component({
  selector: 'app-floor',
  templateUrl: './floor.component.html',
  styleUrls: ['./floor.component.css']
})
export class FloorComponent implements OnInit, OnDestroy {
  floorForm: FormGroup;
  @ViewChild(DataTableDirective, { static: false })
  dtElement: DataTableDirective;
  // tslint:disable-next-line: no-inferrable-types
  isDtInitialized: boolean = false;
  City: any[];
  Country: any[];
  State: any[];
  Area: any[];
  Apartment: any[];
  AppOFF: any[];
  Block: any[];
  sType = null;
  st = null;
  Floor: any[];
  // filter array
  fState: any[];
  fCity: any[];
  fArea: any[];
  fApp: any[];
  fBlock: any[];
  fsType = null;

  dtOptions: DataTables.Settings = {};
  dtTrigger: Subject<any> = new Subject();
  ngOnDestroy(): void {
    this.dtTrigger.unsubscribe();
  }
  constructor(
    private tosterService: ToastrService,
    private service: FloorService,
    private fb: FormBuilder,
    private cityService: CityService,
    private countryService: CountryService,
    private stateService: StateService,
    private areaService: AreaService,
    private apartService: ApartmentService,
    private blockService: BlockService,
    private loader: NgxUiLoaderService,
  ) {
    this.floorForm = this.fb.group({
      app_id: ['0', Validators.required],
      blk_id: ['0', Validators.required],
      floor: this.fb.array([
        this.addFloorFormgroup()
      ])
    });
  }

  ngOnInit() {
    this.dtOptions = {
      pagingType: 'full_numbers',
      pageLength: 10,
      processing: true,
      stateSave: true
    };
    this.getActiveCountry();
    this.getFActiveState();
  }
  onSubmit(formdata: any) {
    for (let index in formdata.floor) {
      if (formdata.floor[index].active === true) {
        formdata.floor[index].active = 'Yes';
      } else {
        formdata.floor[index].active = 'No';
      }
    }
    console.log(formdata);
    this.loader.start();
    this.service.addFloor(formdata).subscribe((res: any) => {
      if (res.status === true) {
        this.loader.stop();
        this.tosterService.success(res.message);
        this.floorForm.reset();
        (this.floorForm.controls['floor'] as FormArray).clear();
        this.addFloor();
        // tslint:disable-next-line:quotemark
        jQuery("#exampleModalCenter").modal("hide");
      } else {
        this.loader.stop();
        this.tosterService.success(res.message);
        this.floorForm.reset();
        (this.floorForm.controls['floor'] as FormArray).clear();
        this.addFloor();
        // tslint:disable-next-line:quotemark
        jQuery("#exampleModalCenter").modal("hide");
      }
    },
      error => {
        this.loader.stop();
        this.tosterService.error(error);
      });
  }
  addFloorFormgroup(): FormGroup {
    return this.fb.group({
      flr_number: ['', Validators.required],
      active: [false]
    });
  }
  getFloorFormControls(): AbstractControl[] {
    return (this.floorForm.get('floor') as FormArray).controls;
  }
  addFloor(): void {
    (this.floorForm.get('floor') as FormArray).push(this.addFloorFormgroup());
  }
  removeFloor(index) {
    (this.floorForm.get('floor') as FormArray).removeAt(index);
  }
  getActiveCountry() {
    this.countryService.getActiveCountry().subscribe(res => {
      this.Country = res;
    });
  }
  getActiveStateById(Cid: any) {
    this.loader.start();
    this.stateService.getActiveStateById(Cid).subscribe(res => {
      this.State = res;
      this.loader.stop();
    }, err => {
      this.loader.stop();
      this.tosterService.error('Not find state');
    });
  }
  getActiveCityById(Sid: any) {
    this.loader.start();
    this.cityService.getActiveCityById(Sid).subscribe(res => {
      this.City = res;
      this.loader.stop();
    }, err => {
      this.loader.stop();
      this.tosterService.error('Not find city');
    });
  }
  getActiveAreaById(CtId: any) {
    this.loader.start();
    this.areaService.getActiveAreaByID(CtId).subscribe(res => {
      this.Area = res;
      this.loader.stop();
    }, err => {
      this.loader.stop();
      this.tosterService.error('Not find area');
    });
  }
  getActiveAppOffById(Aid: any, aoType: string) {
    this.st = aoType;
    if (aoType == 'Apartment') {
      this.loader.start();
      this.apartService.getActiveApartmentByArea(Aid).subscribe((res: any) => {
        this.Apartment = res;
        console.log("Apartments = "+this.Apartment);
        this.loader.stop();
      }, err => {
        this.loader.stop();
        this.tosterService.error('Not find apartment');
      });
    } else if(aoType == 'Government Office') {
      this.loader.start();
      this.apartService.getActiveOfficeByArea(Aid,'GO').subscribe((res: any) => {
        this.Apartment = res;
        console.log("Apartments = "+this.Apartment);
        this.loader.stop();
      }, err => {
        this.loader.stop();
        this.tosterService.error('Not find office');
      });
    }else if(aoType == 'Private Office') {
      this.loader.start();
      this.apartService.getActiveOfficeByArea(Aid,'PO').subscribe((res: any) => {
        this.Apartment = res;
        console.log("PO = "+this.Apartment);
        this.loader.stop();
      }, err => {
        this.loader.stop();
        this.tosterService.error('Not find office');
      });
    }
    else if(aoType == 'Mall') {
      this.loader.start();
      this.apartService.getActiveOfficeByArea(Aid,'M').subscribe((res: any) => {
        this.Apartment = res;
        console.log("Mall = "+this.Apartment);
        this.loader.stop();
      }, err => {
        this.loader.stop();
        this.tosterService.error('Not find office');
      });
    }
    

  }
  getActiveBlockById(appID: any) {
    this.loader.start();
    this.blockService.getActiveBlockByAppOff(appID).subscribe((res: any) => {
      this.Block = res;
      console.log("BLOCKS = "+this.Block);
      this.loader.stop();
    }, err => {
      this.loader.stop();
      this.tosterService.error('Not find block');
    });
    
  }
  getAllActiveAppOff(aot: string) {
    this.sType = aot;
    if (aot == 'Apartment') {
      this.loader.start();
      this.apartService.getAllActiveApartment().subscribe(res => {
        this.AppOFF = res;
        this.loader.stop();
      }, err => {
        this.loader.stop();
        this.tosterService.error('Not find apartment');
      });
    } else if (aot == 'Private Office') {
      this.loader.start();
      this.apartService.getAllActiveOffice('PO').subscribe(res => {
        this.AppOFF = res;
        this.loader.stop();
      }, err => {
        this.loader.stop();
        this.tosterService.error('Not find any private office');
      });
    }else if (aot == 'Government Office') {
      this.loader.start();
      this.apartService.getAllActiveOffice('GO').subscribe(res => {
        this.AppOFF = res;
        this.loader.stop();
      }, err => {
        this.loader.stop();
        this.tosterService.error('Not find any government office');
      });
    }
  }

  // fliteration
  getFActiveState() {
    this.loader.start();
    this.stateService.getActiveState().subscribe(res => {
      this.fState = res;
      this.loader.stop();
    }, err => {
      this.loader.stop();
      this.tosterService.error(err.message);
    });
  }
  getFActiveCity(state_id: any) {
    this.fCity = null;
    this.fArea = null;
    this.fApp = null;
    this.fBlock = null;
    this.loader.start();
    this.cityService.getActiveCityById(state_id).subscribe(res => {
      this.fCity = res;
      this.loader.stop();
    }, err => {
      this.loader.stop();
      this.tosterService.error(err.message);
    });
  }
  getFActiveArea(city_id: any) {
    if (city_id === 'Select City') {
      this.fArea = null;
      this.fApp = null;
      this.fBlock = null;
      this.tosterService.info('Choose valid city !');
    } else {
      this.fArea = null;
      this.fApp = null;
      this.fBlock = null;
      this.loader.start();
      this.areaService.getActiveAreaByID(city_id).subscribe(res => {
        this.fArea = res;
        this.loader.stop();
      }, err => {
        this.loader.stop();
        this.tosterService.error(err.message);
      });
    }
  }
  getFActiveApp(aoType: any, area_id: any) {
    if (area_id === 'Select Area') {
      this.fApp = null;
      this.fBlock = null;
      this.tosterService.info('Choose area first !');
    } else {
      this.fApp = null;
      this.fBlock = null;
      this.fsType = aoType;
      if (aoType === 'Apartment') {
        this.loader.start();
        this.apartService.getActiveApartmentByArea(area_id).subscribe(res => {
          this.fApp = res;
          this.loader.stop();
        }, err => {
          this.loader.stop();
          this.tosterService.error(err.message);
        });
      } else if (aoType === 'Government Office') {
        this.loader.start();
        this.apartService.getActiveOfficeByArea(area_id, 'GO').subscribe(res => {
          this.fApp = res;
          this.loader.stop();
        }, err => {
          this.loader.stop();
          this.tosterService.error(err.message);
        });
      } else if (aoType === 'Private Office') {
        this.loader.start();
        this.apartService.getActiveOfficeByArea(area_id, 'PO').subscribe(res => {
          this.fApp = res;
          this.loader.stop();
        }, err => {
          this.loader.stop();
          this.tosterService.error(err.message);
        });
      } else if (aoType === 'Mall') {
        this.loader.start();
        this.apartService.getActiveOfficeByArea(area_id, 'M').subscribe(res => {
          this.fApp = res;
          this.loader.stop();
        }, err => {
          this.loader.stop();
          this.tosterService.error(err.message);
        });
      } else {
        this.tosterService.info('Choose valid Type !');
      }
    }
  }
  getFActiveBlock(app_id: any) {
    if (app_id === 'Select ' + this.fsType) {
      this.fBlock = null;
      this.tosterService.info('Choose valid apartment !');
    } else {
      this.fBlock = null;
      this.loader.start();
      this.blockService.getActiveBlockByAppOff(app_id).subscribe(res => {
        this.fBlock = res;
        this.loader.stop();
      }, err => {
        this.loader.stop();
        this.tosterService.error(err.message);
      });
    }
  }
  getFloorbyBlock(blk: any) {
    if (blk === 'Select Block') {
      this.tosterService.info('Select block first !');
    } else {
      this.loader.start();
      this.service.getActiveFloorByBlock(blk).subscribe(res => {
        this.Floor = res;
        this.loader.stop();
        if (this.isDtInitialized) {
          this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
            dtInstance.destroy();
            this.dtTrigger.next();
          });
        } else {
          this.isDtInitialized = true;
          this.dtTrigger.next();
        }
      }, err => {
        this.loader.stop();
        this.tosterService.error('Not find floor');
      });
    }
  }
  activateFloor(data: any) {
    const editdata = { active: 'Yes' };
    this.service.activateFloor(data.floor_id, editdata).subscribe((res: any) => {
      if (res.status) {
        this.tosterService.success('Floor updated');
        this.getFloorbyBlock(data.blk_id);
      }
    }, err => {
      this.tosterService.error('Something wrong');
    });
  }
  deActivateFloor(data: any) {
    const editdata = { active: 'No' };
    this.service.activateFloor(data.floor_id, editdata).subscribe((res: any) => {
      if (res.status) {
        this.tosterService.success('Floor updated');
        this.getFloorbyBlock(data.blk_id);
      }
    }, err => {
      this.tosterService.error('Something wrong');
    });
  }
}
