import { BlockService } from './../../services/block.service';
import { ApartmentService } from './../../services/apartment.service';
import { AreaService } from './../../services/area.service';
import { StateService } from './../../services/state.service';
import { CountryService } from './../../services/country.service';
import { CityService } from './../../services/city.service';
import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { Subject } from 'rxjs';
import { DataTableDirective } from 'angular-datatables';
import { FormGroup, FormBuilder, FormArray, AbstractControl, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { NgxUiLoaderService } from 'ngx-ui-loader';
declare var jQuery: any;
@Component({
  selector: 'app-block',
  templateUrl: './block.component.html',
  styleUrls: ['./block.component.css']
})
export class BlockComponent implements OnInit, OnDestroy {
  blockForm: FormGroup;
  @ViewChild(DataTableDirective, { static: false })
  dtElement: DataTableDirective;
  // tslint:disable-next-line: no-inferrable-types
  isDtInitialized: boolean = false;
  City: any[];
  city2: any[];
  Country: any[];
  State: any[];
  Area: any[];
  Block: any[];
  AppOff: any[];
  sType = null;
  dtOptions: DataTables.Settings = {};
  dtTrigger: Subject<any> = new Subject();
  ngOnDestroy(): void {
    this.dtTrigger.unsubscribe();
  }
  constructor(
    private tosterService: ToastrService,
    private loader: NgxUiLoaderService,
    private service: BlockService,
    private fb: FormBuilder,
    private cityService: CityService,
    private countryService: CountryService,
    private stateService: StateService,
    private areaService: AreaService,
    private apartService: ApartmentService
  ) {
    this.blockForm = this.fb.group({
      app_id: ['0', Validators.required],
      block: this.fb.array([
        this.addBlockFormgroup()
      ])
    });
  }

  ngOnInit() {
    this.dtOptions = {
      pagingType: 'full_numbers',
      pageLength: 10,
      processing: true,
      stateSave: true
    };
    this.getAllActiveCountry();
    this.getAllActiveCity();
  }
  onSubmit(formdata: any) {
    console.log(formdata);
    for (let index in formdata.block) {
      if (formdata.block[index].active === true) {
        formdata.block[index].active = 'Yes';
      } else {
        formdata.block[index].active = 'No';
      }
    }
    console.log(formdata);
    this.loader.start();
    this.service.addBlock(formdata).subscribe((res: any) => {
      console.log(res);
      if (res.status === true) {
        this.loader.stop();
        this.tosterService.success(res.message);
        this.blockForm.reset();
        (this.blockForm.controls['block'] as FormArray).clear();
        this.addArea();
        // tslint:disable-next-line:quotemark
        jQuery("#exampleModalCenter").modal("hide");
      } else {
        this.loader.stop();
        this.tosterService.success(res.message);
        this.blockForm.reset();
        (this.blockForm.controls['block'] as FormArray).clear();
        this.addArea();
        // tslint:disable-next-line:quotemark
        jQuery("#exampleModalCenter").modal("hide");
      }
    },
      error => {
        console.log(error);
        // this.tosterService.error(error);
      });
  }
  addBlockFormgroup(): FormGroup {
    return this.fb.group({
      blk_name: ['', Validators.required],
      active: [false]
    });
  }
  getBlocksFormControls(): AbstractControl[] {
    return (this.blockForm.get('block') as FormArray).controls;
  }
  addArea(): void {
    (this.blockForm.get('block') as FormArray).push(this.addBlockFormgroup());
  }
  removeBlock(index) {
    (this.blockForm.get('block') as FormArray).removeAt(index);
  }
  addBlock(data: any) {
    console.log(data);
  }

  getBlockByAppId(appID: any) {
    if (appID == 0) {
      alert('Select ' + this.sType + ' First');
    } else {
      this.loader.start();
      this.service.getBlockById(appID).subscribe((res: any) => {
        this.Block = res;
        this.loader.stop();
        if (this.isDtInitialized) {
          this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
            dtInstance.destroy();
            this.dtTrigger.next();
          });
        } else {
          this.isDtInitialized = true;
          this.dtTrigger.next();
        }
      }, err => {
        this.loader.stop();
        this.tosterService.error('Block Not Found');
      });
    }
  }
  activeBlock(data: any) {
    const editdata = { blk_id: data.blk_id, active: 'Yes' };
    this.service.activeBlock(editdata).subscribe((res: any) => {
      this.getBlockByAppId(data.app_id);
    });
  }
  deActiveBlock(data: any) {
    const editdata = { blk_id: data.blk_id, active: 'No' };
    this.service.activeBlock(editdata).subscribe((res: any) => {
      this.getBlockByAppId(data.app_id);
    });
  }
  getAllActiveCountry() {
    this.countryService.getActiveCountry().subscribe(res => {
      this.Country = res;
    });
  }
  getActiveStateById(Cid: any) {
    this.loader.start();
    this.stateService.getActiveStateById(Cid).subscribe(res => {
      this.State = res;
      this.loader.stop();
    }, err => {
      this.loader.stop();
      this.tosterService.error('Unable to find State')
    });
  }
  getActiveCityById(Sid: any) {
    this.loader.start();
    this.cityService.getActiveCityById(Sid).subscribe(res => {
      this.City = res;
      this.loader.stop();
    }, err => {
      this.loader.stop();
      this.tosterService.error('Unable to find City');
    });
  }
  getActiveAreaById(CtId: any) {
    this.loader.start();
    this.areaService.getActiveAreaByID(CtId).subscribe(res => {
      this.Area = res;
      this.loader.stop();
    }, err => {
      this.loader.stop();
      this.tosterService.error('Unable to find Area');
    });
  }
  getActiveAppOffById(Aid: any, aoType: string) {
    this.sType = aoType;
    console.log(aoType);
    if (aoType == 'Apartment') {
      this.loader.start();
      this.apartService.getActiveApartmentByArea(Aid).subscribe((res: any) => {
        this.AppOff = res;
        this.loader.stop();
      }, err => {
        this.loader.stop();
        this.tosterService.error('Unable to find Apartment');
      });
    } 
    else if (aoType == 'Government Office') {
      this.loader.start();
      this.apartService.getActiveOfficeByArea(Aid, 'GO').subscribe((res: any) => {
        this.AppOff = res;
        this.loader.stop();
      }, err => {
        this.loader.stop();
        this.tosterService.error('Unable to find Apartment');
      });
    }
    else if(aoType == 'Private Office'){
      this.loader.start();
      this.apartService.getActiveOfficeByArea(Aid,'PO').subscribe((res: any) => {
        this.AppOff = res;
        this.loader.stop();
      }, err => {
        this.loader.stop();
        this.tosterService.error('Unable to find Apartment');
      });
    }
    else if(aoType == 'Mall'){
      this.loader.start();
      this.apartService.getActiveOfficeByArea(Aid,'M').subscribe((res: any) => {
        this.AppOff = res;
        this.loader.stop();
      }, err => {
        this.loader.stop();
        this.tosterService.error('Unable to find Apartment');
      });
    }
  }
  getAllActiveCity() {
    this.cityService.getAllActiveCity().subscribe(res => {
      this.city2 = res;
    });
  }

}
