import { Injectable } from '@angular/core';
import { City } from './../models/city';

import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { environment } from './../../environments/environment';
import { catchError} from 'rxjs/operators';


@Injectable({
  providedIn: 'root'
})
export class CouponserviceService {
  
  saveUserProfile(formData: FormData): Observable<any>{
    const url = 'marketplace/add/addCoupon'; 
    return this.http.post(environment.baseUrl + '' +url,formData);
  }
  public getCity(){
    const url = 'location/activeCity'; 
   return this.http.get(environment.baseUrl + '' +url);
 }
 getallCat() {
  const url = 'marketplace/get/getProductsDetails'; 
   return this.http.get(environment.baseUrl + '' +url);
 }
 getAreaByCityId(id: any) {
  const url = 'location/activeArea/'; 
  return this.http.get(environment.baseUrl + '' +url+id);
  }
  searchCouponsFunction(p: any, q: string, r: string, s: string) {
    const url = 'marketplace/get/getCoupondetailsFilterType/';
   return this.http.get(environment.baseUrl + '' +url+p+"/"+q+"/"+r+"/"+s);
 }
 public getImage(imgid:any){
  const url = 'http://dev01.ohhpro.com/uploads/user_pics/';
   return this.http.get(url+imgid);
 }
 public getAllCouponsFunction(){
  const url = 'marketplace/get/getCoupondetailsList';
   return this.http.get(environment.baseUrl + '' +url);
 }
 public updateCouponFunction(formData: FormData): Observable<any>{
   const url = 'marketplace/edit/updateCoupon';
   return this.http.put(environment.baseUrl + '' +url,formData);
 }
 public deleteCouponFunction(id:any){
   const url = 'marketplace/delete/coupon/';
   return this.http.get(environment.baseUrl + '' +url+id);
   //environment.baseUrl + '' +url+''+"get/getCouponDetails/"+id
   //
 }
public getCouponByID(id:any){ 
   const url = 'marketplace/get/getCouponDetails/';
   return this.http.get(environment.baseUrl + '' +url+''+id);
}
//http://dev01.ohhpro.com/ohhpro_admin/admin/
//(environment.baseUrl + '' + url + Sid)
//const url = 'marketplace/';
  getAreaById(Sid: any) {
    //location/activeArea
    const url = 'location/activeArea/';
    return this.http.get<any[]>(environment.baseUrl + '' + url + Sid);
  }
  serverUrl = environment.baseUrl;
  httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json'})
  };

  constructor(private http: HttpClient) { }
  getCityById(Sid: any) {
    const url = 'location/activeCity/';
    return this.http.get<any[]>(environment.baseUrl + '' + url + Sid);
    console.log("get city Url : "+environment.baseUrl + '' + url + Sid);
  }
  addCity(data: City) {
    const url = 'location/addcities';
    console.log(data);
    return this.http.post<City>(environment.baseUrl + '' + url, data, this.httpOptions);
  }
  activeCity(data: any) {
    const url = 'location/updateCityActive/';
    return this.http.put(environment.baseUrl + '' + url + '' + data.city_id , data );
  }
  getAllCities() {
    const url = 'location/allCity';
    return this.http.get<any[]>(environment.baseUrl + '' + url);
  }
  getActiveCityById(aId: number) {
    const url = 'location/activeCity/';
    return this.http.get<any[]>(environment.baseUrl + '' + url + aId);
  }
  getAllActiveCity() {
    const url = 'location/activeCity';
    return this.http.get<any[]>(environment.baseUrl + '' + url);
  }
}
