import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AuthService } from '../auth.service';
import { ToastrService } from 'ngx-toastr';
import { NgxUiLoaderService } from 'ngx-ui-loader';
@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
  loginform: FormGroup;
  errormesaage: string;
  
  constructor(
    private formBuilder: FormBuilder,
    private authservice: AuthService,
    private tosterService: ToastrService,
    private router: Router,
    private loader: NgxUiLoaderService,
  ) { }

  ngOnInit() {
    this.loginform = this.formBuilder.group(
      {
        email: ['', Validators.required],
        password: ['', [Validators.required, Validators.minLength(6)]],
      }
    );
  }
  onSubmit(data: any) {
    
    this.loader.start();
    
    this.authservice.loginService(data).subscribe((res: any) => {
      if (res.status) {
        this.loader.stop();
        sessionStorage.setItem('Admin', JSON.stringify(res.admin));
        this.router.navigate(['/admin/dashboard']);
      } else {
        this.loader.stop();
        this.tosterService.warning(res.message);
      }
    },
      (err: any) => {
        this.loader.stop();
        this.tosterService.error(err.message);
        this.errormesaage = err.error.message;
        console.log(err.error.message);
      });
  }
}
