import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { Subject } from 'rxjs';
import { DataTableDirective } from 'angular-datatables';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { ToastrService } from 'ngx-toastr';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import Swal from 'sweetalert2';
import { DatePipe } from '@angular/common';
import { join } from 'path';
import { stringify } from '@angular/compiler/src/util';
import { environment } from 'src/environments/environment.prod';
import { StateService } from './../../services/state.service';
import { CountryService } from './../../services/country.service';
import { CityService } from './../../services/city.service';
import { ApartmentService } from 'src/app/services/apartment.service';
import * as XLSX from 'xlsx';
import * as FileSaver from 'file-saver';
import { create } from 'domain';
const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
const EXCEL_EXTENSION = '.xlsx';
@Component({
  selector: 'app-notice',
  templateUrl: './notice.component.html',
  styleUrls: ['./notice.component.css']
})
export class NoticeComponent implements OnInit, OnDestroy {
  // local variable
  Admin = JSON.parse(sessionStorage.getItem('Admin'));
  Notice: any[];
  Topic: any;
  noticeID: number;
  el:any;
  EditNotificationForm:FormGroup;
 
  top:string;
 ArrayList:Array<any>=[];
  noticeForm1 = this.fb.group({
    
    topic_name: [''],
    topic_title: ['', Validators.required],
    topic_message: ['', Validators.required],
    
  });
  

  // data-table instance
  @ViewChild(DataTableDirective, { static: false })
  dtElement: DataTableDirective;
  // tslint:disable-next-line: no-inferrable-types
  isDtInitialized: boolean = false;
  dtOptions: DataTables.Settings = {};
  dtTrigger: Subject<any> = new Subject();
  Floor: any[];
  House: any[];
  Block: any[];
  house_no1: any;
  count: number=0;
  remainingChar: number;
  length1: number;
  userFile: any;
  k1: number;
  imgURL: any;
  file: any;
  base64: string;
  imgString:string;
  name: string;
  topicNameList: any;
  cxp: Object;
  fileName='NotificationSheet.xlsx';
  data: any;
  fileToUpload: any;
  NotificationList: any;
  TopicName: any;
  Description: any;
  CompleteData: any;
  ResponseObject: any;
  ArrayPush:Array<any>=[];
  GetAds: any;
  AdvImg = environment.advertisementImage;
  GetFeature: any;
  Country: any;
  State: any;
  City: any;
  ResObj: any;
  ResObjNew: any;
  ngOnDestroy(): void {
    this.dtTrigger.unsubscribe();
  }
  constructor(
    private fb: FormBuilder,
    private loader: NgxUiLoaderService,
    private tosterService: ToastrService,
    private service: ApartmentService,
    private datepipe: DatePipe,
    private countryService:CountryService,
    private stateService: StateService,
    private cityService: CityService
  ) { 
    this.EditNotificationForm=this.fb.group({
      topic:['',Validators.required],
      description:['',Validators.required]
    })
  }
  // pipe = new DatePipe('en-US');
  ngOnInit() {
   this.getAllAdvertisementDetails();
   this.getAllFeatureList();
   this.allActiveCountry();
  }
  onFileSelect(evt) {
    var f = evt.target.files[0];
    var reader = new FileReader();
    reader.onload = (function (theFile) {
      return function (e) {
        var binaryData: any = e.target.result;
        var imgString: any = window.btoa(binaryData);
        console.log(imgString);
        document.getElementById('topic5').innerHTML = imgString;
      };
    })(f);
    reader.readAsBinaryString(f);
  }



  getAllAdvertisementDetails(){
    this.service.getAllAds().subscribe(res => {
      this.GetAds = res;
      console.log(res);
    }, err => {
      this.tosterService.error(err.message);
    });
  }

  getAllFeatureList(){
    this.service.getAllFeature().subscribe(res => {
      this.GetFeature = res;
      console.log(res);
    }, err => {
      this.tosterService.error(err.message);
    });
  }

  allActiveCountry() {
    this.countryService.getActiveCountry().subscribe((res: any) => {
      this.Country = res;
    });
  }

  getActiveStateByCountry(Cid: any) {
    this.loader.start();
    this.stateService.getActiveStateById(Cid).subscribe((res: any) => {
      this.State = res;
      this.loader.stop();
    }, err => {
      this.loader.stop();
      this.tosterService.error('Unable to get State');
    });
  }
  getActiveCityByState(Sid: any) {
    this.loader.start();
    this.cityService.getActiveCityById(Sid).subscribe((res: any) => {
      this.City = res;
      this.loader.stop();
    }, err => {
      this.loader.stop();
      this.tosterService.error('Unable to find City');
    });
  }
 addAdvertisementDetails(feature,city_topic,topic_name,topic_message,topic_time){
  
  var d = JSON.parse(city_topic);
  console.log(city_topic);

    var dart={
      topic_title : topic_name,
      topic_name : 'ohhpro-city-topic-'+d.city_id,
      topic_desc : topic_message,
      topic_image : document.getElementById("topic5").innerText,
      topic_hyperlink : "",
      topic_time : topic_time,
      topic_city : d.city_name,
      topic_redirect : feature
    }
    
    this.service.addAds(dart).subscribe(res=>{
    this.ResObj = res;
    this.getAllAdvertisementDetails();
    });
    $("#topic-name").val('');
    $("#topic-message").val('');
    $("#topic-image").val('');
    $('#feature').prop('selectedIndex',0);
    $('#country').prop('selectedIndex',0);
    $('#state').prop('selectedIndex',0);
    $('#city').prop('selectedIndex',0);
    $('#topic-time').prop('disabled', true).val('00:00');
    console.log(dart);
 }

addInstantNotification(feature,city_topic,topic_name,topic_message,topic_time,comname:any){
  
  var d = JSON.parse(city_topic);
  console.log(feature);
   if(feature == "Ohhpro_Advertisement"){
    var dart={
      topic_title : topic_name,
      topic_name : 'check-adv-1019',
      topic_desc : topic_message,
      topic_image : document.getElementById("topic5").innerText,
      topic_hyperlink : " ",
      topic_time : topic_time,
      topic_city : d.city_name,
      topic_redirect : feature,
      company_name: comname
    }
    console.log(dart);
    this.service.addInstantNotification(dart).subscribe(res=>{
      this.ResObjNew = res;
      });
      
      $("#topic-namein").val('');
      $("#topic-messagein").val('');
      $("#topic-imagein").val('');
      $('#featurein').prop('selectedIndex',0);
      $('#countryin').prop('selectedIndex',0);
      $('#statein').prop('selectedIndex',0);
      $('#cityin').prop('selectedIndex',0);
      $('#topic-timein').prop('disabled', true).val('00:00');
      console.log(dart);
   }else{
    var dartt={
      topic_title : topic_name,
      topic_name : 'check-adv-1019',
      topic_desc : topic_message,
      topic_image : document.getElementById("topic5").innerText,
      topic_hyperlink : " ",
      topic_time : topic_time,
      topic_city : d.city_name,
      topic_redirect : feature,
      company_name: "ohhpro"
    }
    this.service.addInstantNotification(dartt).subscribe(res=>{
      this.ResObjNew = res;
      });
      
      $("#topic-namein").val('');
      $("#topic-messagein").val('');
      $("#topic-imagein").val('');
      $('#featurein').prop('selectedIndex',0);
      $('#countryin').prop('selectedIndex',0);
      $('#statein').prop('selectedIndex',0);
      $('#cityin').prop('selectedIndex',0);
      $('#topic-timein').prop('disabled', true).val('00:00');
      console.log(dart);
   }
    
    
   

 }
  
 onChangeFetaure(data:any){
  console.log(data);
  if(data == "Ohhpro_Advertisement"){
    $(".comname").css("display","inline-block");
  }else{
    $(".comname").css("display","none");
  }
   
  
 }

  


  getAllNotification(){
    this.service.getAllNotification().subscribe(res => {
      this.NotificationList = res;
      console.log(res);
    }, err => {
      this.tosterService.error(err.message);
    });
  }
  GetEditInfo(data:any){
    this.CompleteData=data;
   this.TopicName=data.topic;
   this.Description=data.description;
  }
  edit_pay5(){
    $(".edit5").css("display", "inline-block");
    $(".change5").css("display", "none");
  }
  edit_pay6(){
    $(".edit6").css("display", "inline-block");
    $(".change6").css("display", "none");
  }
  EditSubmit(data:any){
    let str = this.CompleteData.created_date;
    str = str.substring(0, str.length - 9);
    let str1 = this.CompleteData.schedule_date;
    str1 = str1.substring(0, str1.length - 9);
   var xyz = new EditNotification(this.CompleteData.active,data.description,this.CompleteData.flag,this.CompleteData.flag_id,this.CompleteData.id,str1,data.topic);
   console.log(xyz);
   this.service.UpdateNotification(xyz).subscribe(res => {
    this.ResponseObject = res;
    if(res.status == true){
      document.getElementById("addProductCloseButton85").click();
      this.getAllNotification();
    }
    
    // this.loader.stop();
    console.log(res);
  }, err => {
    // this.loader.stop();
    this.tosterService.error(err.message);
  });
  }
  getTopicNames1(){
    // this.loader.start();
    this.service.getTopicNames().subscribe(res => {
      this.topicNameList = res;
      console.log("this.topicNameList = " +this.topicNameList[1]);
      // this.loader.stop();
      console.log(res);
    }, err => {
      // this.loader.stop();
      this.tosterService.error(err.message);
    });
  }

  addNotice1(data: any) {
  
      console.log(data);
      
      this.loader.start();
      
     
     
      this.service.sendMessage(data).subscribe((res: any) => {

        console.log("Res ========================== " + res.status);
        this.cxp=res;
        if (res.status) {
          this.loader.stop();
          this.noticeForm1.reset();
          const Toast = Swal.mixin({
            toast: true,
            position: 'top-end',
            showConfirmButton: true,
            timer: 12000,
            timerProgressBar: true,
            onOpen: (toast) => {
              toast.addEventListener('mouseenter', Swal.stopTimer);
              toast.addEventListener('mouseleave', Swal.resumeTimer);
            }
          });
     
          Toast.fire({
            icon: 'success',
            // res.message+data.topic_name
            title: data.topic_name.includes("ohhpro-apartment-customer-topic-")?res.message+" to all Apartment Users":
                   data.topic_name.includes("ohhpro-apartment-admin-topic-")?res.message+" to all Apartment Admins":

                   data.topic_name.includes("ohhpro-office-user-topic-")?res.message+" to all Office Users":
                   data.topic_name.includes("ohhpro-office-admin-topic-")?res.message+" to all Office Admins":

                   data.topic_name.includes("ohhpro-signaller-topic-")?res.message+" to all Signallers":res.message+" to all Maintenance Managers"
                  
          });
        }
    //  location.reload();
       
      }, err => {
       
        Swal.fire({
          title: 'Error!',
          text: err.message+data.topic_name,
          icon: 'error',
          width: '450px'
        });
      });
    }
    excelDetails(){
      let element = document.getElementById('Notification-Table'); 
      const ws: XLSX.WorkSheet =XLSX.utils.table_to_sheet(element);
      const wb: XLSX.WorkBook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
      XLSX.writeFile(wb, this.fileName);
    }
 onFileChange(evt: any) {
  const target: DataTransfer = <DataTransfer>(evt.target);
  if (target.files.length != 1) throw new Error("cannot use multiple files");

  const reader: FileReader = new FileReader();
  reader.onload = (e: any) => {
    const bstr: string = e.target.result;
    const wb: XLSX.WorkBook = XLSX.read(bstr, { type: 'binary' });
    const wsname: string = wb.SheetNames[0];
    const ws: XLSX.WorkSheet = wb.Sheets[wsname];
    console.log(ws);
    this.data = (XLSX.utils.sheet_to_json(ws, { header: 1 }));
    this.loader.start();
    // for (var i = 1; i < this.data.length; i++) {
    //   var topic :string="";
    //   var description:string="";
    //   var scheduled_date:string="";
    //   var flag_id:any;
    //   var flag:string="";

    //   for (var k = 0; k < 1; k++) {topic = this.data[i][k];}
    //   for (var k = 1; k < 2; k++) {description = this.data[i][k];}
    //   for (var k = 2; k < 3; k++) {scheduled_date = this.data[i][k];}
    //   for (var k = 3; k < 4; k++) {flag_id = this.data[i][k];}
    //   for (var k = 4; k < 5; k++) {flag = this.data[i][k];}
    //   var xyz = new UploadNotice(topic,description,scheduled_date,flag_id,flag);
    //   if(flag_id == null || flag_id == 0 || flag_id == ""){

    //   }else{
    //     this.ArrayList.push(xyz);
    //   }
      
    // }
    var fd = new FormData();
    var newd =  fd.append('file', evt);
    console.log(newd);
    this.loader.stop();
  };
  reader.readAsBinaryString(target.files[0]);
}
handleFileInput(evt: any){
  this.ArrayPush.splice(0);
    const target: DataTransfer = <DataTransfer>(evt.target);
    if (target.files.length != 1) throw new Error("cannot use multiple files");

    const reader: FileReader = new FileReader();
    reader.onload = (e: any) => {
      const bstr: string = e.target.result;
      const wb: XLSX.WorkBook = XLSX.read(bstr, { type: 'binary' });
      const wsname: string = wb.SheetNames[0];
      const ws: XLSX.WorkSheet = wb.Sheets[wsname];
      console.log(ws);
      this.data = (XLSX.utils.sheet_to_json(ws, { header: 1 }));
      
      console.log(this.data.length);
      for (var i = 1; i < this.data.length; i++) {
        var topic: any;
        var description:any;
        var schedule_date: any;
        var flag_id: any;
        var flag: any;
        
        for (var k = 0; k < 1; k++) {topic = this.data[i][k];}
        for (var k = 1; k < 2; k++) {description = this.data[i][k];}
        for (var k = 2; k < 3; k++) {schedule_date = this.data[i][k];}
        for (var k = 3; k < 4; k++) {flag_id = this.data[i][k];}
        for (var k = 4; k < 5; k++) {flag = this.data[i][k];}
     
        var newDate = new Date((schedule_date-25569)*86400000);
        newDate.setHours(newDate.getHours() - 6);
        newDate.setMinutes(newDate.getMinutes() + 30);
        console.log(newDate);
        let latest_date =this.datepipe.transform(newDate, 'yyyy-MM-dd H:mm:ss');
        var newDta = new UploadExcelSheet(topic,description,latest_date,flag_id,flag);
        if(topic == null || topic == ""){

        }else{
          this.ArrayPush.push(newDta);
        }
        
        // console.log(newDta);
        
      }
      
    };
    reader.readAsBinaryString(target.files[0]);
}
  
uploadFile(): void {
  console.log(this.ArrayPush);
  this.loader.start();
        this.service.UploadNotification(this.ArrayPush).subscribe((res: any) => {
          console.log(res);
          this.getAllNotification();
          this.loader.stop();
          this.tosterService.success(res.message);
        },
          error => {
            this.loader.stop();
            this.tosterService.error("Unable to add data")
          });
    }
UploadData(){
  
}
  // onFileSelect(evt) {
  //   var f = evt.target.files[0]; 
  //   var reader = new FileReader();
    
  //   reader.onload = (function(theFile) {
      
  //     return function(e) {
  //       var binaryData = e.target.result;
        
  //       this.imgString = window.btoa(binaryData);
  //       console.log(this.imgString);
  //       document.getElementById('topic1').innerHTML =this.imgString;
        
  //     };
  //   })(f);
  //   reader.readAsBinaryString(f);
  //   console.log("out side "+document.getElementById("topic1").innerText);
    
  // }
  // onFileSelect2(evt) {
  //   var f = evt.target.files[0]; 
  //   var reader = new FileReader();
    
  //   reader.onload = (function(theFile) {
      
  //     return function(e) {
  //       var binaryData = e.target.result;
        
  //       this.imgString = window.btoa(binaryData);
  //       console.log(this.imgString);
  //       document.getElementById('topic2').innerHTML =this.imgString;
        
  //     };
  //   })(f);
  //   reader.readAsBinaryString(f);
  //   console.log("out side "+document.getElementById("topic2").innerText);
    
  // }
  // onFileSelect3(evt) {
  //   var f = evt.target.files[0]; 
  //   var reader = new FileReader();
    
  //   reader.onload = (function(theFile) {
      
  //     return function(e) {
  //       var binaryData = e.target.result;
        
  //       this.imgString = window.btoa(binaryData);
  //       console.log(this.imgString);
  //       document.getElementById('topic3').innerHTML =this.imgString;

  //     };
  //   })(f);
  //   reader.readAsBinaryString(f);
  //   console.log("out side "+document.getElementById("topic3").innerText);
  // }
  // onFileSelect4(evt) {
  //   var f = evt.target.files[0]; 
  //   var reader = new FileReader();
    
  //   reader.onload = (function(theFile) {
      
  //     return function(e) {
  //       var binaryData = e.target.result;
        
  //       this.imgString = window.btoa(binaryData);
  //       console.log(this.imgString);
  //       document.getElementById('topic4').innerHTML =this.imgString;

  //     };
  //   })(f);
  //   reader.readAsBinaryString(f);
  //   console.log("out side "+document.getElementById("topic4").innerText);
  // }
  // getBlockByApp() {
  //   this.loader.start();
  //   this.service.getBlockByApp(this.Admin.assigned_infra).subscribe(res => {
  //     this.Block = res;
  //     this.loader.stop();
  //     console.log(res);
  //   }, err => {
  //     this.loader.stop();
  //     this.tosterService.error(err.message);
  //   });
  // }
  // getFloorByBlk(blk: number) {
  //   this.loader.start();
  //   this.service.getFloorByBlock(blk).subscribe(res => {
  //     this.Floor = res;
  //     this.House = [];
      
  //     this.loader.stop();
  //   }, err => {
  //     this.loader.stop();
  //     this.tosterService.error(err.message);
  //   });
  // }
  // gethouseByflr(fid: number) {
  //   this.loader.start();
  //   this.service.getHouseByFloor(fid).subscribe(res => {
  //     this.House = res;
      
  //     this.loader.stop();
  //   }, err => {
  //     this.loader.stop();
  //     this.tosterService.error(err.message);
  //   });
  // }
  
  // imageNme(topic_image){
  //   this.imageForm1.patchValue({
  //     topicName1: this.imageDirectoryPath1+topic_image
  //   });
  // }
  // getNotice() {
  //  this.Notice=[];
  //   this.service.getNotice(this.Admin.assigned_infra).subscribe(res => {
   
  //   this.Notice = res.reverse();
  //     console.log(res);
  //     if (this.isDtInitialized) {
  //       this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
  //         dtInstance.destroy();
  //         this.dtTrigger.next();
  //       });
  //     } else {
  //       this.isDtInitialized = true;
  //       this.dtTrigger.next();
  //     }
  //     this.loader.stop();
  //   }, err => {
  //     this.loader.stop();
  //     this.tosterService.error(err.message);
  //   });
  // }
  
  // addNotice1(data: any) {
  
  //   console.log(data);
  //   console.log("out side============= "+document.getElementById("topic1").innerText);
  //   this.loader.start();
  //   const user= data.value;
  //   const formData=new FormData();
  //   console.log(this.base64);
  //   const noticeData1 = {
  //     app_id: this.Admin.assigned_infra,
  //     created_by_user_id: this.Admin.user_id,
  //     topic_name: "customer-topic-"+this.Admin.assigned_infra,
  //     topic_title: data.topicTitle,
  //     topic_message: data.topicMsg,
  //     topic_image: document.getElementById("topic1").innerText
  //   };
  //   console.log(noticeData1);
  //   this.service.addNotice1(noticeData1).subscribe(res => {
  //     if (res.status) {
  //       this.loader.stop();
  //       this.noticeForm1.reset();
  //       const Toast = Swal.mixin({
  //         toast: true,
  //         position: 'top-end',
  //         showConfirmButton: false,
  //         timer: 3000,
  //         timerProgressBar: true,
  //         onOpen: (toast) => {
  //           toast.addEventListener('mouseenter', Swal.stopTimer);
  //           toast.addEventListener('mouseleave', Swal.resumeTimer);
  //         }
  //       });
   
  //       Toast.fire({
  //         icon: 'success',
  //         title: res.message
  //       });
  //     }
  //  location.reload();
     
  //   }, err => {
     
  //     Swal.fire({
  //       title: 'Error!',
  //       text: err.message,
  //       icon: 'error',
  //       width: '450px'
  //     });
  //   });
  // }
  gethouseId(sel){
    console.log("house id"+sel);
    // alert(sel.options[sel.selectedIndex].text);
    this.house_no1=sel.options[sel.selectedIndex].text;
    // alert(this.house_no1);
  }
  // addNotice2(data: any ,block_id,floor_id,house) {
  //   console.log("house = "+JSON.stringify(house))
  //   this.loader.start();
   
  //   const noticeData2 = {
  //     app_id: this.Admin.assigned_infra,
  //     block_id:block_id,
  //     floor_id:floor_id,
  //     house_number:this.house_no1,
    
  //     created_by_user_id: this.Admin.user_id,
  //     topic_name: "house-topic-"+house,
  //     topic_title: data.topicTitle,
  //     topic_message: data.topicMsg,
  //     topic_image: document.getElementById("topic2").innerText
      
  //   };
  //   console.log(noticeData2)
  //   this.service.addNotice2(noticeData2).subscribe(res => {
  //     console.log("noticeData"+noticeData2);
      
  //     if (res.status) {
  //       this.loader.stop();
  //       this.noticeForm2.reset();
  //       const Toast = Swal.mixin({
  //         toast: true,
  //         position: 'top-end',
  //         showConfirmButton: false,
  //         timer: 3000,
  //         timerProgressBar: true,
  //         onOpen: (toast) => {
  //           toast.addEventListener('mouseenter', Swal.stopTimer);
  //           toast.addEventListener('mouseleave', Swal.resumeTimer);
  //         }
  //       });

  //       Toast.fire({
  //         icon: 'success',
    
  //         title: res.message
  //       });
  //     }
  // location.reload();
  //     // this.getNotice();
  //   }, err => {
  //     // @ts-ignore
  //     Swal.fire({
  //       title: 'Error!',
  //       text: err.message,
  //       icon: 'error',
  //       width: '450px'
  //     });
  //   });
  // }
  // addNotice3(data: any ) {
  //   this.loader.start();
  //   const noticeData3 = {
  //     app_id: this.Admin.assigned_infra,
  //     created_by_user_id: this.Admin.user_id,
  //     topic_name: "management-topic-"+this.Admin.assigned_infra,
  //     topic_title: data.topicTitle,
  //     topic_message: data.topicMsg,
  //     topic_image: document.getElementById("topic3").innerText
  //   };
  //   this.service.addNotice3(noticeData3).subscribe(res => {
  //     // @ts-ignore
  //     if (res.status) {
  //       this.loader.stop();
  //       this.noticeForm3.reset();
  //       const Toast = Swal.mixin({
  //         toast: true,
  //         position: 'top-end',
  //         showConfirmButton: false, 
  //         timer: 3000,
  //         timerProgressBar: true,
  //         onOpen: (toast) => {
  //           toast.addEventListener('mouseenter', Swal.stopTimer);
  //           toast.addEventListener('mouseleave', Swal.resumeTimer);
  //         }
  //       });
  //       // @ts-ignore
  //       Toast.fire({
  //         icon: 'success',
  //         // @ts-ignore
  //         title: res.message
  //       });
  //     }
  //     location.reload();
  //     // this.getNotice();
  //   }, err => {
  //     // @ts-ignore
  //     Swal.fire({
  //       title: 'Error!',
  //       text: err.message,
  //       icon: 'error',
  //       width: '450px'
  //     });
  //   });
  // }
  // onEditClick(data: any) {
  //   this.loader.start();
  //   console.log("TOPIC IMAGE = "+data.topic_image);
  //   this.editNoticeForm.patchValue({
  //     edittopicName: data.topic_name,
  //     edittopicTitle: data.topic_title,
  //     edittopicMsg: data.topic_message,
  //     notificationID: data.notification_id,
  //     createdBy: data.created_by_user_id
  //   });
  //   // this.getNotice();
  //   this.loader.stop();
  // }
  // updateNotice(data: any) {
 

  //   const editNoticeData = {
  //     app_id:this.Admin.assigned_infra,
  //     created_by_user_id:this.Admin.user_id,
  //     notification_id: data.notificationID,
  //     topic_name: data.edittopicName,
  //     topic_image:document.getElementById("topic4").innerText,
  //     topic_title: data.edittopicTitle,
  //     topic_message: data.edittopicMsg,
  //   };
  //   this.loader.start();
  //   this.service.editNotice(editNoticeData).subscribe(res => {
  //     // @ts-ignore
  //     if (res.status) {
  //       this.editNoticeForm.reset();
  //       this.loader.stop();
  //       const Toast = Swal.mixin({
  //         toast: true,
  //         position: 'top-end',
  //         showConfirmButton: false,
  //         timer: 3000,
  //         timerProgressBar: true,
  //         onOpen: (toast) => {
  //           toast.addEventListener('mouseenter', Swal.stopTimer);
  //           toast.addEventListener('mouseleave', Swal.resumeTimer);
  //         }
  //       });
  //       // @ts-ignore
  //       Toast.fire({
  //         icon: 'success',
  //         // @ts-ignore
  //         title: res.message
  //       });
        
  //     }
  //   }, err => {
  //     this.loader.stop();
  //     // @ts-ignore
  //     Swal.fire({
  //       title: 'Error!',
  //       text: err.message,
  //       icon: 'error',
  //       width: '450px'
  //     });
  //   });
  //   // this.getNotice();
  // location.reload();
  // }
  // getTopicNotice() {
  //   // this.loader.start();
  //   this.service.getNoticeTopic(this.Admin.assigned_infra).subscribe(res => {
      
  //     this.noticeForm1.patchValue({
  //       // @ts-ignore
  //       topicName: res.customer_notification_topic,
  //     });
  //   });
  // }
  // onClickDelete(data: any) {
  //   this.loader.start();
  //   this.noticeID = data.notification_id;
  //   this.service.deleteNotice(this.noticeID).subscribe((res: any) => {
  //     if (res.status) {
  //       const Toast = Swal.mixin({
  //         toast: true,
  //         position: 'top-end',
  //         showConfirmButton: false,
  //         timer: 3000,
  //         timerProgressBar: true,
  //         onOpen: (toast) => {
  //           toast.addEventListener('mouseenter', Swal.stopTimer);
  //           toast.addEventListener('mouseleave', Swal.resumeTimer);
  //         }
  //       });
      
  //       // this.getNotice();
  //       // @ts-ignore
  //       Toast.fire({
  //         icon: 'success',
  //         // @ts-ignore
  //         title: res.message
  //       });
  //       location.reload();
  //     }
  //     else{
  //       const Toast1 = Swal.mixin({
  //         toast: true,
  //         position: 'top-end',
  //         showConfirmButton: false,
  //         timer: 3000,
  //         timerProgressBar: true,
  //         onOpen: (toast) => {
  //           toast.addEventListener('mouseenter', Swal.stopTimer);
  //           toast.addEventListener('mouseleave', Swal.resumeTimer);
  //         }
  //       });
  //     }
  //   }, err => {
      
  //     // @ts-ignore
  //     Swal.fire({
  //       title: 'Error!',
  //       text: err.message,
  //       icon: 'error',
  //       width: '450px'
  //     });
  //   });
    
  //   this.loader.stop();
  // }

}
 
export class UploadNotice{
  topic:string;
  description:string;
  schedule_date:string;
  flag_id:number;
  flag:string;
  constructor(topic:string,description:string,schedule_date:string,flag_id:number,flag:string){
   this.topic=topic;
   this.description=description;
   this.schedule_date=schedule_date;
   this.flag_id=flag_id;
   this.flag=flag;
  }
}
export class EditNotification{
  active: string;
  description: string;
  flag: string;
  flag_id: number;
  id: number;
  schedule_date: string;
  topic: string;
  constructor(active: string,description: string,flag: string,flag_id: number,id: number,   schedule_date: string,topic: string){
  this.active=active;
  this.description=description;
  this.flag=flag;
  this.flag_id=flag_id;
  this.id=id;
  this.schedule_date=schedule_date;
  this.topic=topic;
  }

}
export class UploadExcelSheet{
topic:String;
description:String;
schedule_date:String;
flag_id:number;
flag:String;
constructor(topic:String,description:String,schedule_date:String,flag_id:number, flag:String){
  
  this.topic=topic;
  this.description=description;
  this.schedule_date=schedule_date;
  this.flag_id=flag_id;
  this.flag=flag;
}
}

