import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { environment } from './../../environments/environment';
import { tap, catchError } from 'rxjs/operators';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { ToastrService } from 'ngx-toastr';
@Injectable({
  providedIn: 'root'
})
export class JobberService {
  httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' })
  };
  constructor(
    private http: HttpClient,
    private loader: NgxUiLoaderService,
  ) { }
  getJobbers() {
    const url = 'jobber/allJobberBasicDetails';
    return this.http.get(environment.baseUrl + '' + url);
  }
  getJobberById(jID: any) {
    const url = 'jobber/jobberBasicDetails/';
    return this.http.get(environment.baseUrl + '' + url + jID);
  }
  activeJobber(data: any) {
    const url = 'jobber/updateJobberActive/';
    return this.http.put(environment.baseUrl + '' + url + '' + data.jobber_id, data);
  }
  updateJobber(data: any) {
    const url = 'jobber/updateJobberDetails/';
    return this.http.put(environment.baseUrl + '' + url + data.jobber_id, data);
  }
  // jobber filterartion service
  getFirstFilter(state, city, area) {
    const url = 'jobber/JobberDetail/';
    return this.http.get<any>(environment.baseUrl + url + state + '/' + city + '/' + area);
  }
  getSecondFilter(state, city, area, skill) {
    const url = 'jobber/JobberDetailBySkill/';
    return this.http.get<any>(environment.baseUrl + url + state + '/' + city + '/' + area + '/' + skill);
  }
  getThirdFilter(state, city, area, is_active) {
    const url = 'jobber/JobberDetailByIsActive/';
    return this.http.get<any>(environment.baseUrl + url + state + '/' + city + '/' + area + '/' + is_active);
  }
  getFourthFilter(state, city, area, skill, is_active, medical) {
    const url = 'jobber/jobberfilterByMedicalCheck/';
    return this.http.get<any>(environment.baseUrl + url + state + '/' + city + '/' + area + '/' + skill + '/' + is_active + '/' + medical);
  }
  getFiveFilter(state, city, area, skill, is_active, police) {
    const url = 'jobber/jobberfilterByPolice/';
    return this.http.get<any>(environment.baseUrl + url + state + '/' + city + '/' + area + '/' + skill + '/' + is_active + '/' + police);
  }
  getSixFilter(state, city, area, skill, is_active, bank) {
    const url = 'jobber/jobberfilterByAccountCheck/';
    return this.http.get<any>(environment.baseUrl + url + state + '/' + city + '/' + area + '/' + skill + '/' + is_active + '/' + bank);
  }
  getSevenFilter(state, city, area, skill, is_active, medical, police, bank) {
    const url = 'jobber/jobberfilter/';
    return this.http.get<any>(environment.baseUrl + url + state + '/' + city + '/' + area + '/' + skill + '/' + is_active +
      '/' + medical + '/' + police + '/' + bank);
  }

  // skill api
  getAllSkill() {
    const url = 'jobber/allSkill';
    return this.http.get<any>(environment.baseUrl + url);
  }

  // upload api
  uploadIdDrivingLicence(data:any) {
    const url = 'jobber/updateDLimage';
    // const formData = new FormData();
    // formData.append('file', drivingFile, drivingFile.name);
    // console.log(drivingFile);
    return this.http.post(environment.baseUrl + '' + url ,data).pipe(tap(res => {
      this.loader.stop();
      console.log('Upload', res);
    }, err => {
      this.loader.stop();
    }));
  }
  uploadIdMedicalReport(data:any) {
    const url = 'jobber/updateMedicalReportimage';
    // const formData = new FormData();
    // formData.append('file', medicalFile, medicalFile.name);
    // console.log(medicalFile);
    return this.http.post(environment.baseUrl + '' + url ,data).pipe(tap(res => {
      this.loader.stop();
      console.log('Upload', res);
    }, err => {
      this.loader.stop();
    }));
  }
  uploadIdApprovedDoc(data:any) {
    const url = 'jobber/updatedocimage';
    // const formData = new FormData();
    // formData.append('file', doc, doc.name);
    // console.log(doc);
    return this.http.put(environment.baseUrl + '' + url ,data).pipe(tap(res => {
      this.loader.stop();
      console.log('Upload', res);
    }, err => {
      this.loader.stop();
    }));
  }
  openAccount(jobber_id: number) {
    const url = 'createAccountInRazorPay/';
    return this.http.post(environment.razorpayUrl + url + jobber_id, '', this.httpOptions);
  }
  checkDuplicate(data: any) {
    const url = 'lr/edit/profile/checkDuplicateJobberProfile';
    return this.http.put(environment.baseUrl + url, data).pipe(tap(res => {
      this.loader.stop();
    }, err => {
      this.loader.stop();
    }));
  }
  // otp send
  sendPhoneOTP(phoneNumber: number) {
    const url = 'lr/OTP/';
    return this.http.get(environment.baseUrl + '' + url + phoneNumber);
  }
  sendEmailOTP(Email: string) {
    const url = 'lr/emailotp';
    const mail = { email: Email };
    return this.http.post(environment.baseUrl + '' + url, mail);
  }
}
