import { HouseService } from './../../services/house.service';
import { FloorService } from './../../services/floor.service';
import { BlockService } from './../../services/block.service';
import { AreaService } from './../../services/area.service';
import { CityService } from './../../services/city.service';
import { StateService } from './../../services/state.service';
import { CountryService } from './../../services/country.service';
import { ApartmentService } from './../../services/apartment.service';
import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { DataTableDirective } from 'angular-datatables';
import { FormGroup, FormBuilder, FormArray, AbstractControl, FormControl, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { SubAdminService } from 'src/app/services/sub-admin.service';
import { BsDatepickerConfig } from 'ngx-bootstrap/datepicker';
import { take, first, tap, delay } from 'rxjs/operators';
import { forkJoin, Subject, Observable, timer } from 'rxjs';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { DatePipe } from '@angular/common';
import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';
import { Router } from '@angular/router';
import { ElementRef } from '@angular/core';
import { log } from 'console';

export interface ISubadmin {
  fname: string;
  mname: string;
  lname: string;
  dob: any;
  country: string;
  state: string;
  city: string;
  area: string;
  pin: string;
  residential_type: string;
  assigned_infra: string;
  blk_id: string;
  flr_id: string;
  house_no: string;
  street: string;
  phone: string;
  email: any;
  password: any;
}
declare var jQuery: any;
@Component({
  selector: 'app-sub-admin',
  templateUrl: './sub-admin.component.html',
  styleUrls: ['./sub-admin.component.css']
})
export class SubAdminComponent implements OnInit, OnDestroy {
  [x: string]: any;
  phoneOtp: number;
  mailOtp: number;
  phone = null;
  email= null;
  phoneR: any;
  emailR: any;
  admin: number;
  document: File = null;
  datePickerConfig: Partial<BsDatepickerConfig>;
  timeLeft = 180;
  interval: any;
  adminForm: FormGroup;
  createForm: FormGroup;
  otpForm: FormGroup;
  fShow = true;
  // otp related
  admin_id: number;
  editEmail: any;
  editPhone: any;
  otpStatus = false;
  eEOTP = true;
  ePOTP = true;
  @ViewChild(DataTableDirective, { static: false })
  
  @ViewChild('userFile',{ static: false }) 
  myInputVariable: ElementRef;

  dtElement: DataTableDirective;
  dtElement1: DataTableDirective;
  // tslint:disable-next-line: no-inferrable-types
  isDtInitialized: boolean = false;
  isDtInitialized1: boolean = false;
  radioType = '';
  newAdmin: ISubadmin;
  Admin: any[];
  Active: any;
  Apartment: any[] = null;
  cApartment: any[] = null;
  appOffice: any[];
  Country: any[];
  State: any[];
  City: any[];
  Area: any[];
  // filter
  StateFilter: any[];
  CityFilter: any[];
  AreaFilter: any[];
  // inside apartment
  apartList: any[];
  blockList: any[];
  floorList: any[];
  houseList: any[];
  type = null;
  dtOptions: DataTables.Settings = {};
  dtTrigger: Subject<any> = new Subject();
  dtOptions1: DataTables.Settings = {};
  dtTrigger1: Subject<any> = new Subject();
  VarDate: any;
  date:Date = new Date();
  conv_date: any;
  // image jiba pai karichi
  public userFile:any = File;
  imageDirectoryPath:any='https://ohhpro.com:8443/uploads/user_docs/';
  // imageDirectoryPath:any='http://ohhpro.com:9080/uploads/user_docs/';
  public  imagePath;
  imgURL: any;
  


  ngOnDestroy(): void {
    this.dtTrigger.unsubscribe();
  }
  constructor(
    public datepipe: DatePipe, private router: Router, 
    private fb: FormBuilder,
    private loader: NgxUiLoaderService,
    private tosterService: ToastrService,
    private service: SubAdminService,
    private ApartService: ApartmentService,
    private countryService: CountryService,
    private stateService: StateService,
    private cityService: CityService,
    private areaService: AreaService,
    private blockService: BlockService,
    private floorService: FloorService,
    private houseService: HouseService
  ) {
    const maxdate = new Date();
    maxdate.setFullYear(maxdate.getFullYear() - 18);
    this.datePickerConfig = Object.assign({},
      {
        containerClass: 'theme-dark-blue',
        showWeekNumbers: false,
        dateInputFormat: 'DD-MM-YYYY',
        maxDate: maxdate,
      });

    this.adminForm = this.fb.group({
      user_id: [''],
      fname: [' ', Validators.required],
      mname: ['null'],
      lname: [' ', Validators.required],
      email: ['', [Validators.email, Validators.pattern("^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\\.[A-Za-z]{2,50}$"),
              Validators.required], this.chkEditEmail.bind(this)],
      phone: ['', [Validators.required, Validators.pattern('^[0-9]*$'), Validators.minLength(10),
              Validators.maxLength(10)], this.chkEditPhone.bind(this)],
      assigned_infra: [''],
      app_name: [''],
      app_type: [''],
      active: [''],

    });

    this.createForm = this.fb.group({
      fname: ['',[Validators.required]],
      mname: [''],
      lname: [''],
      dob:['',[Validators.required]],

      phone: ['', [Validators.required, Validators.pattern('^[0-9]*$'), Validators.minLength(10),
      Validators.maxLength(10)], this.chkPhone.bind(this)],
      email: ['', [Validators.pattern("^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\\.[A-Za-z]{2,50}$"),
      Validators.required], this.chkEmail.bind(this)],
      house_no: [''],
      single_house: [''],
      street: [''],

      country: ['0'],
      state: ['0'],
      city: ['0'],
      area: ['0'],
      pin: [''],
      residential_type: ['Type'],
      assigned_infra: ['0'],
      blk_id: [0],
      flr_id: [0],

      password: ['', [Validators.required]],
      proof_of_residence:['', [Validators.required]]
    });

    this.otpForm = this.fb.group({
      otpPhone: [''],
      otpEmail: ['']
    });
  }

  ngOnInit() {
    console.log("showHide1 "+this.showHide1);
    console.log("email : "+this.email);
    this.createForm.reset();
    this.dtOptions = {
      pagingType: 'full_numbers',
      pageLength: 10,
      processing: true,
      stateSave: true,
      
    };
    this.getAdmin();
    this.getAllActiveCountry();
    this.getAllActiveState();
    {
      document.getElementById("tab1").style.display="none"; 
      document.getElementById("tab2").style.display="none";
      document.getElementById("tab3").style.display="none";
      document.getElementById("tab4").style.display="none";
      document.getElementById("tab5").style.display="none";
    } 
  }

  onSelectFile(event){
    if(event.target.files.length>0){
    const file= event.target.files[0];
   console.log(file);
    this.userFile= file;

    var reader = new FileReader();

    this.imgURL= this.file ;    //add index signature,otherwise it was showing error .did it by quick fix 2nd option
    reader.readAsDataURL(this.userFile);
    reader.onload=(event:any) => {
      this.imgURL=event.target.result;
      console.log("URL = "+this.imgURL);
      console.log("url");
    }
    }
    // when you done with process - clear the file
   this.myInputVariable.nativeElement.value = "";
  }
  startTimer() {
    this.interval = setInterval(() => {
      if (this.timeLeft > 0) {
        this.timeLeft--;
      } else {
        this.timeLeft = 0;
      }
    }, 1000);
  }
  datefilter(Date: any) {
    this.VarDate = Date;
    let conv_date = this.datepipe.transform(this.VarDate, 'dd-M-y');
    console.log(this.conv_date);
    return conv_date;
  }
  getAdmin() {
    this.loader.start();
    this.service.getAdmin().subscribe((res: any) => {
      this.Admin = res;
      console.log(res);
      this.loader.stop();
      if (this.isDtInitialized) {
        this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
          dtInstance.destroy();
          this.dtTrigger.next();
        });
      } else {
        this.isDtInitialized = true;
        this.dtTrigger.next();
      }
    }, err => {
      this.loader.stop();
      this.tosterService.error('Not find Admin');
    });
  }
  activeAdmin(data: any) {
    const editdata = { user_id: data.user_id, active: 'Yes' };
    this.loader.start();
    this.service.activeAdmin(editdata).subscribe((res: any) => {
      // this.getAdmin();
      this.Admin.find(a => a.user_id === data.user_id).active = 'Yes';
      this.loader.stop();
      this.tosterService.success(res.message);
    }, err => {
      this.loader.stop();
      this.tosterService.error(err.message);
    });
  }
  deActiveAdmin(data: any) {
    const editdata = { user_id: data.user_id, active: 'No' };
    this.loader.start();
    this.service.activeAdmin(editdata).subscribe((res: any) => {
      // this.getAdmin();
      this.Admin.find(a => a.user_id === data.user_id).active = 'No';
      this.loader.stop();
      this.tosterService.success(res.message);
    }, err => {
      this.loader.stop();
      this.tosterService.error(err.message);
    });
  }
  getAdminById(data: any) {
    clearInterval(this.interval);
    this.timeLeft = 120;
    this.otpStatus = false;
    this.eEOTP = true;
    this.ePOTP = true;
    this.loader.start();
    this.admin_id = data.user_id;
    this.editEmail = data.email;
    this.editPhone = data.phone;
    this.otpForm.reset();
    this.service.getAdminById(data.user_id).subscribe((res: any) => {
      this.loader.stop();
      if (res.active === 'Yes') {
        this.Active = true;
      } else {
        this.Active = '';
      }
      if (res.app_type === 'A') {
        this.type = 'Apartment';
      } else if (res.app_type === 'GO'){
        this.type = 'Government Office';
      }else if (res.app_type === 'PO'){
        this.type = 'Private Office';
      }else if (res.app_type === 'M'){
        this.type = 'Mall';
      }
      this.Apartment = [{ 'app_id': res.assigned_infra, 'app_name': res.app_name }];
      console.log(res);
      this.adminForm.patchValue({
        user_id: res.user_id,
        fname: res.fname,
        mname: res.mname,
        lname: res.lname,
        email: res.email,
        phone: res.phone,
        app_name: res.app_name,
        app_type: res.app_type,
        assigned_infra: res.assigned_infra,
        active: this.Active
      });
    });
  }
  updateAdmin(formdata: any) {
    if (formdata.active === true) {
      formdata.active = 'Yes';
    } else if (formdata.active === false) {
      formdata.active = 'No';
    }
    console.log(formdata);
    this.admin = formdata["user_id"];
    if (this.editEmail !== formdata['email'] || this.editPhone !== formdata['phone']) {
      console.log('otp sending start');
      this.loader.start();
      if (this.editEmail !== formdata['email']) {
        console.log('otp sending to email');
        this.service.sendEmailOTP(formdata['email']).subscribe((res: any) => {
          if (res.status) {
            this.loader.stop();
            this.mailOtp = res['otp'];
            this.eEOTP = false;
            this.tosterService.success('An OTP sent to email : '+formdata['email'] +' & OTP is ' + res['otp']);
          }
        }, err => {
          this.loader.stop();
          this.tosterService.error(err.message);
        });
      }
      if (this.editPhone !== formdata['phone']) {
        console.log('otp sending to phone');
        this.loader.start();
        this.service.sendPhoneOTP(formdata['phone']).subscribe((res: any) => {
          if (res.status) {
            this.loader.stop();
            this.phoneOtp = res['otp'];
            this.ePOTP = false;
            this.tosterService.success('An OTP sent to Phone : '+formdata['phone'] +' & OTP is ' + res['otp']);
          }
        }, err => {
          this.loader.stop();
          this.tosterService.error(err.message);
        });
      }
      setTimeout(() => {
        this.otpStatus = true;
        this.startTimer();
      }, 3000);
    } else {
      this.loader.start();
      this.service.updateAdmin(formdata).subscribe((res: any) => {
        if (res.status === true) {
          if (this.document != null) {
            this.loader.start();
            this.service.uploadIdProof(this.admin, this.document).subscribe(resP => {
              if (resP["status"]) {
                this.getAdmin();
                this.loader.stop();
                this.tosterService.success('Update Successful');
                jQuery("#adminModal").modal("hide");
              }
            },
              error => {
                this.loader.stop();
                this.tosterService.error(error.statusText);
              });
          } else {
            this.getAdmin();
            this.loader.stop();
            this.tosterService.success('Update Successful');
            jQuery('#adminModal').modal("hide");
          }
        }
      }, err => {
        this.loader.stop();
        this.tosterService.error(err.statusText);
      });
    }
  }
  editAdmin(emailOtp: number, phoneOtp: number, formdata: any) {
    if (this.editEmail !== formdata['email'] && this.editPhone !== formdata['phone']) {
      if (this.mailOtp === emailOtp && this.phoneOtp === phoneOtp) {
        console.log(formdata);
        this.admin = formdata["user_id"];
        this.loader.start();
        this.service.updateAdmin(formdata).subscribe((res: any) => {
          if (res.status === true) {
            if (this.document != null) {
              this.loader.start();
              this.service.uploadIdProof(this.admin, this.document).subscribe(resP => {
                if (resP["status"]) {
                  this.getAdmin();
                  this.loader.stop();
                  this.tosterService.success('Update Successful');
                  jQuery("#adminModal").modal("hide");
                  this.otpStatus = false;
                  this.eEOTP = true;
                  this.ePOTP = true;
                }
              },
                error => {
                  this.loader.stop();
                  this.tosterService.error(error.statusText);
                });
            } else {
              this.getAdmin();
              this.loader.stop();
              this.tosterService.success('Update Successful');
              jQuery('#adminModal').modal("hide");
              this.otpStatus = false;
              this.eEOTP = true;
              this.ePOTP = true;
            }
          }
        }, err => {
          this.loader.stop();
          this.tosterService.error(err.statusText);
        });
      } else {
        console.log(this.mailOtp + '/' + this.phoneOtp);
        this.tosterService.error('Invalid OTP !!');
      }
    } else if (this.editEmail === formdata['email'] && this.editPhone !== formdata['phone']) {
      if (this.phoneOtp === phoneOtp) {
        console.log(formdata);
        this.admin = formdata["user_id"];
        this.loader.start();
        this.service.updateAdmin(formdata).subscribe((res: any) => {
          if (res.status === true) {
            if (this.document != null) {
              this.loader.start();
              this.service.uploadIdProof(this.admin, this.document).subscribe(resP => {
                if (resP["status"]) {
                  this.getAdmin();
                  this.loader.stop();
                  this.tosterService.success('Update Successful');
                  jQuery("#adminModal").modal("hide");
                  this.otpStatus = false;
                  this.eEOTP = true;
                  this.ePOTP = true;
                }
              },
                error => {
                  this.loader.stop();
                  this.tosterService.error(error.statusText);
                });
            } else {
              this.getAdmin();
              this.loader.stop();
              this.tosterService.success('Update Successful');
              jQuery('#adminModal').modal("hide");
              this.otpStatus = false;
              this.eEOTP = true;
              this.ePOTP = true;
            }
          }
        }, err => {
          this.loader.stop();
          this.tosterService.error(err.statusText);
        });
      } else {
        console.log(this.mailOtp + '/' + this.phoneOtp);
        this.tosterService.error('Invalid OTP !!');
      }
    } else if (this.editEmail !== formdata['email'] && this.editPhone === formdata['phone']) {
      if (this.mailOtp === emailOtp) {
        console.log(formdata);
        this.admin = formdata["user_id"];
        this.loader.start();
        this.service.updateAdmin(formdata).subscribe((res: any) => {
          if (res.status === true) {
            if (this.document != null) {
              this.loader.start();
              this.service.uploadIdProof(this.admin, this.document).subscribe(resP => {
                if (resP["status"]) {
                  this.getAdmin();
                  this.loader.stop();
                  this.tosterService.success('Update Successful');
                  jQuery("#adminModal").modal("hide");
                  this.otpStatus = false;
                  this.eEOTP = true;
                  this.ePOTP = true;
                }
              },
                error => {
                  this.loader.stop();
                  this.tosterService.error(error.statusText);
                });
            } else {
              this.getAdmin();
              this.loader.stop();
              this.tosterService.success('Update Successful');
              jQuery('#adminModal').modal("hide");
              this.otpStatus = false;
              this.eEOTP = true;
              this.ePOTP = true;
            }
          }
        }, err => {
          this.loader.stop();
          this.tosterService.error(err.statusText);
        });
      } else {
        console.log(this.mailOtp + '/' + this.phoneOtp);
        this.tosterService.error('Invalid OTP !!');
      }
    }
  }
  getActiveAppOffice(type: any) {
    if (type === 'Type') {
      this.Apartment = null;
      alert('Please choose apartment or office');
    } else if (type === 'Apartment') {
      this.ApartService.getAllActiveApartment().subscribe((res: any) => {
        this.Apartment = res;
      });
    } else if (type === 'Government Office') {
      this.ApartService.getAllActiveOffice('GO').subscribe((res: any) => {
        this.Apartment = res;
      });
    }else if (type === 'Private Office') {
      this.ApartService.getAllActiveOffice('PO').subscribe((res: any) => {
        this.Apartment = res;
      });
    }else if (type === 'Mall') {
      this.ApartService.getAllActiveOffice('M').subscribe((res: any) => {
        this.Apartment = res;
      });
    }
  }
  getActiveAppOffc(type: any, area_id) {
    this.radioType = null;
    if (type === 'Type') {
      this.cApartment = null;
      alert('Please choose apartment or office');
    } else if (type === 'A') {
      this.ApartService.getActiveApartmentByArea(area_id).subscribe((res: any) => {
        this.cApartment = res;
      });
    } else if (type === 'PO') {
      this.ApartService.getActiveOfficeByArea(area_id,'PO').subscribe((res: any) => {
        this.cApartment = res;
      });
    }
    else if (type === 'GO') {
      this.ApartService.getActiveOfficeByArea(area_id,'GO').subscribe((res: any) => {
        this.cApartment = res;
      });
    }
    else if (type === 'M') {
      this.ApartService.getActiveOfficeByArea(area_id,'M').subscribe((res: any) => {
        this.cApartment = res;
      });
    }
  }
  getAllActiveCountry() {
    this.countryService.getActiveCountry().subscribe((res: any) => {
      this.Country = res;
    });
  }

  getActiveStatebyId(cId: number) {
    this.loader.start();
    this.stateService.getActiveStateById(cId).subscribe((res: any) => {
      this.State = res;
      this.loader.stop();
    }, err => {
      this.loader.start();
      this.tosterService.error('Not find state');
    });
  }
  getActiveCityById(sId: number) {
    this.loader.start();
    this.cityService.getActiveCityById(sId).subscribe((res: any) => {
      this.City = res;
      this.loader.stop();
    }, err => {
      this.loader.stop();
      this.tosterService.error('Not find city');
    });
  }
  getActiveAreaById(ctId: number) {
    this.loader.start();
    this.areaService.getActiveAreaByID(ctId).subscribe((res: any) => {
      this.Area = res;
      this.loader.stop();
    }, err => {
      this.loader.stop();
      this.tosterService.error('Not find area');
    });
  }
  onChangeArea(aID: number) {
    this.areaService.getPinByArea(aID).subscribe((res: any) => {
      this.createForm.patchValue({
        pin: res.pin_code
      });
    });
  }
  chkEmail(control: FormControl): Promise<any> | Observable<any> {
    const promise = new Promise((resolve, reject) => {
      let data = {
        field: 'email',
        value: control.value
      };
      console.log(data);
      this.service.checkDuplicateUserEmail(data).subscribe(
        resData => {
          console.log(resData);
          if (resData['status']) {
            resolve(null);
          } else {
            resolve({ 'textIsForbidden': true });
          }
        },
        error => console.log(error)
      );
    });
    return promise;
  }

  chkPhone(control: FormControl): Promise<any> | Observable<any> {
    const promise = new Promise((resolve, reject) => {
      let data = {
        field: 'phone',
        value: control.value
      };
      console.log(data);
      this.service.checkDuplicateUserMob(data).subscribe(
        resData => {
          console.log(resData);
          if (resData['status']) {
            resolve(null);
          } else {
            resolve({ 'textIsForbidden': true });
          }
        },
        error => console.log(error)
      );
    });
    return promise;
  }

  emailErrorMessage() {
    return this.createForm.get('email').hasError('required') && this.createForm.get('email').touched ? 'You must enter an email' :
      this.createForm.get('email').hasError('pattern') ? 'You must enter a valid email' :
        this.createForm.get('email').hasError('textIsForbidden') ? 'Email already exist!' : '';
  }

  phoneErrorMessage() {
    return this.createForm.get('phone').hasError('required') && this.createForm.get('phone').touched ? 'You must enter phone' :
      this.createForm.get('phone').hasError('minlength') ? 'Minimum length is 10!' :
        this.createForm.get('phone').hasError('maxlength') ? 'Maximum length is 10!' :
          this.createForm.get('phone').hasError('textIsForbidden') ? 'Number already exist!' :
            this.createForm.get('phone').hasError('pattern') ? 'Enter Valid no!' : '';
  }
  // onFileSelect(event){
  //   if(event.target.files.length>0){
  //   const file= event.target.files[0];
  //  console.log(file);
  //   this.userFile= file;

  //   var reader = new FileReader();

  //   this.imgURL= this.file ;    //add index signature,otherwise it was showing error .did it by quick fix 2nd option
  //   reader.readAsDataURL(this.userFile);
  //   reader.onload=(event:any) => {
  //     this.imgURL=event.target.result;
  //     console.log("URL = "+this.imgURL);
  //     console.log("url");
  //   }
  //   }
  // }


  onFileSelectphoto(evt: { target: { files: any[]; }; }) {
    var f = evt.target.files[0];
    var reader = new FileReader();
    reader.onload = (function(theFile) {
      return function(e) {
        var binaryData:any = e.target.result;
        var imgString:any = window.btoa(binaryData) ;
        console.log("my result1====",imgString);
        document.getElementById('topic2').innerHTML =imgString;
        
      };
    })(f);
    reader.readAsBinaryString(f);
  }








  
  Submit1(submitForm: any) { 
       // console.log(submitForm);
    // if (this.radioType === '') {
    //   this.tosterService.info('Specify Admin Address');
    // } else 
   // var tryt = this.imgURL.substring(22); 
    if (this.radioType === 'insideApart') {

      {

        
       this.dirty = new addAdmin(submitForm.fname,submitForm.mname,submitForm.lname,submitForm.dob,submitForm.country,submitForm.state,submitForm.city,submitForm.area,submitForm.pin,submitForm.residential_type,submitForm.assigned_infra,submitForm.blk_id,submitForm.flr_id,submitForm.house_no,submitForm.phone,submitForm.email,submitForm.password,document.getElementById('topic2').innerText,"Yes","Yes");
       this.submitAdminInfo();

        this.loader.start();
        clearInterval(this.interval);
        forkJoin(this.service.sendPhoneOTP(submitForm.phone), this.service.sendEmailOTP(submitForm.email))
          .subscribe(([phoneRes, emailRes]) => {
            if (phoneRes["status"] && emailRes["status"]) {
              this.loader.stop();
              this.phoneOtp = phoneRes["otp"];
              this.mailOtp = emailRes["otp"];
              console.log('Phone-' + phoneRes["otp"]);
              console.log('Email-' + emailRes["otp"]);
              this.tosterService.success("OTP sent Successful");
              this.fShow = false;
              this.startTimer();
            }
          }, error => {
            this.loader.stop();
            this.tosterService.error(error);
          });



        // console.log(dart);



      //   const user = submitForm;
      //   const formData = new FormData();

      //  console.log(user.fname);


      //   formData.append('fname', (user.fname));
      //   if(user.mname==null){
      //     formData.append('mname', '   ');
      //   }
      //   else if(user.mname!=null){
      //     formData.append('mname', user.mname);
      //   }
      //   if(user.lname==null){
      //     formData.append('lname', '   ');
      //   }
      //   else if(user.lname!=null){
      //     formData.append('lname', user.lname);
      //   }
        
       
      //   formData.append('dob', this.datefilter(user.dob));
      //   formData.append('country', (user.country));
      //   formData.append('state', (user.state));

      //   formData.append('city', (user.city));
      //   formData.append('area', (user.area));
      //   formData.append('pin', (user.pin));
      //   formData.append('residential_type', ((user.residential_type)));
      //   formData.append('assigned_infra', (user.assigned_infra));
      //   formData.append('blk_id', (user.blk_id));

      //   formData.append('flr_id', (user.flr_id));
      //   formData.append('house_no', (user.house_no));
       
      
      //   formData.append('phone', (user.phone));
      //   this.phoneR= user.phone;
      //   formData.append('email', (user.email));
      //   this.emailR=user.email;
      //   formData.append('password', (user.password));
      //   formData.append('proof_of_residence',this.userFile.name );

      //  formData.append('file', this.userFile, this.userFile.value);


      };
      

    } 
    else if (this.radioType === 'singleHome') {

      this.dirtyNew = new addAdminSingleHome(submitForm.fname,submitForm.mname,submitForm.lname,submitForm.dob,submitForm.country,submitForm.state,submitForm.city,submitForm.area,submitForm.pin,submitForm.residential_type,submitForm.assigned_infra,submitForm.house_no,submitForm.phone,submitForm.email,submitForm.password,document.getElementById('topic2').innerText,"Yes","Yes");
      this.submitAdminInfo();

       this.loader.start();
        clearInterval(this.interval);
        forkJoin(this.service.sendPhoneOTP(submitForm.phone), this.service.sendEmailOTP(submitForm.email))
          .subscribe(([phoneRes, emailRes]) => {
            if (phoneRes["status"] && emailRes["status"]) {
              this.loader.stop();
              this.phoneOtp = phoneRes["otp"];
              this.mailOtp = emailRes["otp"];
              console.log('Phone-' + phoneRes["otp"]);
              console.log('Email-' + emailRes["otp"]);
              this.tosterService.success("OTP sent Successful");
              this.fShow = false;
              this.startTimer();
            }
          }, error => {
            this.loader.stop();
            this.tosterService.error(error);
          });




        



      //   const user = submitForm;
      //   const formData = new FormData();



      //   formData.append('fname', (user.fname));
        
      //   if(user.mname==null){
      //     formData.append('mname', '   ');
      //   }
      //   else if(user.mname!=null){
      //     formData.append('mname', user.mname);
      //   }
      //   if(user.lname==null){
      //     formData.append('lname', '   ');
      //   }
      //   else if(user.lname!=null){
      //     formData.append('lname', user.lname);
      //   }
      //   formData.append('dob', this.datefilter(user.dob));
      //   formData.append('country', (user.country));
      //   formData.append('state', (user.state));

      //   formData.append('city', (user.city));
      //   formData.append('area', (user.area));
      //   formData.append('pin', (user.pin));
      //   formData.append('residential_type', ((user.residential_type)));
      //   formData.append('assigned_infra', (user.assigned_infra));
       

      //    formData.append('street', (user.street));
      //    formData.append('house_no', (user.house_no));
       
      //   formData.append('phone', (user.phone));
      //   this.phoneR= user.phone;
      //   formData.append('email', (user.email));
      //   this.emailR=user.email;
      //   formData.append('password', (user.password));
      //   formData.append('proof_of_residence',this.userFile.name );

      //  formData.append('file', this.userFile, this.userFile.value);
      //   console.log(formData);
      


        
      

    } 
    // this.createForm.reset();
    // submitForm.reset();
    
  }
  resendOTP() {
    clearInterval(this.interval);
    console.log();
    this.loader.start();
    forkJoin(this.service.sendPhoneOTP(this.phoneR), this.service.sendEmailOTP(this.emailR))
      .subscribe(([phoneRes, emailRes]) => {
        if (phoneRes["status"] && emailRes["status"]) {
          this.loader.stop();
          this.phoneOtp = phoneRes["otp"];
          this.mailOtp = emailRes["otp"];
          console.log('Phone-' + phoneRes["otp"]);
          console.log('Email-' + emailRes["otp"]);
          this.tosterService.success("OTP sent Successful");
          this.timeLeft = 120;
          this.startTimer();
        }
      }, error => {
        this.loader.stop();
        this.tosterService.error(error);
      });
  }
  verifyOTP(mOTP: number, eOTP: number) {
    
    if (mOTP === this.phoneOtp && eOTP === this.mailOtp) {
      
      this.loader.start();
      forkJoin(this.service.veryfyPhoneOTP(this.admin), this.service.veryfyMailOTP(this.admin))
        .subscribe(([res1, res2]) => {
          if (res1["status"] && res2["status"]) {
            
            this.loader.stop();
            this.getAdmin();
            this.tosterService.success("Email & Phone verified");
            // tslint:disable-next-line:quotemark
            jQuery("#exampleModal").modal("hide");
            this.fShow = true;
            this.reload1();
          }
        }, error => {
          this.loader.stop();
          this.tosterService.error(error);
        });
    } else {
      this.tosterService.error('OTP invalid');
    }
    // this.reload1();
  }
  submitAdminInfo(){
    if(this.radioType === 'insideApart'){
      this.loader.start();
      this.service.createAdminWithUser(this.dirty).subscribe(res => {
        this.admin = res["userid"]["user_id"];
        this.loader.stop();
      }, error => {
        this.loader.stop();
        this.tosterService.error(error);
      });
    }else if(this.radioType === 'singleHome'){
      this.loader.start();
      this.service.createAdmin(this.dirtyNew).subscribe(res => {
        this.admin = res["userid"]["user_id"];
          this.loader.stop();
      }, error => {
        this.loader.stop();
        this.tosterService.error(error);
      });
    }

        
  }




  onChangeRadio(data: string) {
    this.radioType = data;
    if(this.radioType=="singleHome" ){
      document.getElementById("tab1").style.display="none"; 
      document.getElementById("tab2").style.display="none";
      document.getElementById("tab3").style.display="none";
      document.getElementById("tab4").style.display="block";
      document.getElementById("tab5").style.display="block";
    }
    else if(this.radioType=="insideApart"){
      document.getElementById("tab1").style.display="block"; 
      document.getElementById("tab2").style.display="block";
      document.getElementById("tab3").style.display="block";
      document.getElementById("tab4").style.display="none";
      document.getElementById("tab5").style.display="none";
    } 
    console.log(this.radioType);
  }

  // filter admin section
  getAllActiveState() {
    this.stateService.getActiveState().subscribe(res => {
      this.StateFilter = res;
    }, err => {
      this.tosterService.error(err.message);
    });
  }
  getFactiveCity(s_id) {
    this.loader.start();
    this.AreaFilter = null;
    this.cityService.getActiveCityById(s_id).subscribe(res => {
      this.loader.stop();
      this.CityFilter = res;
    }, err => {
      this.loader.stop();
      this.tosterService.error(err.message);
    });
  }
  getFactiveArea(c_id) {
    this.loader.start();
    this.areaService.getActiveAreaByID(c_id).subscribe(res => {
      this.loader.stop();
      this.AreaFilter = res;
    }, err => {
      this.loader.stop();
      this.tosterService.error(err.message);
    });
  }
  getFilterAdmin(state, city, area, type, sta) {
    document.getElementById("table1").innerHTML="";
    document.getElementById("table2").style.display="block";
    this.loader.start();
    // this.Admin.length=0;
    this.service.filterAdmin(state, city, area, type, sta).subscribe(res => {
      
      this.Admin1 = res;
      console.log(res);
      this.loader.stop();
      if (this.isDtInitialized) {
        this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
          dtInstance.destroy();
          this.dtTrigger.next();
        });
      } else {
        this.isDtInitialized = true;
        this.dtTrigger.next();
      }
    }, err => {
      this.loader.stop();
      this.tosterService.error('Not find Admin');
    });
  }

  // admin belongs to apartment
  getAllActiveApartment() {
    this.loader.start();
    this.ApartService.getAllActiveApartment().subscribe(res => {
      this.apartList = res;
      this.loader.stop();
    }, err => {
      this.loader.stop();
      this.tosterService.error(err.message);
    });
  }
  onChangeApartment(ap_id, aType) {        // Select appartment/ Office / mall(?)
    if (aType === 'A') {
      this.loader.start();
      this.blockService.getActiveBlockByAppOff(ap_id).subscribe(res => {
        this.blockList = res;
        this.loader.stop();
      }, err => {
        this.loader.stop();
        this.tosterService.error(err.message);
      });
    } else {
      this.blockList = null;
    }
  }
  onchangeBlock(blk_id) {
    this.loader.start();
    this.floorService.getActiveFloorByBlock(blk_id).subscribe(res => {
      this.floorList = res;
      console.log(res);
      this.loader.stop();
    }, err => {
      this.loader.stop();
      this.tosterService.error(err.message);
    });
  }
  onChnageFloor(flr_id) {
    this.loader.start();
    this.houseService.getHouseByFloor(flr_id).subscribe(res => {
      this.houseList = res;
      console.log(res);
      this.loader.stop();
    }, err => {
      this.loader.stop();
      this.tosterService.error(err.message);
    });
  }
  // admin edit email & phone duplicate check
  chkEditEmail(control: FormControl): Promise<any> | Observable<any> {
    const promise = new Promise((resolve, reject) => {
      let data = {
        field: 'email',
        user_id: this.admin_id,
        value: control.value
      };
      console.log(data);
      this.service.checkDuplicate(data).subscribe(
        resData => {
          console.log(resData);
          if (resData['status']) {
            resolve(null);
          } else {
            resolve({ textIsForbidden: true });
          }
        },
        error => console.log(error)
      );
    });
    return promise;
  }

  chkEditPhone(control: FormControl): Promise<any> | Observable<any> {
    const promise = new Promise((resolve, reject) => {
      let data = {
        field: 'phone',
        user_id: this.admin_id,
        value: control.value
      };
      console.log(data);
      this.service.checkDuplicate(data).subscribe(
        resData => {
          console.log(resData);
          if (resData['status']) {
            resolve(null);
          } else {
            resolve({ textIsForbidden: true });
          }
        },
        error => console.log(error)
      );
    });
    return promise;
  }

  editEmailErrorMessage() {
    return this.adminForm.get('email').hasError('required') && this.adminForm.get('email').touched ? 'You must enter a email' :
      this.adminForm.get('email').hasError('email') ? 'You must enter a valid email' :
        this.adminForm.get('email').hasError('pattern') ? 'You must enter a valid email' :
          this.adminForm.get('email').hasError('textIsForbidden') ? 'Email already exist!' : '';
  }

  editPhoneErrorMessage() {
    return this.adminForm.get('phone').hasError('required') && this.adminForm.get('phone').touched ? 'You must enter phone' :
      this.adminForm.get('phone').hasError('minlength') ? 'Minimum length is 10!' :
        this.adminForm.get('phone').hasError('maxlength') ? 'Maximum length is 10!' :
          this.adminForm.get('phone').hasError('pattern') ? 'Enter Valid no!' :
            this.adminForm.get('phone').hasError('textIsForbidden') ? 'Number already exist!' : '';
  }
  resendOTP2(formdata: any) {
    console.log('otp sending start');
    this.loader.start();
    if (this.editEmail !== formdata['email']) {
      console.log('otp sending to email');
      this.service.sendEmailOTP(formdata['email']).subscribe((res: any) => {
        if (res.status) {
          this.otpForm.reset();
          console.log(res);
          this.loader.stop();
          this.mailOtp = res['otp'];
          this.eEOTP = false;
          this.tosterService.success('An OTP sent to email !!');
        }
      }, err => {
        this.loader.stop();
        this.tosterService.error(err.message);
      });
    }
    if (this.editPhone !== formdata['phone']) {
      console.log('otp sending to phone');
      this.loader.start();
      this.service.sendPhoneOTP(formdata['phone']).subscribe((res: any) => {
        if (res.status) {
          this.otpForm.reset();
          console.log(res);
          this.loader.stop();
          this.phoneOtp = res['otp'];
          this.ePOTP = false;
          this.tosterService.success('An OTP sent to phone !!');
        }
      }, err => {
        this.loader.stop();
        this.tosterService.error(err.message);
      });
    }
    setTimeout(() => {
      clearInterval(this.interval);
      this.otpStatus = true;
      this.timeLeft = 120;
      this.startTimer();
    }, 2000);
  }
  reload1(){
    this.createForm.reset();
     
    //this.tosterService.success("Email & Phone verified");
    window.location.reload();
    this._document.defaultView.location.reload();
    this.createForm.reset();
  }

  showHideBlock() { 
  
  if(this.radioType=="singleHome" ){
    document.getElementById("tab1").style.display="none"; 
    document.getElementById("tab2").style.display="none";
    document.getElementById("tab3").style.display="none";
    document.getElementById("tab4").style.display="block";
    document.getElementById("tab5").style.display="block";
  }
  else if(this.radioType=="insideApart"){
    document.getElementById("tab1").style.display="block"; 
    document.getElementById("tab2").style.display="block";
    document.getElementById("tab3").style.display="block";
    document.getElementById("tab4").style.display="none";
    document.getElementById("tab5").style.display="none";
  } 
  console.log("showHide1 /// "+this.radioType);
  }
}


export class addAdmin{
  fname:String;
  mname:String;
  lname:String;
  dob:String;
  country:number;
  state:number;
  city:number;
  area:number;
  pin:String;
  residential_type:String;
  assigned_infra:number;
  blk_id:number;
  flr_id:number;
  house_no:String;
  phone:String;
  email:String;
  password:String;
  proof_of_residence:String;
  phone_verified:String;
  email_verified:String;
  constructor(fname:String,mname:String,lname:String,dob:String,country:number,state:number,city:number,area:number, pin:String,residential_type:String,assigned_infra:number,blk_id:number,flr_id:number,house_no:String,  phone:String,email:String,password:String,proof_of_residence:String,phone_verified:String,email_verified:String){
  this.fname=fname;
  this.mname=mname;
  this.lname=lname;
  this.dob=dob;
  this.country=country;
  this.state=state;
  this.city=city;
  this.area=area;
  this.pin=pin;
  this.residential_type=residential_type;
  this.assigned_infra=assigned_infra;
  this.blk_id=blk_id;
  this.flr_id=flr_id;
  this.house_no=house_no;
  this.phone=phone;
  this.email=email;
  this.password=password;
  this.proof_of_residence=proof_of_residence;
  this.phone_verified=phone_verified;
  this.email_verified=email_verified;
  }

}


export class addAdminSingleHome{
  fname:String;
  mname:String;
  lname:String;
  dob:String;
  country:number;
  state:number;
  city:number;
  area:number;
  pin:String;
  residential_type:String;
  assigned_infra:number;
  house_no:String;
  phone:String;
  email:String;
  password:String;
  proof_of_residence:String;
  phone_verified:String;
  email_verified:String;
  constructor(fname:String,mname:String,lname:String,dob:String,country:number,state:number,city:number,area:number, pin:String,residential_type:String,assigned_infra:number,house_no:String,phone:String,email:String,password:String,proof_of_residence:String,phone_verified:String,email_verified:String){
  this.fname=fname;
  this.mname=mname;
  this.lname=lname;
  this.dob=dob;
  this.country=country;
  this.state=state;
  this.city=city;
  this.area=area;
  this.pin=pin;
  this.residential_type=residential_type;
  this.assigned_infra=assigned_infra;
  this.house_no=house_no;
  this.phone=phone;
  this.email=email;
  this.password=password;
  this.proof_of_residence=proof_of_residence;
  this.phone_verified=phone_verified;
  this.email_verified=email_verified;
  }

}