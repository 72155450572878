import { SubscriptionService } from './../../services/subscription.service';
import { FormGroup, FormBuilder, RequiredValidator, Validators } from '@angular/forms';
import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { Subject } from 'rxjs';
import { DataTableDirective } from 'angular-datatables';
import { AppBillingService } from 'src/app/services/app-billing.service';
import { AreaService } from './../../services/area.service';
import { CityService } from 'src/app/services/city.service';
import { StateService } from './../../services/state.service';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-apartment-billing',
  templateUrl: './apartment-billing.component.html',
  styleUrls: ['./apartment-billing.component.css']
})
export class ApartmentBillingComponent implements OnInit, OnDestroy {
  @ViewChild(DataTableDirective, { static: false })
  dtElement: DataTableDirective;
  // tslint:disable-next-line: no-inferrable-types
  isDtInitialized: boolean = false;
  // page variables
  Invoice: any[];
  id: number;
  app_id: number;
  app_name: string;
  admin_id: number;
  admin_name: string;
  admin_email: string;
  admin_pnone: string;
  inv_number: number;
  inv_date: any;
  inv_amount: number;
  validity_from: any;
  validity_to: any;
  subscription: number;
  payment_date: any;
  status: string;
  // filter
  StateFilter: any[];
  CityFilter: any[];
  AreaFilter: any[];
  Subs: any[];

  filterForm = this.fb.group({
    state: ['', Validators.required],
    city: ['', Validators.required],
    area: ['', Validators.required],
    type: ['', Validators.required],
    subscription: ['', Validators.required],
    status: ['', Validators.required]
  });

  dtOptions: DataTables.Settings = {};
  dtTrigger: Subject<any> = new Subject();
  ngOnDestroy(): void {
    this.dtTrigger.unsubscribe();
  }
  constructor(
    private fb: FormBuilder,
    private service: AppBillingService,
    private stateService: StateService,
    private cityService: CityService,
    private areaService: AreaService,
    private loader: NgxUiLoaderService,
    private tosterService: ToastrService,
    private subService: SubscriptionService
  ) {
  }

  ngOnInit() {
    this.dtOptions = {
      pagingType: 'full_numbers',
      pageLength: 10,
      processing: true,
      stateSave: true
    };
    this.getAllInvoice();
    this.getAllActiveState();
    this.getSubscription();
  }
  getAllInvoice() {
    this.service.getAppInvoice().subscribe((res: any) => {
      this.Invoice = res;
      if (this.isDtInitialized) {
        this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
          dtInstance.destroy();
          this.dtTrigger.next();
        });
      } else {
        this.isDtInitialized = true;
        this.dtTrigger.next();
      }
    });
  }
  billFilter(data: any) {
    this.loader.start();
    this.service.billFilter(data).subscribe((res: any) => {
      this.Invoice = res;
      this.loader.stop();
    }, err => {
      this.loader.stop();
      this.tosterService.error(err.message);
    });
  }
  // filteration
  getAllActiveState() {
    this.stateService.getActiveState().subscribe(res => {
      this.StateFilter = res;
    }, err => {
      this.tosterService.error(err.message);
    });
  }
  getFactiveCity(s_id) {
    this.loader.start();
    this.AreaFilter = null;
    this.cityService.getActiveCityById(s_id).subscribe(res => {
      this.loader.stop();
      this.CityFilter = res;
    }, err => {
      this.loader.stop();
      this.tosterService.error(err.message);
    });
  }
  getFactiveArea(c_id) {
    this.loader.start();
    this.areaService.getActiveAreaByID(c_id).subscribe(res => {
      this.loader.stop();
      this.AreaFilter = res;
    }, err => {
      this.loader.stop();
      this.tosterService.error(err.message);
    });
  }
  getSubscription() {
    this.loader.start();
    this.subService.getActiveSubscription().subscribe(res => {
      this.loader.stop();
      this.Subs = res;
    }, err => {
      this.loader.stop();
      this.tosterService.error(err.message);
    });
  }
}
