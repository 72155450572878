import { Injectable } from '@angular/core';
import { environment } from './../../environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AdServiceService {
  httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json'})
  };
// serverUrl = environment.baseUrl;
constructor(
  private http: HttpClient
) { }
// http://dev01.ohhpro.com:9080/ohhpro/rest/homePage/get/getOhhproAdv

getAllAds(): Observable<any[]> {
  const url = 'homePage/get/getOhhproAdv';
  return this.http.get<any[]>(environment.baseUrl1 + '' + url);
}
// http://dev01.ohhpro.com:9080/ohhpro/rest/homePage/add/updateOhhproAdv/No/6
// http://dev01.ohhpro.com:9080/ohhpro/rest/homePage/add/updateOhhproAdv/No/1
activeDeactiveAd(status,id) {
  const url = 'homePage/add/updateOhhproAdv';
  
  return this.http.post(environment.baseUrl1 + '' + url + '/'+status+'/'+id,this.httpOptions);
}

}
