import { Component, OnInit } from '@angular/core';
import { ApartmentService } from './../../services/apartment.service';
import { AreaService } from './../../services/area.service';
import { StateService } from './../../services/state.service';
import { CountryService } from './../../services/country.service';
import { CityService } from './../../services/city.service';
import {  OnDestroy, ViewChild } from '@angular/core';
import { forkJoin, Observable, Subject } from 'rxjs';
import { DataTableDirective } from 'angular-datatables';
import { FormGroup, FormBuilder, FormArray, AbstractControl, Validators, FormControl } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { BsDatepickerConfig } from 'ngx-bootstrap/datepicker';
declare var jQuery: any;

@Component({
  selector: 'app-governmento',
  templateUrl: './governmento.component.html',
  styleUrls: ['./governmento.component.css']
})
export class GovernmentoComponent implements OnInit, OnDestroy {

  officeForm: FormGroup;
  @ViewChild(DataTableDirective, { static: false })
  dtElement: DataTableDirective;
  // tslint:disable-next-line: no-inferrable-types
  isDtInitialized: boolean = false;
  Office: any[];
  City: any[];
  Country: any[];
  State: any[];
  City2: any[];
  Area: any[] = null;
  Subs: any[];
  subscription = false;
  sValue = 0;

  editEmail: any;
  editPhone: any;
  otpStatus = false;
  eEOTP = true;
  ePOTP = true;
  fShow = true;
  timeLeft = 60;
  interval: any;
  mailOtp: number;
  app_email = null;
  admin: number;
  app_id: any;

  datePickerConfig: Partial<BsDatepickerConfig>;
  dtOptions: DataTables.Settings = {};
  dtTrigger: Subject<any> = new Subject();
  email1: any;
  ngOnDestroy(): void {
    this.dtTrigger.unsubscribe();
  }
  constructor(
    private tosterService: ToastrService,
    private loader: NgxUiLoaderService,
    private service: ApartmentService,
    private cityService: CityService,
    private countryService: CountryService,
    private stateService: StateService,
    private areaService: AreaService,
    private fb: FormBuilder
  ) {
    this.datePickerConfig = Object.assign({},
      {
        containerClass: 'theme-dark-blue',
        showWeekNumbers: false,
        dateInputFormat: 'DD-MM-YYYY',
      });
    this.officeForm = this.fb.group({
      country: ['0', Validators.required],
      state: ['0', Validators.required],
      city: ['0', Validators.required],
      area: ['0', Validators.required],
      app_name: ['', Validators.required],
      app_address: ['', Validators.required],
      app_email: ['', [Validators.email, Validators.pattern("^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\\.[A-Za-z]{2,50}$"),
      Validators.required], this.chkEmail.bind(this)],
      app_type: ['GO'],
      pin: [''],
      police_station: ['', Validators.required],
      fire_station: ['', Validators.required],
      hospital: ['', Validators.required],
      ambulance: ['', Validators.required],
      community_help_desk: ['', Validators.required],
      subscription_id: ['0', Validators.required],
      app_gate_no1: [''],
      app_gate_no2: [''],
      app_gate_no3: [''],
      app_gate_no4: [''],
      gate_no1_phone: [''],
      gate_no2_phone: [''],
      gate_no3_phone: [''],
      gate_no4_phone: [''],
      // service_start_date: ['', Validators.required],
      // service_end_date: ['', Validators.required],
      active: [false]
    });
  }
  ngOnInit() {
    this.dtOptions = {
      pagingType: 'full_numbers',
      pageLength: 10,
      processing: true,
      stateSave: true
    };
    this.getActiveCountry();
    this.getAllActiveCity();
    this.getSubscription();
  }

  chkEmail(control: FormControl): Promise<any> | Observable<any> {
    const promise = new Promise((resolve, reject) => {
      let data = {
        app_email: control.value
         
        
      };
      console.log(data);
      this.service.checkDuplicateAppEmail(data).subscribe(
        resData => {
          console.log(resData);
          console.log("data = "+resData);
          if (resData['status']) {
            resolve(null);
          } else {
            resolve({ 'textIsForbidden': true });
          }
        },
        error => console.log(error)
      );
    });
    return promise;
  }
  
  // onSubmit(apart: any, ssd: any, sed: any) {
  //   console.log(apart);
  //   apart.service_start_date = ssd;
  //   apart.service_end_date = sed;
  //   if (apart.active === true) {
  //     apart.active = 'Yes';
  //   } else {
  //     apart.active = 'No';
  //   }
  //   this.loader.start();
  //   this.service.addApartment(apart).subscribe((res: any) => {
  //     if (res.status === true) {
  //       this.loader.stop();
  //       this.tosterService.success('Office Added !!');
  //       this.officeForm.reset();
  //       // tslint:disable-next-line:quotemark
  //       jQuery("#exampleModalCenter").modal("hide");
  //     }
  //   },
  //     err => {
  //       this.loader.stop();
  //       this.tosterService.error(err.message);
  //     });
  // }

  onSubmit(apart: any, ssd: any, sed: any) {
    console.log("Apartment = "+apart);
    this.email1=apart.app_email;
    apart.service_start_date = ssd;
    apart.service_end_date = sed;
    if (apart.active === true) {
      apart.active = 'Yes';
    } else {
      apart.active = 'No';
    }
    this.app_email = apart["email"];
    this.loader.start();
    this.service.addApartment(apart).subscribe((res: any) => {
      console.log("res = "+res);
      this.admin = res["appId"]["app_id"];
      this.app_id=res["appId"]["app_id"];
      console.log("App_id = "+this.app_id);
      if (res.status === true) {
        this.loader.start();
            clearInterval(this.interval);
            forkJoin(this.service.sendEmailOTP(apart["app_email"]))
              .subscribe(([emailRes]) => {
                if (emailRes["status"]) {
                  this.loader.stop();
                  
                  this.mailOtp = emailRes["otp"];
                  
                  console.log('Email-' + emailRes["otp"]);
                  this.tosterService.success("OTP sent Successful");
                  this.fShow = false;
                  this.startTimer();
                }
              }, error => {
                this.loader.stop();
                this.tosterService.error(error);
              });
        // this.loader.stop();
        // this.tosterService.success('Apartment Added !!');
        // // tslint:disable-next-line:quotemark
        // jQuery("#exampleModalCenter").modal("hide");
      }
    },
      err => {
        this.loader.stop();
        this.tosterService.error(err.message);
      });
  }

  verifyOTP(eOTP: number) {
    console.log("eotp = "+eOTP);
    if (eOTP === this.mailOtp) {
      this.loader.start();
      forkJoin( this.service.veryfyMailOTP(this.admin))
        .subscribe(([res2]) => {
          if (res2["status"]) {
            this.loader.stop();
            //this.getAdmin();
            this.tosterService.success("Email  verified");
            // tslint:disable-next-line:quotemark
            jQuery("#exampleModalCenter").modal("hide");
            this.fShow = true;
          }
        }, error => {
          this.loader.stop();
          this.tosterService.error(error);
        });
    } else {
      this.tosterService.error('OTP invalid');
    }
  }

  activeOffice(data: any) {
    const editdata = { app_id: data.app_id, active: 'Yes' };
    this.service.activeApartment(editdata).subscribe(res => {
      this.getOfficeByAreaId(data.area,'GO');
    });
  }
  deActiveOffice(data: any) {
    const editdata = { app_id: data.app_id, active: 'No' };
    this.service.activeApartment(editdata).subscribe(res => {
      this.getOfficeByAreaId(data.area,'GO');
    });
  }
  getActiveCountry() {
    this.countryService.getActiveCountry().subscribe(res => {
      this.Country = res;
    });
  }
  getActiveStateById(Cid: any) {
    this.loader.start();
    this.stateService.getActiveStateById(Cid).subscribe(res => {
      this.State = res;
      this.loader.stop();
    }, err => {
      this.loader.stop();
      this.tosterService.error('Not find state');
    });
  }
  getAllActiveCity() {
    this.cityService.getAllActiveCity().subscribe(res => {
      this.City2 = res;
    });
  }
  getActiveCityById(Sid: any) {
    this.loader.start();
    this.cityService.getActiveCityById(Sid).subscribe(res => {
      this.City = res;
      this.loader.stop();
    }, err => {
      this.loader.stop();
      this.tosterService.error('Not find city');
    });
  }
  getActiveAreaById(CtId: any) {
    this.loader.start();
    this.areaService.getActiveAreaByID(CtId).subscribe(res => {
      this.Area = res;
      this.loader.stop();
    }, err => {
      this.loader.stop();
      this.tosterService.error('Not Getting Area');
    });
  }
  getPincode(Aid: any) {
    this.service.getPinCode(Aid).subscribe((res: any) => {
      this.officeForm.patchValue({
        pin: res.pin_code,
      });
    });
  }
  getOfficeByAreaId(areaId: any , app_type: any) {
    if (areaId == 'Select Area') {
      alert('Select Area First');
    } else {
      if (this.subscription == false && this.sValue == 0) {
        this.loader.start();
        this.service.getOfficeByAreaId(areaId, app_type).subscribe((res: any) => {
          this.Office = res;
          this.loader.stop();
          if (this.isDtInitialized) {
            this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
              dtInstance.destroy();
              this.dtTrigger.next();
            });
          } else {
            this.isDtInitialized = true;
            this.dtTrigger.next();
          }
        }, err => {
          this.loader.stop();
          this.tosterService.error('Something Wrong');
        });
      } else {
        // apartment by area id and subscription
        this.loader.start();
        this.service.getOffByFilter(areaId, this.sValue, 'GO').subscribe((res: any) => {
          this.Office = res;
          this.loader.stop();
          if (this.isDtInitialized) {
            this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
              dtInstance.destroy();
              this.dtTrigger.next();
            });
          } else {
            this.isDtInitialized = true;
            this.dtTrigger.next();
          }
        }, err => {
          this.loader.stop();
          this.tosterService.error('Something Wrong');
        });
      }

    }
  }
  subscriptionChange(e: any) {
    if (e.target.checked == false) {
      this.sValue = 0;
      this.subscription = e.target.checked;
    } else {
      this.subscription = e.target.checked;
    }
  }
  onSubValueChange(s: number) {
    this.sValue = s;
  }
  offSubscription(offId: number, sub: any) {
    let data = { subscription_id: sub };
    this.loader.start();
    this.service.updateOffSubscription(offId, data).subscribe((res: any) => {
      this.loader.stop();
      if (res.status) {
        this.tosterService.success('Subscription updated');
      }
    }, err => {
      this.loader.stop();
      this.tosterService.error('something wrong');
    });
  }
  getSubscription() {
    this.loader.start();
    this.service.getActiveSubscription().subscribe(res => {
      this.loader.stop();
      this.Subs = res;
    }, err => {
      this.loader.stop();
      this.tosterService.error(err.message);
    });
  }
  emailErrorMessage() {
    return this.officeForm.get('app_email').hasError('required') && this.officeForm.get('app_email').touched ? 'You must enter an email' :
      this.officeForm.get('app_email').hasError('pattern') ? 'You must enter a valid email' :
        this.officeForm.get('app_email').hasError('textIsForbidden') ? 'Email already exist!' : '';
  }
  startTimer() {
    this.interval = setInterval(() => {
      if (this.timeLeft > 0) {
        this.timeLeft--;
      } else {
        this.timeLeft = 0;
      }
    }, 1000);
  }

  resendOTP() {
    clearInterval(this.interval);
    this.loader.start();
    forkJoin( this.service.sendEmailOTP(this.email1))
      .subscribe(([emailRes]) => {
        if (emailRes["status"]) {
          this.loader.stop();
          
          this.mailOtp = emailRes["otp"];
    
          console.log('Email-' + emailRes["otp"]);
          this.tosterService.success("OTP sent Successful");
          this.timeLeft = 120;
          this.startTimer();
        }
      }, error => {
        this.loader.stop();
        this.tosterService.error(error);
      });
  }
  activateAdmin(data: any) {
    console.log(data);
    let activeStatus;
    if (data.admin_active === 'Yes') {
      activeStatus = { admin_active: 'No' };
    } else if (data.admin_active === 'No' || data.admin_active == null) {
      activeStatus = { admin_active: 'Yes' };
    }
    this.loader.start();
    this.service.activateAdmin(activeStatus, data.app_id).subscribe((res: any) => {
      this.getOfficeByAreaId(data.area, 'GO');
      this.loader.stop();
      this.tosterService.success(res.message);
    }, err => {
      this.loader.stop();
      this.tosterService.error(err.message);
    });
  }
}

