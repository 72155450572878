import { CountryService } from './../../services/country.service';
import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { Subject } from 'rxjs';
import { DataTableDirective } from 'angular-datatables';
declare var $: any;
@Component({
  selector: 'app-country',
  templateUrl: './country.component.html',
  styleUrls: ['./country.component.css']
})
export class CountryComponent implements OnInit, OnDestroy {
  @ViewChild(DataTableDirective, { static: false })
  dtElement: DataTableDirective;
  // tslint:disable-next-line: no-inferrable-types
  isDtInitialized: boolean = false;
  Country: any[];
  dtOptions: DataTables.Settings = {};
  dtTrigger: Subject<any> = new Subject();
  ngOnDestroy(): void {
    this.dtTrigger.unsubscribe();
  }
  constructor(
    private service: CountryService
  ) { }
  ngOnInit() {
    this.dtOptions = {
      pagingType: 'full_numbers',
      pageLength: 10,
      processing: true,
    };
    this.allCountry();
  }

  allCountry() {
    this.service.getAllCountry().subscribe((res: any) => {
      this.Country = res;
      if (this.isDtInitialized) {
        this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
          dtInstance.destroy();
          this.dtTrigger.next();
        });
      } else {
        this.isDtInitialized = true;
        this.dtTrigger.next();
      }
    });
  }
  activeCountry(data: any) {
    const editdata = { country_id: data.country_id, active: 'Yes' };
    this.service.activeCountry(editdata).subscribe((res: any) => {
      this.allCountry();
    });
  }
  deActiveCountry(data: any) {
    const editdata = { country_id: data.country_id, active: 'No' };
    this.service.activeCountry(editdata).subscribe((res: any) => {
      this.allCountry();
    });
  }
}
