import { Injectable } from '@angular/core';
import { environment } from './../../environments/environment';
import { HttpClient } from '@angular/common/http';

import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CovidService {
  // serverUrl = environment.baseUrl;
  constructor(
    private http: HttpClient
  ) { }

  // http://dev01.ohhpro.com:9080/ohhpro/rest/covid/getCovidFoodstatus
  getCovidFoodDetails(): Observable<any[]> {
    const url = 'covid/getCovidFoodstatus';
    return this.http.get<any[]>(environment.baseUrl1 + '' + url);
  }
  // http://dev01.ohhpro.com:9080/ohhpro/rest/covid/updateCovidFoodStatus/{user_review_status}/{covid_19_food_id}/{user_id}
  updateFoodReview(status,food,user,start,end) {
    const url = 'covid/updateCovidFoodStatus';
    
    return this.http.put(environment.baseUrl1 + '' + url + '/'+status+'/'+food + '/'+ user + '/'+start + '/'+ end, user);
  }
  
}
