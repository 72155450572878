import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { environment } from './../../environments/environment';
import { catchError} from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class SkillService {
  httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json'})
  };
  constructor(private http: HttpClient) { }
  getAllSkill() {
    const url = 'skill/allSkill';
    return this.http.get<any[]>(environment.baseUrl + url);
  }
  getSkillByID(skill_id: number) {
    const url = 'skill/allSkill/';
    return this.http.get(environment.baseUrl + url + skill_id);
  }
  addSkill(data: any) {
    const url = 'skill/addskill';
    return this.http.post(environment.baseUrl + url , data);
  }
  updateSkill(data: any, id: number) {
    const url = 'skill/updateskillDetails/';
    return this.http.put(environment.baseUrl + url + id, data);
  }
}
