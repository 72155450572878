import { City } from './../../models/city';
import { StateService } from './../../services/state.service';
import { CountryService } from './../../services/country.service';
import { CityService } from './../../services/city.service';
import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { Subject } from 'rxjs';
import { DataTableDirective } from 'angular-datatables';
import { FormGroup, FormBuilder, FormArray, AbstractControl, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { NgxUiLoaderService } from 'ngx-ui-loader';
declare var jQuery: any;
@Component({
  selector: 'app-city',
  templateUrl: './city.component.html',
  styleUrls: ['./city.component.css']
})
export class CityComponent implements OnInit, OnDestroy {
 
  cityForm: FormGroup;

  @ViewChild(DataTableDirective, { static: false })
  dtElement: DataTableDirective;

  
  isDtInitialized: boolean = false;
  Citty: any[];
  Country: any[];
  State: any[] = null;
  ctt: any;
  dtOptions: DataTables.Settings = {};
  dtTrigger: Subject<any> = new Subject();
  
  ngOnDestroy(): void {
    this.dtTrigger.unsubscribe();
  }
  constructor(
    private fb: FormBuilder,
    private tosterService: ToastrService,
    private loader: NgxUiLoaderService,
    private service: CityService,
    private countryService: CountryService,
    private stateService: StateService
  ) { }
  ngOnInit() {
    this.cityForm = this.fb.group({
      country_id: ['0'],
      state_id: ['0'],
      cityLst: this.fb.array([
        this.addCityFormgroup()
      ])
    });
    this.dtOptions = {
      pagingType: 'full_numbers',
      pageLength: 10,
      processing: true,
      stateSave: true
    };
    this.allCity();
    this.allActiveCountry();
  }
  onSubmit(formdata: any) {
    for (let index in formdata.cityLst) {
      if (formdata.cityLst[index].active === true) {
        formdata.cityLst[index].active = 'Yes';
      } else {
        formdata.cityLst[index].active = 'No';
      }
    }
    console.log(formdata);
    this.loader.start();
    this.service.addCity(formdata).subscribe((res: any) => {
      console.log("added city : "+res);
      if (res.status === true) {
        this.loader.stop();
        this.tosterService.success(res.message);
        this.cityForm.reset();
        (this.cityForm.controls['cityLst'] as FormArray).clear();
        this.addCity();
       
        jQuery("#exampleModalCenter").modal("hide");
      } else {
        this.loader.stop();
        this.tosterService.success(res.message);
        this.cityForm.reset();
        (this.cityForm.controls['cityLst'] as FormArray).clear();
        this.addCity();
      }
    },
      error => {
        console.log(error);
        this.tosterService.error(error);
      });
  }
  addCityFormgroup(): FormGroup {
    return this.fb.group({
      city_name: [''],
      active: [false]
    });
  }
  getCitysFormControls(): AbstractControl[] {
    return (this.cityForm.get('cityLst') as FormArray).controls;
  }
  addCity(): void {
    (this.cityForm.get('cityLst') as FormArray).push(this.addCityFormgroup());
  }
  removeCity(index) {
    (this.cityForm.get('cityLst') as FormArray).removeAt(index);
  }

  // datatable bind methods
  allCity() {
    this.service.getCityById(27).subscribe((res: any) => {
      this.Citty = res;
      console.log("city by id 27 : "+this.Citty);
      if (this.isDtInitialized) {
        this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
          dtInstance.destroy();
          this.dtTrigger.next();
        });
      } else {
        this.isDtInitialized = true;
        this.dtTrigger.next();
      }
    });
  }

  allActiveCountry() {
    this.countryService.getActiveCountry().subscribe((res: any) => {
      this.Country = res;
      console.log("Country : "+this.Country);
    });
  }

  getActiveStateById(Cid: any) {
    if (Cid == 'Select Country') {
      alert('Select Country First');
    } else {
      this.loader.start();
      this.stateService.getActiveStateById(Cid).subscribe((res: any) => {
        this.State = res;
        console.log("Active states : "+ this.State);
        this.loader.stop();
      }, err => {
        this.loader.stop();
        this.tosterService.error('Unable to bind State');
      });
    }
  }
  //        Go BUTTON FUNCTION 
  getCityByState(Sid: any) {
    if (Sid == 'Select State') {
      alert('Select State First');
    } else {
      this.loader.start();
      this.service.getCityById(Sid).subscribe((res: any) => {
        this.Citty = res;
        console.log("city b y id  : "+this.Country);
        this.loader.stop();
        if (this.isDtInitialized) {
          this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
            dtInstance.destroy();
            this.dtTrigger.next();
          });
        } else {
          this.isDtInitialized = true;
          this.dtTrigger.next();
        }
      }, err => {
        this.loader.stop();
        this.tosterService.error('Something wrong');
      });
    }
  }
  activeCity(data: any) {
    const editdata = { city_id: data.city_id, active: 'Yes' };
    this.service.activeCity(editdata).subscribe((res: any) => {
      console.log("Active city :  : "+res);
      this.getCityByState(data.state_id);
    });
  }
  deActiveCity(data: any) {
    const editdata = { city_id: data.city_id, active: 'No' };
    this.service.activeCity(editdata).subscribe((res: any) => {
      this.getCityByState(data.state_id);
    });
  }
}
