import { environment } from './../../../environments/environment';
import { SkillService } from './../../services/skill.service';
import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { Subject } from 'rxjs';
import { DataTableDirective } from 'angular-datatables';
import { FormGroup, FormBuilder, FormArray, AbstractControl, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { NgxUiLoaderService } from 'ngx-ui-loader';

declare var jQuery: any;
export interface AddSkill {
  skill_name: string;
  jobber_min_hr_rate: number;
  jobber_max_hr_rate: number;
  household_hr_rate: number;
  household_month_hr_rate: number;
  gst: number;
  tds: number;
  service_tax: number;
  active: string;
}

@Component({
  selector: 'app-skill',
  templateUrl: './skill.component.html',
  styleUrls: ['./skill.component.css']
})
export class SkillComponent implements OnInit, OnDestroy {
  skillForm: FormGroup;
  editSkillForm: FormGroup;
  Skill: any[];
  picUrl = environment.imgaeUrl;
  Photo: File = null;
  Icon: File = null;
  // datatable
  @ViewChild(DataTableDirective, { static: false })
  dtElement: DataTableDirective;
  // tslint:disable-next-line: no-inferrable-types
  isDtInitialized: boolean = false;
  dtOptions: DataTables.Settings = {};
  dtTrigger: Subject<any> = new Subject();
  ngOnDestroy(): void {
    this.dtTrigger.unsubscribe();
  }
  constructor(
    private service: SkillService,
    private tosterService: ToastrService,
    private loader: NgxUiLoaderService,
    private fb: FormBuilder,
  ) {
    this.skillForm = this.fb.group({
      skill_name: ['', [Validators.required]],
      jobber_min_hr_rate: ['', [Validators.required]],
      jobber_max_hr_rate: ['', [Validators.required]],
      household_hr_rate: ['', [Validators.required]],
      household_month_hr_rate: ['', [Validators.required]],
      commercial_hr_rate: ['', [Validators.required]],
      gst: ['', [Validators.required]],
      tds: ['', [Validators.required]],
      service_tax: ['', [Validators.required]],
      active: [false]
    });
    this.editSkillForm = this.fb.group({
      skill_id: ['', Validators.required],
      skill_name: ['', [Validators.required]],
      jobber_min_hr_rate: ['', [Validators.required]],
      jobber_max_hr_rate: ['', [Validators.required]],
      household_hr_rate: ['', [Validators.required]],
      household_month_hr_rate: ['', [Validators.required]],
      ommercial_hr_rate: ['', [Validators.required]],
      gst: ['', [Validators.required]],
      tds: ['', [Validators.required]],
      service_tax: ['', [Validators.required]],
      active: [false]
    });
  }

  ngOnInit() {
    this.dtOptions = {
      pagingType: 'full_numbers',
      pageLength: 10,
      processing: true,
      stateSave: true
    };
    this.getAllSkill();
  }
  getAllSkill() {
    this.loader.start();
    this.service.getAllSkill().subscribe(res => {
      this.Skill = res;
      if (this.isDtInitialized) {
        this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
          dtInstance.destroy();
          this.dtTrigger.next();
        });
      } else {
        this.isDtInitialized = true;
        this.dtTrigger.next();
      }
      this.loader.stop();
    }, err => {
      this.loader.stop();
      this.tosterService.error(err.message);
    });
  }
  addSkill(skill: any) {
    if (skill.active === true) {
      skill.active = 'Yes';
    } else {
      skill.active = 'No';
    }
    if (this.Photo !== null && this.Icon !== null) {
      // const skillData = new FormData();
      // skillData.append('skill_name', skill.skill_name);
      // skillData.append('jobber_min_hr_rate', skill.jobber_min_hr_rate);
      // skillData.append('jobber_max_hr_rate', skill.jobber_max_hr_rate);
      // skillData.append('household_hr_rate', skill.household_hr_rate);
      // skillData.append('household_month_hr_rate', skill.household_month_hr_rate);
      // skillData.append('commercial_hr_rate', skill.commercial_hr_rate);
      // skillData.append('gst', skill.gst);
      // skillData.append('tds', skill.tds);
      // skillData.append('service_tax', skill.service_tax);
      // skillData.append('active', skill.active);
      // skillData.append('file', this.Photo, this.Photo.name);
      // skillData.append('file1', this.Icon, this.Icon.name);
      // console.log(FormData);
      var dart = new addJobberSkill(skill.skill_name,skill.jobber_min_hr_rate,skill.jobber_max_hr_rate,skill.household_hr_rate,skill.household_month_hr_rate,skill.commercial_hr_rate,skill.gst,skill.tds,skill.service_tax,skill.active,document.getElementById('topic2').innerText,document.getElementById('topic3').innerText)
      this.loader.start();
      this.service.addSkill(dart).subscribe((res: any) => {
        if (res.status) {
          console.log(res);
          this.loader.stop();
          this.tosterService.success(res.message);
          this.skillForm.reset();
          this.getAllSkill();
          jQuery("#addSkill").modal("hide");
        }
      }, err => {
        this.loader.stop();
        this.tosterService.error(err.message);
      });
    } else {
      this.tosterService.error('upload image & icon');
    }
  }
  updateSkill(eSkill: any) {
    if (eSkill.active === true) {
      eSkill.active = 'Yes';
    } else {
      eSkill.active = 'No';
    }

    this.loader.start();
    this.service.updateSkill(eSkill, eSkill.skill_id).subscribe((res: any) => {
      if (res.status) {
        this.loader.stop();
        this.tosterService.success(res.message);
        this.skillForm.reset();
        this.getAllSkill();
        jQuery("#editSkill").modal("hide");
      }
    }, err => {
      this.loader.stop();
      this.tosterService.error(err.message);
    });
  }
  getSkillByID(skill_id: number) {
    this.loader.start();
    this.service.getSkillByID(skill_id).subscribe((res: any) => {
      if (res.active === 'Yes') {
        this.editSkillForm.patchValue({
          active: true
        });
      } else {
        this.editSkillForm.patchValue({
          active: ''
        });
      }
      this.editSkillForm.patchValue({
        skill_id: res.skill_id,
        skill_name: res.skill_name,
        jobber_min_hr_rate: res.jobber_min_hr_rate,
        jobber_max_hr_rate: res.jobber_max_hr_rate,
        household_hr_rate: res.household_hr_rate,
        household_month_hr_rate: res.household_month_hr_rate,
        ommercial_hr_rate: res.commercial_hr_rate,
        gst: res.gst,
        tds: res.tds,
        service_tax: res.service_tax
      });
      this.loader.stop();
    }, err => {
      this.loader.stop();
      this.tosterService.error(err.message);
    });
  }
  onImageSelect(evt: { target: { files: any[]; }; }) {
    this.Photo = evt.target.files[0];
    var reader = new FileReader();
    reader.onload = (function(theFile) {
      return function(e) {
        var binaryData:any = e.target.result;
        var imgString:any = window.btoa(binaryData) ;
        console.log("my result1====",imgString);
        document.getElementById('topic2').innerHTML =imgString;
        
      };
    })(this.Photo);
    reader.readAsBinaryString(this.Photo);
  }
  onIconSelect(evt: { target: { files: any[]; }; }) {
    this.Icon = evt.target.files[0];
    var reader = new FileReader();
    reader.onload = (function(theFile) {
      return function(e) {
        var binaryData:any = e.target.result;
        var imgString:any = window.btoa(binaryData);
        console.log("my result1====",imgString);
        document.getElementById('topic3').innerHTML =imgString;
        
      };
    })(this.Icon);
    reader.readAsBinaryString(this.Icon);
  }
  // onImageSelect(file: any) {
  //   this.Photo = file.target.files[0];
  //   console.log(this.Photo.name);
  // }
  // onIconSelect(file: any) {
  //   this.Icon = file.target.files[0];
  //   console.log(this.Icon);
  // }
}


export class addJobberSkill{
  skill_name:String;
  jobber_min_hr_rate:String;
  jobber_max_hr_rate:String;
  household_hr_rate:String;
  household_month_hr_rate:String;
  commercial_hr_rate:String;
  gst:String;
  tds:String;
  service_tax:String;
  active:String;
  skill_image:String;
  skill_icon:String;
  constructor(skill_name:String,jobber_min_hr_rate:String,jobber_max_hr_rate:String, household_hr_rate:String,household_month_hr_rate:String,commercial_hr_rate:String,gst:String,  tds:String,service_tax:String,active:String,skill_image:String,skill_icon:String){
    this.skill_name=skill_name;
    this.jobber_min_hr_rate=jobber_min_hr_rate;
    this.jobber_max_hr_rate=jobber_max_hr_rate;
    this.household_hr_rate=household_hr_rate;
    this.household_month_hr_rate=household_month_hr_rate;
    this.commercial_hr_rate=commercial_hr_rate;
    this.gst=gst;
    this.tds=tds;
    this.service_tax=service_tax;
    this.active=active;
    this.skill_image=skill_image;
    this.skill_icon=skill_icon;
  }
}