

import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { Subject } from 'rxjs';
import { DataTableDirective } from 'angular-datatables';
import { FormBuilder, Validators } from '@angular/forms';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { ToastrService } from 'ngx-toastr';

import Swal from 'sweetalert2';
import { DatePipe } from '@angular/common';
import { join } from 'path';
import { stringify } from '@angular/compiler/src/util';
import { environment } from 'src/environments/environment.prod';
import { CovidService } from 'src/app/services/covid.service';

@Component({
  selector: 'app-covid',
  templateUrl: './covid.component.html',
  styleUrls: ['./covid.component.css']
})
export class CovidComponent implements OnInit, OnDestroy {
  imageForm1 = this.fb.group({
    
    topicName1: [''],
    
  });

  imageDirectoryPath:any=environment.foodImgUrl;
  

  @ViewChild(DataTableDirective, { static: false })
  dtElement: DataTableDirective;

  
  isDtInitialized: boolean = false;
  
  dtOptions: DataTables.Settings = {};
  dtTrigger: Subject<any> = new Subject();
  foods: any;
  postResponse: any;
  postResponse2: any;
  // today: Date;
  dd: number;
  mm: number;
  yyyy: number;
  today1: string;

  VarDate: any;
  conv_date: any;
  start: any;
  end: any;
  today: string;
  startingDate: any;
  startingDatev: any;
  
  ngOnDestroy(): void {
    this.dtTrigger.unsubscribe();
  }
  constructor(
    private fb: FormBuilder,
    private tosterService: ToastrService,
    private loader: NgxUiLoaderService,
    private service: CovidService,
    public datepipe: DatePipe
   
  ) { }
  ngOnInit() {
    this.foodDetails();
//     this.today = new Date();
// this.dd = this.today.getDate();
// this.mm = this.today.getMonth()+1; //January is 0!
// this.yyyy = this.today.getFullYear();
 

//     this.today1 = (this.yyyy+'-'+this.mm+'-'+this.dd);
// document.getElementById("start").setAttribute("min", this.today1);

    this.today=this.datefilter1(new Date());
    console.log(this.today);

    const date = new Date();
    date.setDate(date.getDate() + 14);

    this.startingDate= this.datefilter1(date);
    
    console.log("00000000000000000000000000000000000000000000000000"+this.startingDate);
   
    this.dtOptions = {
      pagingType: 'full_numbers',
      pageLength: 10,
      processing: true,
      stateSave: true
    };
    
    
  }
  
  foodDetails() {
    console.log("calling food");
      this.loader.start();
      this.service.getCovidFoodDetails().subscribe((res: any) => {
        this.foods = res;
        
        this.loader.stop();
        if (this.isDtInitialized) {
          this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
            dtInstance.destroy();
            this.dtTrigger.next();
          });
        } else {
          this.isDtInitialized = true;
          this.dtTrigger.next();
        }
      }, err => {
        this.loader.stop();
        this.tosterService.error('Something wrong');
      });
    
  }

 
  markItAsAccepted(status:any, food:any, id :any ,i:any,start:any,end:any,pastStatus:any){
    if(pastStatus=="Approved" || pastStatus=="Declined"){
      alert("It has already been "+pastStatus)
    }
    else if( start==='' && end===''){
     
      alert("Please enter start & end date");
    }
    else if( start===''){
     
      alert("Please enter start date ");
    }
    else if( end===''){
     
      alert("Please enter end date");
    }
    else{
      var r=confirm("Are You sure to accept it? If 'YES', Please press 'OK', otherwise press 'CANCEL'");
      if(r== true){
        this.loader.start();
      this.service.updateFoodReview(status,food,id,start,end).subscribe((res: any) => {
        this.postResponse = res;
        console.log("foods : "+JSON.stringify(this.postResponse));
        if (res.status === true) {
          this.loader.stop();
          this.tosterService.success("Application is successfully acceptded");
         
         
          document.getElementById(i).innerText="ACCEPTED";
          document.getElementById(i).style.color="green";
          document.getElementById(i).style.fontWeight="bold";
          
        } else {
          this.loader.stop();
          this.tosterService.success(res.message);
          
          
        }
      });
      }
      else{
        alert("Please review again...");
      }
    }
    
    
    
  }
  markItAsDeclined(status:any, food:any, id :any,i:any,start:any,end:any,pastStatus:any){
    if(pastStatus=="Approved" || pastStatus=="Declined"){
      alert("It has already been "+pastStatus)
    }
    else if( start==='' && end===''){
     
      alert("Please enter start & end date");
    }
    else if( start===''){
     
      alert("Please enter start date ");
    }
    else if( end===''){
     
      alert("Please enter end date");
    }
    else{
      var r=confirm("Are You sure to decline it? If 'YES', Please press 'OK', otherwise press 'CANCEL'");
      if(r== true){
        this.loader.start();
      this.service.updateFoodReview(status,food,id,start,end).subscribe((res: any) => {
        
        this.postResponse2 = res;
  
        console.log("foods : "+JSON.stringify(this.postResponse));
        if (res.status === true) {
          this.loader.stop();
          this.tosterService.success("Application is declined");
          
          document.getElementById(i).innerText="DECLINED";
          document.getElementById(i).style.color="red";
          document.getElementById(i).style.fontWeight="bold";
  
          
         
          
        } else {
          this.loader.stop();
          this.tosterService.success(res.message);
          
          
        }
      });
      }
      else{
        alert("Please review again...");
      }
    }
    
   
    
  }
  datefilter(Date: any) {
    this.VarDate = Date;
    let conv_date = this.datepipe.transform(this.VarDate, 'dd-MM-y');
    console.log(this.conv_date);
    return conv_date;
  }
  datefilter1(Date: any) {
    this.VarDate = Date;
    let conv_date = this.datepipe.transform(this.VarDate, 'y-MM-dd');
    console.log(this.conv_date);
    return conv_date;
  }


  imageNme(topic_image){
    this.imageForm1.patchValue({
      topicName1: this.imageDirectoryPath+topic_image
    });
  }

  startDate1(start:any){
    console.log(" nnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnn"+start);
    this.startingDatev=start;

    console.log(" nnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnn"+this.startingDatev); 
 }

 
      
 }

