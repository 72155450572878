

import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { Subject } from 'rxjs';
import { DataTableDirective } from 'angular-datatables';
import { FormBuilder, Validators } from '@angular/forms';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { ToastrService } from 'ngx-toastr';

import Swal from 'sweetalert2';
import { DatePipe } from '@angular/common';
import { join } from 'path';
import { stringify } from '@angular/compiler/src/util';
import { environment } from 'src/environments/environment.prod';
import { CovidService } from 'src/app/services/covid.service';
import { AdServiceService } from 'src/app/services/ad-service.service';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'app-advertisement',
  templateUrl: './advertisement.component.html',
  styleUrls: ['./advertisement.component.css']
})
export class AdvertisementComponent implements OnInit, OnDestroy {
  

  imageDirectoryPath:any=environment.adImgUrl;
  

  @ViewChild(DataTableDirective, { static: false })
  dtElement: DataTableDirective;

  
  isDtInitialized: boolean = false;
  
  dtOptions: DataTables.Settings = {};
  dtTrigger: Subject<any> = new Subject();
  foods: any;
  postResponse: any;
  postResponse2: any;
  // today: Date;
  dd: number;
  mm: number;
  yyyy: number;
  today1: string;

  VarDate: any;
  conv_date: any;
  start: any;
  end: any;
  today: string;
  startingDate: any;
  startingDatev: any;
  ads: any;
  imageForm1: FormGroup;
  advForm: FormGroup;
  imgString:any;
  assetForm: FormGroup;
  ngOnDestroy(): void {
    this.dtTrigger.unsubscribe();
  }
  constructor(
    private fb: FormBuilder,
    private tosterService: ToastrService,
    private loader: NgxUiLoaderService,
    private service: AdServiceService,
    public datepipe: DatePipe
   
  ) {
    
    
   }

 
  ngOnInit() {
    this.getAllAdvertisements();


    this.today=this.datefilter1(new Date());
    console.log(this.today);

    const date = new Date();
    date.setDate(date.getDate() + 14);

    this.startingDate= this.datefilter1(date);
    
    console.log("00000000000000000000000000000000000000000000000000"+this.startingDate);
   
    this.dtOptions = {
      pagingType: 'full_numbers',
      pageLength: 10,
      processing: true,
      stateSave: true
    };
    
    
  }
  
  getAllAdvertisements() {
    console.log("calling ad");
      this.loader.start();
      this.service.getAllAds().subscribe((res: any) => {
        this.ads = res;
        
        this.loader.stop();
        if (this.isDtInitialized) {
          this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
            dtInstance.destroy();
            this.dtTrigger.next();
          });
        } else {
          this.isDtInitialized = true;
          this.dtTrigger.next();
        }
      }, err => {
        this.loader.stop();
        this.tosterService.error('Something went wrong');
      });
    
  }

  onSubmit(data:any){

  }
 
  markItAsAccepted(active:any,id :any){
    console.log("======================="+active);
      if(active=='Yes'){
        alert("It is already active.")
      }
      else{
        var r=confirm("Are You sure to activate this advertisement? If 'YES', Please press 'OK', otherwise press 'CANCEL'");
      if(r== true){
        this.loader.start();
      this.service.activeDeactiveAd("Yes",id).subscribe((res: any) => {
        
        this.postResponse2 = res;
  
        console.log("foods : "+JSON.stringify(this.postResponse));
        if (res.status === true) {
          this.loader.stop();
          this.tosterService.success("This advertisement is now activated");
          this.getAllAdvertisements();
          
        } else {
          this.loader.stop();
          this.tosterService.success(res.message);
          
          
        }
      });
      }
      
    }

  }
  markItAsDeclined(active:any,id :any){
    if(active=='No'){
      alert("It is already deactive.")
    }
    else{
      var r=confirm("Are You sure to deactive this advertisement? If 'YES', Please press 'OK', otherwise press 'CANCEL'");
      if(r== true){
        this.loader.start();
      this.service.activeDeactiveAd("No",id).subscribe((res: any) => {
        
        this.postResponse2 = res;
  
        console.log("foods : "+JSON.stringify(this.postResponse));
        if (res.status === true) {
          this.loader.stop();
          this.tosterService.success("This advertisement is now deactivated");
          this.getAllAdvertisements();
     
        } else {
          this.loader.stop();
          this.tosterService.success(res.message);
          
          
        }
      });
      }
      
    }
  
  }
  datefilter(Date: any) {
    this.VarDate = Date;
    let conv_date = this.datepipe.transform(this.VarDate, 'dd-MM-y');
    console.log(this.conv_date);
    return conv_date;
  }
  datefilter1(Date: any) {
    this.VarDate = Date;
    let conv_date = this.datepipe.transform(this.VarDate, 'y-MM-dd');
    console.log(this.conv_date);
    return conv_date;
  }


  imageNme(topic_image){
    this.imageForm1.patchValue({
      topicName1: this.imageDirectoryPath+topic_image
    });
  }

  startDate1(start:any){
    console.log(" nnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnn"+start);
    this.startingDatev=start;

    console.log(" nnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnn"+this.startingDatev); 
 }

 onFileSelect(evt) {
  var f = evt.target.files[0]; 
  var reader = new FileReader();
  
  reader.onload = (function(theFile) {
    
    return function(e:any) {
      var binaryData = e.target.result;
      
      var imgString = window.btoa(binaryData);
      console.log(imgString);
      document.getElementById('topic11').innerHTML =imgString;
      
    };
  })(f);
  reader.readAsBinaryString(f);
  console.log("out side "+document.getElementById("topic11").innerText);
  
}


 
      
 }

