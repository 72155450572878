import { DepartmentService } from './../../services/department.service';
import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { Subject } from 'rxjs';
import { DataTableDirective } from 'angular-datatables';
import { CountryService } from 'src/app/services/country.service';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { ToastrService } from 'ngx-toastr';
import { FormGroup, FormBuilder, FormArray, AbstractControl, Validators } from '@angular/forms';
declare var jQuery: any;
@Component({
  selector: 'app-department',
  templateUrl: './department.component.html',
  styleUrls: ['./department.component.css']
})
export class DepartmentComponent implements OnInit, OnDestroy {
  @ViewChild(DataTableDirective, { static: false })
  dtElement: DataTableDirective;
  deptForm: FormGroup;
  deptFormEdit: FormGroup;
  // tslint:disable-next-line: no-inferrable-types
  isDtInitialized: boolean = false;
  depatments: any[];
  dtOptions: DataTables.Settings = {};
  dtTrigger: Subject<any> = new Subject();
  ngOnDestroy(): void {
    this.dtTrigger.unsubscribe();
  }
  constructor(
    private service: DepartmentService,
    private countryService: CountryService,
    private loader: NgxUiLoaderService,
    private fb: FormBuilder,
    private tosterService: ToastrService,

  ) {
    this.deptForm = this.fb.group({
      department_name: ['', Validators.required],
      active: [''],
    });
    this.deptFormEdit = this.fb.group({
      department_id: [''],
      department_name: ['', Validators.required],
      active: [''],
    });
  }


  ngOnInit() {
    this.dtOptions = {
      pagingType: 'full_numbers',
      pageLength: 10,
      processing: true,
      stateSave: true
    };
    this.allDepartments();
  }

  allDepartments() {
    this.service.getAlldepartment().subscribe((res: any) => {
      this.depatments = res;
      if (this.isDtInitialized) {
        this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
          dtInstance.destroy();
          this.dtTrigger.next();
        });
      } else {
        this.isDtInitialized = true;
        this.dtTrigger.next();
      }
    });
  }
  getDeptByID(dept_id: number) {
    console.log(dept_id);
    this.loader.start();
    this.service.getDeptByID(dept_id).subscribe((res: any) => {
      console.log(res);
      if (res.active === 'Yes') {
        this.deptFormEdit.patchValue({
          active: true
        });
      } else {
        this.deptFormEdit.patchValue({
          active: ''
        });
      }
      this.deptFormEdit.patchValue({
        department_id: res.department_id,
        department_name: res.department_name
      });
      this.loader.stop();
    }, err => {
      this.loader.stop();
      this.tosterService.error(err.message);
    });
  }

  edit(data: any) {
    if (data.active === true) {
      data.active = 'Yes';
    } else {
      data.active = 'No';
    }

    this.loader.start();
    this.service.updateDepartment(data, data.department_id).subscribe((res: any) => {
      if (res.status) {
        this.loader.stop();
        this.tosterService.success(res.message);
        this.deptFormEdit.reset();
        this.allDepartments();
        jQuery('#EditDept').modal('hide');
      }
    }, err => {
      this.loader.stop();
      this.tosterService.error(err.message);
    });
  }
  delete() {

  }
  add(formdata: any) {
    console.log(formdata.active);
    if (formdata.active === true) {
      formdata.active = 'Yes';
    } else {
      formdata.active = 'No';
    }
    console.log(formdata);
    this.service.addDept(formdata).subscribe((res: any) => {

      if (res.status === true) {
        this.tosterService.success(res.message);
        this.deptForm.reset();
        this.allDepartments();
        // tslint:disable-next-line:quotemark
        jQuery("#exampleModalCenter").modal("hide");
      }
    },
      error => {
        this.tosterService.error(error);
      });

  }
}
