import { environment } from './../../../environments/environment';
import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { Subject } from 'rxjs';
import { DataTableDirective } from 'angular-datatables';
import { VisitorService } from 'src/app/services/visitor.service';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { ToastrService } from 'ngx-toastr';
import { BsDatepickerConfig } from 'ngx-bootstrap/datepicker';
import { AreaService } from './../../services/area.service';
import { CityService } from './../../services/city.service';
import { StateService } from './../../services/state.service';
import { ApartmentService } from './../../services/apartment.service';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ExcelServicesService } from 'src/app/services/excel-services.service';

@Component({
  selector: 'app-regular-visitor',
  templateUrl: './regular-visitor.component.html',
  styleUrls: ['./regular-visitor.component.css']
})
export class RegularVisitorComponent implements OnInit {
  @ViewChild(DataTableDirective, { static: false })
  dtElement: DataTableDirective;
  // tslint:disable-next-line: no-inferrable-types
  isDtInitialized: boolean = false;
  datePickerConfig: Partial<BsDatepickerConfig>;
  userPicUrl = environment.userPicUrl;
  visitorForm: FormGroup;
  Visitors: any[];
  // fliter purpose
  State: any[];
  City: any[];
  Area: any[];
  cApartment: any[];

  dtOptions: DataTables.Settings = {};
  dtTrigger: Subject<any> = new Subject();
  ngOnDestroy(): void {
    this.dtTrigger.unsubscribe();
  }
  constructor(
    private fb: FormBuilder,
    private service: VisitorService,
    private loader: NgxUiLoaderService,
    private tosterService: ToastrService,
    private stateService: StateService,
    private cityService: CityService,
    private areaService: AreaService,
    private ApartService: ApartmentService,
    private excelService: ExcelServicesService
  ) {
    let maxdate = new Date();
    // maxdate.setFullYear(maxdate.getFullYear() - 18);
    this.datePickerConfig = Object.assign({},
      {
        containerClass: 'theme-dark-blue',
        showWeekNumbers: false,
        dateInputFormat: 'DD-MM-YYYY',
        maxDate: maxdate,
      });
    this.visitorForm = this.fb.group({
      state_id: ['0', [Validators.required]],
      city_id: ['0', [Validators.required]],
      area: ['0', [Validators.required]],
      app_type: ['0', [Validators.required]],
      app_id: ['0', [Validators.required]],
      from: ['', [Validators.required]],
      to: ['', [Validators.required]]
    });
  }

  ngOnInit() {
    this.dtOptions = {
      pagingType: 'full_numbers',
      pageLength: 10,
      processing: true,
      stateSave: true
    };
    this.allRegularVisitor();
    this.getAllActiveState();
  }
  allRegularVisitor() {
    this.loader.start();
    this.service.getRegularVisitor().subscribe((res: any) => {
      this.Visitors = res;
      console.log(res);
      this.loader.stop();
      if (this.isDtInitialized) {
        this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
          dtInstance.destroy();
          this.dtTrigger.next();
        });
      } else {
        this.isDtInitialized = true;
        this.dtTrigger.next();
      }
    }, err => {
      this.loader.stop();
    });
  }
  // filter visitor
  getAllActiveState() {
    this.stateService.getActiveState().subscribe(res => {
      this.State = res;
    }, err => {
      this.tosterService.error(err.message);
    });
  }
  getFactiveCity(s_id) {
    this.loader.start();
    this.cityService.getActiveCityById(s_id).subscribe(res => {
      this.loader.stop();
      this.City = res;
    }, err => {
      this.loader.stop();
      this.tosterService.error(err.message);
    });
  }
  getFactiveArea(c_id) {
    this.loader.start();
    this.areaService.getActiveAreaByID(c_id).subscribe(res => {
      this.loader.stop();
      this.Area = res;
    }, err => {
      this.loader.stop();
      this.tosterService.error(err.message);
    });
  }
  getActiveAppOffc(type: any, area_id) {
    if (type === 'Type') {
      this.cApartment = null;
      alert('Please choose apartment or office');
    } else if (type === 'A') {
      this.ApartService.getActiveApartmentByArea(area_id).subscribe((res: any) => {
        this.cApartment = res;
      });
    } else if (type === 'PO') {
      this.ApartService.getActiveOfficeByArea(area_id,'PO').subscribe((res: any) => {
        this.cApartment = res;
      });
    }
    else if (type === 'GO') {
      this.ApartService.getActiveOfficeByArea(area_id,'GO').subscribe((res: any) => {
        this.cApartment = res;
      });
    }
  }

  // filter method
  filterVisitorList(data: any, fdate: string, tdate: string) {
    data.from = fdate;
    data.to = tdate;
    console.log(data);
    this.loader.start();
    this.service.getRegularVisitorByFilter(data).subscribe((res: any) => {
      console.log(res);
      this.Visitors = res;
      if (this.isDtInitialized) {
        this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
          dtInstance.destroy();
          this.dtTrigger.next();
        });
      } else {
        this.isDtInitialized = true;
        this.dtTrigger.next();
      }
      this.loader.stop();
    }, err => {
      this.loader.stop();
      this.tosterService.error(err);
    });
  }

  // generate report
  exportAsXLSX(): void {
    this.excelService.exportAsExcelFile(this.Visitors, 'visitorLog');
  }
}
