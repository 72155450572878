import { City } from './../models/city';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { environment } from './../../environments/environment';
import { catchError} from 'rxjs/operators';
import { runInThisContext } from 'vm';


@Injectable({
  providedIn: 'root'
})
export class InvoiceService {
  httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json'})
  };
  constructor(private http: HttpClient) { }
  getAllInvoice() {
    const url = 'customer/allcustomerbill';
    return this.http.get<any[]>(environment.baseUrl + '' + url);
  }
  getSplitInvoiceById(CustID: number) {
    const url = 'customer/unpaidCust/';
    return this.http.get(environment.baseUrl + '' + url + CustID);
  }
  getFilterInvoice(state, city, area, status) {
    const url = 'customer/customerbillfilter/';
    return this.http.get<any[]>(environment.baseUrl + url + state + '/' + city + '/' + area + '/' + status);
  }
}
