import { City } from './../../models/city';
import { StateService } from './../../services/state.service';
import { CountryService } from './../../services/country.service';
import { CityService } from './../../services/city.service';
import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { Subject } from 'rxjs';
import { DataTableDirective } from 'angular-datatables';
import { FormGroup, FormBuilder, FormArray, AbstractControl, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { NgxUiLoaderService } from 'ngx-ui-loader';


import { CouponserviceService } from './../../services/couponservice.service';
declare var jQuery: any;

import { DatePipe } from '@angular/common';
import { Input } from '@angular/core';
import {FormControl } from '@angular/forms';
import { NavigationEnd, Router } from '@angular/router';

import { HttpClient } from '@angular/common/http';

import { Observable } from 'rxjs';


declare var jQuery: any;

@Component({
  selector: 'app-coupons',
  templateUrl: './coupons.component.html',
  styleUrls: ['./coupons.component.css']
})
export class CouponsComponent implements OnInit, OnDestroy {

  
  reactiveForm: FormGroup;
  updateForm: FormGroup;

  @ViewChild(DataTableDirective, { static: false })
  dtElement: DataTableDirective;

  
  isDtInitialized: boolean = false;
 
  couponFilter: any[];
  Country: any[];
  State: any[] = null;
  ctt: any;
  dtOptions: DataTables.Settings = {};
  dtTrigger: Subject<any> = new Subject();
  areas: any;
  k1=0;
  respp="";
  [x: string]: any;
 @Input() pcities: any;
  filterForm: any=FormGroup;
 
  udate:any;
  public userFile:any = File =null;
  imgsrc:any=[];

  cats:any=[];
  ucities:any=[];
  uareas:any=[];
  coupons:any=[];
  coupon1:any=[];
  
  cities:any=[];
 
  fcategories:any=[];
  categories:any=[];
  
  imgURL:any="";
  ipath:any=[];
  
  popUpFormId:any;
  totalRecords:string;
  cDate:any;
  
//  imageDirectoryPath:any='http://dev01.ohhpro.com:9080/uploads/user_pics/';
  imageDirectoryPath:any='https://ohhpro.com:8443/uploads/user_pics/';

  public  imagePath;
  date:Date = new Date();
 
    visibilities=["city","area"];
    
    selected: any;
    types=["online","instore"];
    message: string;


  
  ngOnDestroy(): void {
    this.dtTrigger.unsubscribe();
  }
  constructor(
    public datepipe: DatePipe, private router: Router, 
    private fb2 : FormBuilder ,
    private fb3 : FormBuilder ,public http: HttpClient,
    
    
    private loader: NgxUiLoaderService,
    private service: CityService,
    private countryService: CountryService,
    private stateService: StateService ,


    private fb: FormBuilder,
    private tosterService: ToastrService,
    
    private couponserviceService: CouponserviceService
  ) {
     
    this.reactiveForm=this.fb.group(
      {
        country:[0, Validators.required],
        state:[0, Validators.required],
        city : [0, Validators.required],
        area : [0, Validators.required],
        visibility : ['0', Validators.required],
        category : ['0', Validators.required],
        vendor : ["", Validators.required],
        coupon_desc  : ["", Validators.required],
        coupon_code  : ["", Validators.required],
        coupon_minimum_amount  :[ 0, Validators.required],
        online_or_instore  : ["", Validators.required],
        coupon_expiry_date  :["", Validators.required],
        terms_and_conditions  : ["", Validators.required],
        disclaimer  : ["", Validators.required],
        filee: []
      }
    );
    
    this.updateForm=this.fb3.group(
      {
        coupon_pic:[""],
        coupon_id : [0, Validators.required],
        city : [0, Validators.required],
        area : [0, Validators.required],
        visibility : [0, Validators.required],
        category : [0,Validators.required],
        vendor : new FormControl(),
        coupon_desc  : new FormControl(),
        coupon_code  : new FormControl(),
        coupon_minimum_amount  : new FormControl(),
        online_or_instore  : [0, Validators.required],
        coupon_expiry_date  : new FormControl(),
        terms_and_conditions  : new FormControl(),
        disclaimer  : new FormControl(),
      }
    );

     
      this.router.events.subscribe((e) => {
        if (e instanceof NavigationEnd) {
            
            
            console.log("fareas");
            console.log(this.fareas);
            
            console.log("areas");
            console.log(this.areas);
            this.getCategory();
            
            console.log("uareas");
            console.log(this.uareas);
            
            
        }
     });
   }
  ngOnInit() {
    this.cityForm = this.fb.group({
      country_id: ['0'],
      state_id: ['0'],
      cityLst: this.fb.array([
        this.addCityFormgroup()
      ])
    });
    this.dtOptions = {
      pagingType: 'full_numbers',
      pageLength: 10,
      processing: true,
      stateSave: true
    };
    // this.allCity();
    this.allActiveCountry();
    // this.getCategory();

    // this.getAllUCity();
    // this.getUAreaByCity();

  }
  onFileSelect(event){
  this.k1=0;
    
    if(event.target.files.length>0){
    const file= event.target.files[0];
   console.log(file);
    this.userFile= file;

    var reader = new FileReader();

    this.imgURL= this.file ;    //add index signature,otherwise it was showing error .did it by quick fix 2nd option
    reader.readAsDataURL(this.userFile);
    reader.onload=(event:any) => {
      this.imgURL=event.target.result;
      console.log("URL = "+this.imgURL);
      console.log("url");
    }
   this.k1=1;
    }
  }
  reload1(){
    // this.reactiveForm.reset();
     
    console.log("reload function is called ");
     window.location.reload();
    //  this.getAllCoupons();
    // this._document.defaultView.location.reload();

  }
  saveForm(submitForm: FormGroup){
    console.log("k1 = "+this.k1);
    if(this.k1== 0){
      alert("Please insert Coupon Photo");
    }
    else if(submitForm.valid){
      this.loader.start();
      const user= submitForm.value;
      const formData=new FormData();
      console.log("raw date : "+user.coupon_expiry_date);
      this.cdate=this.datefilter(user.coupon_expiry_date);
      
      formData.append('area', (user.area));
      formData.append('city', (user.city));
      formData.append('visibility', (user.visibility));
      formData.append('category', (user.category));
      formData.append('vendor', (user.vendor));
      formData.append('coupon_desc', (user.coupon_desc));

      formData.append('coupon_code', (user.coupon_code));
      formData.append('coupon_minimum_amount', (user.coupon_minimum_amount));
      formData.append('online_or_instore', (user.online_or_instore));
      formData.append('coupon_expiry_date', ((this.cdate ) ) );
      formData.append('terms_and_conditions',(user.terms_and_conditions));
      formData.append('disclaimer', (user.disclaimer));
      
      formData.append('file',this.userFile, this.userFile.value);
      console.log(formData);
      
      this.couponserviceService.saveUserProfile(formData).subscribe((response)=>{
      console.log(response);
      this.respp=response.status;
      if (response.status === true) {
        this.loader.stop();
        this.tosterService.success(response.message);
        this.reactiveForm.reset();
        this.reload1();
       
        jQuery("#addCoupon").modal("hide");
      } else {
        this.loader.stop();
        this.tosterService.success(response.message);
        this.reactiveForm.reset();
        
      }
      
      
      })
    }
    
    // this.k1=0;
    
    // this.imgURL="";
    // window.location.reload();
    // this.reload1();
  }
//   delay(ms: number) {
//     return new Promise( resolve => setTimeout(resolve, ms) );
// }

  //update coupon

  updateCoupon(updateForm: FormGroup){
    if(this.k1== 0){
      alert("Please insert Coupon Photo");
    }
    else if(updateForm.valid){
this.loader.start();
      const user= updateForm.value;
      const formData=new FormData();
      console.log("raw date : "+user.coupon_expiry_date);
      this.udate=this.datefilter(user.coupon_expiry_date);
      console.log("changed date"+this.cDate);
      
      formData.append('coupon_id', (user.coupon_id));
      console.log("id="+user.coupon_id);
      formData.append('city', (user.city));
      formData.append('area', (user.area));
      formData.append('visibility', (user.visibility));
      formData.append('category', (user.category));
      formData.append('vendor', (user.vendor));
      formData.append('coupon_desc', (user.coupon_desc));

      formData.append('coupon_code', (user.coupon_code));
      formData.append('coupon_minimum_amount', (user.coupon_minimum_amount));
      formData.append('online_or_instore', (user.online_or_instore));
      formData.append('coupon_expiry_date', (this.udate) );
      formData.append('terms_and_conditions',(user.terms_and_conditions));
      formData.append('disclaimer', (user.disclaimer));
      

      formData.append('file',this.userFile, this.userFile.value);
      console.log(formData);
      console.log(user.coupon_expiry_date)
      console.log(this.datefilter(user.coupon_expiry_date));
      this.couponserviceService.updateCouponFunction(formData).subscribe((response)=>{
      console.log(response);
      console.log("coupon updated successfully");
      if (response.status === true) {
        this.loader.stop();
        this.tosterService.success(response.message);
        this.updateForm.reset();
        this.reload1();
        
       
        jQuery("#addCoupon").modal("hide");
      } else {
        this.loader.stop();
        this.tosterService.success(response.message);
        this.updateForm.reset();
      }
      
      })
    }
    else{
      console.log("coupon did not updated");       
    }
  }
  onSubmit(formdata: any) {
    
    for (let index in formdata.cityLst) {
      if (formdata.cityLst[index].active === true) {
        formdata.cityLst[index].active = 'Yes';
      } else {
        formdata.cityLst[index].active = 'No';
      }
    }
    console.log(formdata);
    this.loader.start();
    this.service.addCity(formdata).subscribe((res: any) => {
      console.log(res);
      if (res.status === true) {
        this.loader.stop();
        this.tosterService.success(res.message);
        this.cityForm.reset();
        (this.cityForm.controls['cityLst'] as FormArray).clear();
        this.addCity();
       
        jQuery("#exampleModalCenter").modal("hide");
      } else {
        this.loader.stop();
        this.tosterService.success(res.message);
        this.cityForm.reset();
        (this.cityForm.controls['cityLst'] as FormArray).clear();
        this.addCity();
      }
    },
      error => {
        console.log(error);
        this.tosterService.error(error);
      });
  }

  getAllFCity(){
    this.couponserviceService.getCity().subscribe(
      data=>{
        this.fcities=data; 
    },
      error => {
        console.log("cant print");
      }
    )

  }
  getAllUCity(){
    this.couponserviceService.getCity().subscribe(
      data=>{
        this.ucities=data; 
    },
      error => {
        console.log("cant print");
      }
    )

  }
  
  getUAreaByCity(){
    this.y=(<HTMLInputElement>document.getElementById('ucity')).value
    console.log("y value ="+this.y)

    
    this.couponserviceService.getAreaByCityId(this.y).subscribe(
      data=>{
       
        this.uareas=data;
        console.log("y ")
        console.log(this.y);
        console.log(this.uareas);
    },
      error => {
        console.log("cant print");
       
      }
    )
  }
  






  addCityFormgroup(): FormGroup {
    return this.fb.group({
      city_name: [''],
      active: [false]
    });
  }
  getCitysFormControls(): AbstractControl[] {
    return (this.cityForm.get('cityLst') as FormArray).controls;
  }
  addCity(): void {
    (this.cityForm.get('cityLst') as FormArray).push(this.addCityFormgroup());
  }
  removeCity(index) {
    (this.cityForm.get('cityLst') as FormArray).removeAt(index);
  }

  // datatable bind methods
  allCity() {
    this.service.getCityById(27).subscribe((res: any) => {
      this.Citty = res;
      if (this.isDtInitialized) {
        this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
          dtInstance.destroy();
          this.dtTrigger.next();
        });
      } else {
        this.isDtInitialized = true;
        this.dtTrigger.next();
      }
    });
  }

  allActiveCountry() {
    this.countryService.getActiveCountry().subscribe((res: any) => {
      this.Country = res;
      console.log("Country : "+this.Country);
    });
  }

  getActiveStateById(Cid: any) {
    if (Cid == 'Select Country') {
      alert('Select Country First');
    } else {
      this.loader.start();
      this.stateService.getActiveStateById(Cid).subscribe((res: any) => {
        this.State = res;
        this.loader.stop();
      }, err => {
        this.loader.stop();
        this.tosterService.error('Unable to bind State');
      });
    }
  }
  // Go BUTTON FOR CITY
  getCityByState(Sid: any) {
    if (Sid == 'Select State') {
      alert('Select State First');
    } else {
      this.loader.start();
      this.service.getCityById(Sid).subscribe((res: any) => {
        this.Citty = res;
        this.loader.stop();
        if (this.isDtInitialized) {
          this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
            dtInstance.destroy();
            this.dtTrigger.next();
          });
        } else {
          this.isDtInitialized = true;
          this.dtTrigger.next();
        }
      }, err => {
        this.loader.stop();
        this.tosterService.error('Something wrong');
      });
    }
  }

  getAreaByCity(Sid: any) {
    if (Sid == 'Select City') {
      alert('Select City First');
    } else {
      this.loader.start();
      this.service.getAreaById(Sid).subscribe((res: any) => {
        this.areas = res;
        this.loader.stop();
        if (this.isDtInitialized) {
          this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
            dtInstance.destroy();
            this.dtTrigger.next();
          });
        } else {
          this.isDtInitialized = true;
          this.dtTrigger.next();
        }
      }, err => {
        this.loader.stop();
        this.tosterService.error('Something wrong');
      });
      console.log("AREA: "+this.areas);
    }
  }

  getCategory(){
    this.loader.start();
    this.couponserviceService.getallCat().subscribe(
      data=>{
        this.cats=data; 
        this.loader.stop();
        if (this.isDtInitialized) {
          this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
            dtInstance.destroy();
            this.dtTrigger.next();
          });
        } else {
          this.isDtInitialized = true;
          this.dtTrigger.next();
        }
      }, err => {
        this.loader.stop();
        this.tosterService.error('Something wrong');
      });
      console.log("AREA: "+this.areas);
    }
  
  
// go button for coupon
searchCoupons(p:any,q:any,r:any,s:any) {
  console.log("p= "+p );
  console.log("q= "+q );
  console.log("r= "+r );
  console.log("s= "+s );
  if (p == 'Select City' || q == 'Select Area' || r == 'Select Category' || s == 'Select Type') {
    alert('Select Correct Option');
  } else {
    this.loader.start();
    this.couponserviceService.searchCouponsFunction(p,q,r,s).subscribe((res: any) => {
      this.couponFilter = res;
      this.loader.stop();
      if (this.isDtInitialized) {
        this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
          dtInstance.destroy();
          this.dtTrigger.next();
        });
      } else {
        this.isDtInitialized = true;
        this.dtTrigger.next();
      }
    }, err => {
      this.loader.stop();
      this.tosterService.error('Something wrong');
    });
  }
}

getImg(c){
  this.couponserviceService.getImage(c).subscribe(
    data=>{
      this.ipath=data; 
      console.log(this.ipath);

  },
    error => {
      this.ipath= "assets/images/img_24.jpg"; 
      console.log("cant print");
    }
  )

}
// get Coupon Details  by coupon id for update

getCouponByID(cId: any) {
  this.couponId = cId;
  this.getAllUCity();
  this.loader.start();
  this.couponserviceService.getCouponByID(cId).subscribe((res: any) => {
    console.log("res : "+res);
    this.coupon_pic1 = res.coupon_pic;
    
    this.updateForm.patchValue({
      coupon_id: res.coupon_id,

   
      city: res.city,
      city_name: res.city_name,
      area: res.area,
      area_name: res.area_name,
     
      visibility: res.visibility,
     
      vendor: res.vendor,
      coupon_desc: res.coupon_desc,
      coupon_code: res.coupon_code,
      coupon_minimum_amount: res.coupon_minimum_amount,
      online_or_instore: res.online_or_instore,
     
      terms_and_conditions: res.terms_and_conditions,
      disclaimer: res.disclaimer
      
    });
    this.loader.stop();
  }, err => {
    this.loader.stop();
    this.tosterService.error(err.message);
  });
}
//partial updates for upadate coupon
partialUpdate(c,j){
  console.log(c);
  console.log(this.updateForm);
  console.log("c.category: "+c.category);
   this.updateForm.patchValue({
    city:c.city,
    
    area:c.area,
    
    coupon_code:c.coupon_code,
    coupon_desc:c.coupon_desc,
    coupon_id:c.coupon_id,
    coupon_pic:c.coupon_pic,
    vendor:c.vendor
    
    
   
   });
 }


 
deleteCouponbyCouponId(cid){
  this.loader.start();
  this.couponserviceService.deleteCouponFunction(cid).subscribe(
    data=>{
      this.d=data; 
      this.getAllCoupons();
      console.log(this.d);

  },
    error => {
      console.log("cant delete");
      this.printTerm=null;
      this.k=0;
    }
  )
  this.loader.stop();
  
}
datefilter(Date: any) {
  this.VarDate = Date;
  let conv_date = this.datepipe.transform(this.VarDate, 'dd-M-y');
  console.log(this.conv_date);
  return conv_date;
}

//show all coupons
getAllCoupons(){
  this.loader.start();
  this.couponserviceService.getAllCouponsFunction().subscribe((
    data:any)=>{
      this.couponFilter=data; 
      console.log("ALL COUPONS : "+ this.Citty);
      this.loader.stop();
      if (this.isDtInitialized) {
        this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
          dtInstance.destroy();
          this.dtTrigger.next();
        });
      } else {
        this.isDtInitialized = true;
        this.dtTrigger.next();
      }
    }, err => {
      this.loader.stop();
      this.tosterService.error('Something wrong');
    });
  }



  //End of go button
  activeCity(data: any) {
    const editdata = { city_id: data.city_id, active: 'Yes' };
    this.service.activeCity(editdata).subscribe((res: any) => {
      this.getCityByState(data.state_id);
    });
  }
  deActiveCity(data: any) {
    const editdata = { city_id: data.city_id, active: 'No' };
    this.service.activeCity(editdata).subscribe((res: any) => {
      this.getCityByState(data.state_id);
    });
  }
}

