import { Component, OnInit, OnDestroy,ViewChild  } from '@angular/core';
import { MerchantService } from 'src/app/services/merchant.service';
import { CityService } from 'src/app/services/city.service';
import { HttpClient } from '@angular/common/http';
import { DataTableDirective } from 'angular-datatables';
import { ToastrService } from 'ngx-toastr';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { Observable, Subject } from 'rxjs';
import { FormBuilder, FormControl,FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-merchant',
  templateUrl: './merchant.component.html',
  styleUrls: ['./merchant.component.css']
})
export class MerchantComponent implements OnInit {
  imageDirectoryPath:any="http://dev01.ohhpro.com:9080/uploads/local_mart/local_mart_merchant/";
  
  City: any;
  ShopCategory: any;
  dtOptions: DataTables.Settings = {};
  dtTrigger: Subject<any> = new Subject();
  dtElement: DataTableDirective;
  isDtInitialized: boolean = false;
  sh:number;
  myimage:Observable<any>;
  MerchantForm: FormGroup;
  city: any[];
  mer: any[];
  Shop: any[];
  shop:any;
  ph:any;
  Shop1: any;
  s: any[];
  sl: any[];
  shopList: any[];
  imageSrc: string = '';

  ngOnDestroy(): void {
    this.dtTrigger.unsubscribe();
  }

  constructor(private http:HttpClient,
    private cityService: CityService,
    private merchantService:MerchantService,
      private tosterService: ToastrService,
      private loader: NgxUiLoaderService,
      private fb: FormBuilder) { 

        this.MerchantForm=this.fb.group({
          name: ["", Validators.required],
          photo: ["",Validators.required],
          phone: ["", Validators.required], 
          email: ["", Validators.required],  
          password: ["", Validators.required],       
          shop_id: [, Validators.required],
          woner_id: [0,Validators.required],
          woner_type: ["owner",Validators.required],
        });
        this.loader.start();
        this.cityService.getAllActiveCity().subscribe(res=>{          
          this.City=res;this.loader.stop();
          console.log("city=",this.City)
        });
        this.loader.start();
        this.merchantService.getShopCategoryData().subscribe(data=>{
          console.log("category=",data);
          this.ShopCategory=data;this.loader.stop();
        });
        
  }

  ngOnInit() {
    this.dtOptions = {
      pagingType: 'full_numbers',
      pageLength: 10,
      processing: true,
      stateSave: true
    }; 
    this.getAllActiveCity();
    this.getShopCategoryData();
  }

  getAllActiveCity() {
    this.cityService.getAllActiveCity().subscribe(res => {
      this.city = res;
    });
  }
  getShopCategoryData() {
    this.merchantService.getMerchantShopCategory().subscribe(res=>{this.ShopCategory=res;});
  }
  merchant(shopid:number){
    this.loader.start();
    console.log("shop id =",shopid);
    this.merchantService.getMerchant(shopid).subscribe(data=>{this.s=data;console.log(data);this.loader.stop();}, err => {
      this.loader.stop();
      this.tosterService.error('Unable to get merchant');
     });
  }
  ShopList(ct:number,category:number){
    if ( ct<0) {
      alert('Select City First');
    }
      else if(category<0){alert('Select Category');
                         }      
      else {
               this.loader.start();
               this.merchantService.getShopList(category,ct).subscribe(data=>{
               this.shopList=data;
               this.loader.stop();
                }, err => {
                            this.loader.stop();
                            this.tosterService.error('Unable to get Shop');
                           });
            }
    
  }
  chkemail(phone,value){
    this.merchantService.checkEmail(phone,value).subscribe(data=>{
      this.ph=data;
      console.log(data);
    });
  }
 
  submit(formdata: any) {    
    this.loader.start();
    this.merchantService.adminAddMerchant(formdata).subscribe((res:any)=>{
      console.log("after post==",res);
      this.loader.stop();
      this.tosterService.success(res.message);
    },
      error => {
        this.loader.stop();
        this.tosterService.error('Unable to get Shop');
    });
  
  }


  


  handleInputChange(e) {
    var file = e.dataTransfer ? e.dataTransfer.files[0] : e.target.files[0];
    var pattern = /image-*/;
    var reader = new FileReader();
    if (!file.type.match(pattern)) {
      alert('invalid format');
      return;
    }
    reader.onload = this._handleReaderLoaded.bind(this);
    reader.readAsDataURL(file);
  }
  _handleReaderLoaded(e) {
    let reader = e.target;
    this.imageSrc = reader.result;
    console.log(this.imageSrc)
  }


}
