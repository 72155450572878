import { Mask } from './../../models/mask';
import { environment } from './../../../environments/environment.prod';
import { JobberService } from './../../services/jobber.service';
import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { Subject, empty, Observable, forkJoin } from 'rxjs';
import { DataTableDirective } from 'angular-datatables';
import { FormGroup, FormBuilder, FormArray, AbstractControl, Validators, FormControl } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { BsDatepickerConfig } from 'ngx-bootstrap/datepicker';
import { AreaService } from './../../services/area.service';
import { CityService } from './../../services/city.service';
import { StateService } from './../../services/state.service';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { resetFakeAsyncZone } from '@angular/core/testing';

declare var jQuery: any;
@Component({
  selector: 'app-jobber',
  templateUrl: './jobber.component.html',
  styleUrls: ['./jobber.component.css']
})
export class JobberComponent implements OnInit, OnDestroy {
  jobberForm: FormGroup;
  otpForm: FormGroup;
  jobberID: number = null;
  @ViewChild(DataTableDirective, { static: false })
  dtElement: DataTableDirective;
  // tslint:disable-next-line: no-inferrable-types
  isDtInitialized: boolean = false;
  // filter
  StateFilter: any[];
  CityFilter: any[];
  AreaFilter: any[];
  Skill: any[];

  maskAccount = new Mask();
  userPicUrl = environment.userPicUrl;
  userDocUrl = environment.userDocUrl;
  Jobber: any[];
  emailVerified: any;
  phoneVerified: any;
  isApproved: any;
  // document
  docLink: any;
  drivingLicence: any;
  medicalReport: any;
  policeDoc: any;
  // update document
  Driving: File = null;
  Medical: File = null;
  Document: File = null;
  // otp related
  editEmail: any;
  editPhone: any;
  otpStatus = false;
  eEOTP = true;
  ePOTP = true;
  phoneOtp: number;
  mailOtp: number;
  timeLeft = 120;
  interval: any;

  dtOptions: DataTables.Settings = {};
  dtTrigger: Subject<any> = new Subject();
  datePickerConfig: Partial<BsDatepickerConfig>;
  ngOnDestroy(): void {
    this.dtTrigger.unsubscribe();
  }
  constructor(
    private tosterService: ToastrService,
    private loader: NgxUiLoaderService,
    private service: JobberService,
    private stateService: StateService,
    private cityService: CityService,
    private areaService: AreaService,
    private fb: FormBuilder,
  ) {
    this.datePickerConfig = Object.assign({},
      {
        containerClass: 'theme-dark-blue',
        showWeekNumbers: false,
        dateInputFormat: 'DD-MM-YYYY',
      });
    this.jobberForm = this.fb.group({
      jobber_id: [''],
      fname: [''],
      mname: [''],
      lname: [''],
      dob: [''],
      street: [''],
      house_no: [''],
      country: [''],
      country_name: [''],
      state: [''],
      state_name: [''],
      city: [''],
      city_name: [''],
      area: [''],
      area_name: [''],
      pin: [''],
      email: ['', [Validators.email, Validators.pattern(/^[a-z]+[a-z0-9._]+@[a-z]+\.[a-z.]{2,5}$/),
      Validators.required], this.chkEditEmail.bind(this)],
      email_verified: [''],
      phone: ['', [Validators.required, Validators.pattern('^[0-9]*$'), Validators.minLength(10),
      Validators.maxLength(10)], this.chkEditPhone.bind(this)],
      phone_verified: [''],
      jobber_approved_doc_type: [''],
      jobber_approved_doc_number: [''],
      jobber_approved_doc_image: [''],
      jobber_is_approved_police_verification: [''],
      jobber_is_approved_medical_check: [''],
      last_background_check_date: [''],
      bank_account_check: [''],
      bank_name: [''],
      branch: [''],
      bank_account_name: [''],
      ac_no: [''],
      ifsc_code: [''],
      created_datetime: [''],
      lastupdate_datetime: [''],
      jobber_driver_license_no: [''],
      is_approved: [''],
      razorpay_account_id: [''],
      razorpay_destination_account: [''],
      razorpay_account_creation_date: ['']
    });
    this.otpForm = this.fb.group({
      otpPhone: [''],
      otpEmail: ['']
    });
  }

  ngOnInit() {
    this.dtOptions = {
      pagingType: 'full_numbers',
      pageLength: 5,
      processing: true,
      stateSave: true
    };
    this.allJobber();
    this.getAllActiveState();
    this.getAllSkill();
  }
  startTimer() {
    this.interval = setInterval(() => {
      if (this.timeLeft > 0) {
        this.timeLeft--;
      } else {
        this.timeLeft = 0;
      }
    }, 1000);
  }
  onSubmit(formdata: any, date: any) {
    // formdata.last_background_check_date = date;
    if (formdata.active === true) {
      formdata.active = 'Yes';
    } else if (formdata.active === false) {
      formdata.active = 'No';
    }
    if (this.editEmail !== formdata['email'] || this.editPhone !== formdata['phone']) {
      console.log('otp sending start');
      clearInterval(this.interval);
      this.loader.start();
      if (this.editEmail !== formdata['email']) {
        console.log('otp sending to email');
        this.service.sendEmailOTP(formdata['email']).subscribe((res: any) => {
          if (res.status) {
            console.log(res);
            this.loader.stop();
            this.mailOtp = res['otp'];
            this.eEOTP = false;
            this.tosterService.success('An OTP sent to email !!');
          }
        }, err => {
          this.loader.stop();
          this.tosterService.error(err.message);
        });
      }
      if (this.editPhone !== formdata['phone']) {
        console.log('otp sending to phone');
        this.loader.start();
        this.service.sendPhoneOTP(formdata['phone']).subscribe((res: any) => {
          if (res.status) {
            console.log(res);
            this.loader.stop();
            this.phoneOtp = res['otp'];
            this.ePOTP = false;
            this.tosterService.success('An OTP sent to phone !!');
          }
        }, err => {
          this.loader.stop();
          this.tosterService.error(err.message);
        });
      }
      setTimeout(() => {
        this.otpStatus = true;
        this.startTimer();
      }, 2000);
    } else {
      console.log(formdata);
      this.loader.start();
      this.service.updateJobber(formdata).subscribe((res: any) => {
        if (res.status === true) {
          this.loader.stop();
          this.tosterService.success(res.message);
          this.allJobber();
          // tslint:disable-next-line:quotemark
          jQuery(".bd-example-modal-lg").modal("hide");
        }
      },
        error => {
          this.loader.stop();
          this.tosterService.error(error.statusText);
        });
    }
  }
  updateJober(emailOtp: number, phoneOtp: number, formdata: any) {
    if (this.editEmail !== formdata['email'] && this.editPhone !== formdata['phone']) {
      if (this.mailOtp === emailOtp && this.phoneOtp === phoneOtp) {
        console.log(formdata);
        this.loader.start();
        this.service.updateJobber(formdata).subscribe((res: any) => {
          if (res.status === true) {
            this.loader.stop();
            this.tosterService.success(res.message);
            this.allJobber();
            jQuery('.bd-example-modal-lg').modal('hide');
            this.otpStatus = false;
            this.eEOTP = true;
            this.ePOTP = true;
            this.otpForm.reset();
          }
        },
          error => {
            this.loader.stop();
            this.tosterService.error(error.statusText);
          });
      } else {
        console.log(this.mailOtp + '/' + this.phoneOtp);
        this.tosterService.error('Invalid OTP !!');
      }
    } else if (this.editEmail === formdata['email'] && this.editPhone !== formdata['phone']) {
      if (this.phoneOtp === phoneOtp) {
        console.log(formdata);
        this.loader.start();
        this.service.updateJobber(formdata).subscribe((res: any) => {
          if (res.status === true) {
            this.loader.stop();
            this.tosterService.success(res.message);
            this.allJobber();
            jQuery('.bd-example-modal-lg').modal('hide');
            this.otpStatus = false;
            this.eEOTP = true;
            this.ePOTP = true;
            this.otpForm.reset();
          }
        },
          error => {
            this.loader.stop();
            this.tosterService.error(error.statusText);
          });
      } else {
        console.log(this.mailOtp + '/' + this.phoneOtp);
        this.tosterService.error('Invalid OTP !!');
      }
    } else if (this.editEmail !== formdata['email'] && this.editPhone === formdata['phone']) {
      if (this.mailOtp === emailOtp) {
        console.log(formdata);
        this.loader.start();
        this.service.updateJobber(formdata).subscribe((res: any) => {
          if (res.status === true) {
            this.loader.stop();
            this.tosterService.success(res.message);
            this.allJobber();
            jQuery('.bd-example-modal-lg').modal('hide');
            this.otpStatus = false;
            this.eEOTP = true;
            this.ePOTP = true;
            this.otpForm.reset();
          }
        },
          error => {
            this.loader.stop();
            this.tosterService.error(error.statusText);
          });
      } else {
        console.log(this.mailOtp + '/' + this.phoneOtp);
        this.tosterService.error('Invalid OTP !!');
      }
    }
  }
  resendOTP(formdata: any) {
    console.log('otp sending start');
    clearInterval(this.interval);
    this.loader.start();
    if (this.editEmail !== formdata['email']) {
      console.log('otp sending to email');
      this.service.sendEmailOTP(formdata['email']).subscribe((res: any) => {
        if (res.status) {
          console.log(res);
          this.loader.stop();
          this.mailOtp = res['otp'];
          this.eEOTP = false;
          this.tosterService.success('An OTP sent to email !!');
        }
      }, err => {
        this.loader.stop();
        this.tosterService.error(err.message);
      });
    }
    if (this.editPhone !== formdata['phone']) {
      console.log('otp sending to phone');
      this.loader.start();
      this.service.sendPhoneOTP(formdata['phone']).subscribe((res: any) => {
        if (res.status) {
          console.log(res);
          this.loader.stop();
          this.phoneOtp = res['otp'];
          this.ePOTP = false;
          this.tosterService.success('An OTP sent to phone !!');
        }
      }, err => {
        this.loader.stop();
        this.tosterService.error(err.message);
      });
    }
    setTimeout(() => {
      clearInterval(this.interval);
      this.otpStatus = true;
      this.timeLeft = 120;
      this.startTimer();
    }, 2000);
  }
  allJobber() {
    this.loader.start();
    this.service.getJobbers().subscribe((res: any) => {
      this.Jobber = res;
      console.log(res);
      if (this.isDtInitialized) {
        this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
          dtInstance.destroy();
          this.dtTrigger.next();
        });
      } else {
        this.isDtInitialized = true;
        this.dtTrigger.next();
      }
      this.loader.stop();
    }, err => {
      this.loader.stop();
      this.tosterService.error(err.message);
    });
  }
  getJobberByID(jID: any) {
    this.jobberID = jID;
    this.timeLeft = 120;
    clearInterval(this.interval);
    this.otpStatus = false;
    this.eEOTP = true;
    this.ePOTP = true;
    this.otpForm.reset();
    this.loader.start();
    this.service.getJobberById(jID).subscribe((res: any) => {
      console.log(res);
      this.docLink = res.jobber_approved_doc_image;
      this.drivingLicence = res.jobber_driver_license_image;
      this.medicalReport = res.jobber_approved_medical_report_image;
      console.log("medi"+this.medicalReport);
      this.policeDoc = res.police_verification_doc_image;
      console.log("pol"+this.policeDoc);

      this.emailVerified = res.email_verified;
      this.phoneVerified = res.phone_verified;
      this.isApproved = res.is_approved;
      this.editEmail = res.email;
      this.editPhone = res.phone;
      this.jobberForm.patchValue({
        jobber_id: res.jobber_id,
        fname: res.fname,
        mname: res.mname,
        lname: res.lname,
        dob: res.dob,
        email: res.email,
        email_verified: res.email_verified,
        phone: res.phone,
        phone_verified: res.phone_verified,
        area: res.area,
        area_name: res.area_name,
        city: res.city,
        city_name: res.city_name,
        state: res.state,
        state_name: res.state_name,
        country: res.country,
        country_name: res.country_name,
        pin: res.pin,
        house_no: res.house_no,
        street: res.street,
        jobber_approved_doc_type: res.jobber_approved_doc_type,
        jobber_approved_doc_number: res.jobber_approved_doc_number,
        jobber_approved_doc_image: res.jobber_approved_doc_image,
        jobber_is_approved_police_verification: res.jobber_is_approved_police_verification === null ? '' :
          res.jobber_is_approved_police_verification,
        jobber_is_approved_medical_check: res.jobber_is_approved_medical_check === null ? '' : res.jobber_is_approved_medical_check,
        last_background_check_date: res.last_background_check_date,
        bank_account_check: res.bank_account_check === null ? '' : res.bank_account_check,
        bank_name: res.bank_name,
        bank_account_name: res.bank_account_name,
        branch: res.branch,
        ac_no: res.ac_no,
        ifsc_code: res.ifsc_code,
        created_datetime: res.created_datetime,
        lastupdate_datetime: res.lastupdate_datetime,
        jobber_driver_license_no: res.jobber_driver_license_no,
        is_approved: res.is_approved,
        razorpay_account_id: res.razorpay_account_id,
        razorpay_destination_account: res.razorpay_destination_account,
        razorpay_account_creation_date: res.razorpay_account_creation_date
      });
      this.loader.stop();
    }, err => {
      this.loader.stop();
      this.tosterService.error(err.message);
    });
  }
  activeJobber(data: any) {
    if (data.jobber_is_approved_police_verification === 'Yes' && data.jobber_is_approved_medical_check === 'Yes'
      && data.bank_account_check === 'Yes') {
      const editdata = { jobber_id: data.jobber_id, is_approved: 'Yes' };
      this.service.activeJobber(editdata).subscribe((res: any) => {
        this.allJobber();
      }, err => {
        this.tosterService.error(err.message);
      });
    } else {
      this.tosterService.info('Kindly complete all verification');
    }
  }
  deActiveJobber(data: any) {
    const editdata = { jobber_id: data.jobber_id, is_approved: 'No' };
    this.service.activeJobber(editdata).subscribe((res: any) => {
      this.allJobber();
    });
  }

  // jobber filteration
  getAllActiveState() {
    this.stateService.getActiveState().subscribe(res => {
      this.StateFilter = res;
    }, err => {
      this.tosterService.error(err.message);
    });
  }
  getFactiveCity(s_id) {
    this.AreaFilter = null;
    this.loader.start();
    this.cityService.getActiveCityById(s_id).subscribe(res => {
      this.loader.stop();
      this.CityFilter = res;
    }, err => {
      this.loader.stop();
      this.tosterService.error(err.message);
    });
  }
  getFactiveArea(c_id) {
    this.loader.start();
    this.areaService.getActiveAreaByID(c_id).subscribe(res => {
      this.AreaFilter = res;
      this.loader.stop();
    }, err => {
      this.loader.stop();
      this.tosterService.error(err.message);
    });
  }
  getAllSkill() {
    this.service.getAllSkill().subscribe(res => {
      this.Skill = res;
    }, err => {
      this.tosterService.error(err.message);
    });
  }
  // filter start
  getFirstFilter(state, city, area) {
    if (state !== '' && city !== '' && area !== '') {
      this.loader.start();
      this.service.getFirstFilter(state, city, area).subscribe(res => {
        this.Jobber = res;
        if (this.isDtInitialized) {
          this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
            dtInstance.destroy();
            this.dtTrigger.next();
          });
        } else {
          this.isDtInitialized = true;
          this.dtTrigger.next();
        }
        this.loader.stop();
      }, err => {
        this.loader.stop();
        this.tosterService.error(err.message);
      });
    } else {
      this.tosterService.info('choose all fields');
    }
  }
  getSecondFilter(state, city, area, skill) {
    if (state !== '' && city !== '' && area !== '' && skill !== '') {
      this.loader.start();
      this.service.getSecondFilter(state, city, area, skill).subscribe(res => {
        this.Jobber = res;
        if (this.isDtInitialized) {
          this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
            dtInstance.destroy();
            this.dtTrigger.next();
          });
        } else {
          this.isDtInitialized = true;
          this.dtTrigger.next();
        }
        this.loader.stop();
      }, err => {
        this.loader.stop();
        this.tosterService.error(err.message);
      });
    } else {
      this.tosterService.info('Choose all fields');
    }
  }
  getThirdFilter(state, city, area, is_active) {
    if (state !== '' && city !== '' && area !== '' && is_active !== '') {
      this.loader.start();
      this.service.getThirdFilter(state, city, area, is_active).subscribe(res => {
        this.Jobber = res;
        if (this.isDtInitialized) {
          this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
            dtInstance.destroy();
            this.dtTrigger.next();
          });
        } else {
          this.isDtInitialized = true;
          this.dtTrigger.next();
        }
        this.loader.stop();
      }, err => {
        this.loader.stop();
        this.tosterService.error(err.message);
      });
    } else {
      this.tosterService.info('Choose all fields');
    }
  }
  getFourthFilter(state, city, area, skill, is_active, medical) {
    if (state !== '' && city !== '' && area !== '' && skill !== '' && is_active !== '' && medical !== '') {
      this.loader.start();
      this.service.getFourthFilter(state, city, area, skill, is_active, medical).subscribe(res => {
        this.Jobber = res;
        if (this.isDtInitialized) {
          this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
            dtInstance.destroy();
            this.dtTrigger.next();
          });
        } else {
          this.isDtInitialized = true;
          this.dtTrigger.next();
        }
        this.loader.stop();
      }, err => {
        this.loader.stop();
        this.tosterService.error(err.message);
      });
    } else {
      this.tosterService.info('Choose all fields');
    }
  }
  getFiveFilter(state, city, area, skill, is_active, police) {
    if (state !== '' && city !== '' && area !== '' && skill !== '' && is_active !== '' && police !== '') {
      this.loader.start();
      this.service.getFiveFilter(state, city, area, skill, is_active, police).subscribe(res => {
        this.Jobber = res;
        if (this.isDtInitialized) {
          this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
            dtInstance.destroy();
            this.dtTrigger.next();
          });
        } else {
          this.isDtInitialized = true;
          this.dtTrigger.next();
        }
        this.loader.stop();
      }, err => {
        this.loader.stop();
        this.tosterService.error(err.message);
      });
    } else {
      this.tosterService.info('Choose all fields');
    }
  }
  getSixFilter(state, city, area, skill, is_active, bank) {
    if (state !== '' && city !== '' && area !== '' && skill !== '' && is_active !== '' && bank !== '') {
      this.loader.start();
      this.service.getSixFilter(state, city, area, skill, is_active, bank).subscribe(res => {
        this.Jobber = res;
        if (this.isDtInitialized) {
          this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
            dtInstance.destroy();
            this.dtTrigger.next();
          });
        } else {
          this.isDtInitialized = true;
          this.dtTrigger.next();
        }
        this.loader.stop();
      }, err => {
        this.loader.stop();
        this.tosterService.error(err.message);
      });
    } else {
      this.tosterService.info('Choose all fields');
    }
  }
  getSevenFilter(state, city, area, skill, is_active, medical, police, bank) {
    if (state !== '' && city !== '' && area !== '' && skill !== '' && is_active !== '' && medical !== '' && police !== '' && bank !== '') {
      this.loader.start();
      this.service.getSevenFilter(state, city, area, skill, is_active, medical, police, bank).subscribe(res => {
        this.Jobber = res;
        if (this.isDtInitialized) {
          this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
            dtInstance.destroy();
            this.dtTrigger.next();
          });
        } else {
          this.isDtInitialized = true;
          this.dtTrigger.next();
        }
        this.loader.stop();
      }, err => {
        this.loader.stop();
        this.tosterService.error(err.message);
      });
    } else {
      this.tosterService.info('Choose all fields');
    }
  }
  onFileSelectDoc(evt: { target: { files: any[]; }; }) {
    this.Document = evt.target.files[0];
    var reader = new FileReader();
    reader.onload = (function(theFile) {
      return function(e) {
        var binaryData:any = e.target.result;
        var imgString:any = window.btoa(binaryData) ;
        console.log("my result1====",imgString);
        document.getElementById('topic2').innerHTML =imgString;
        
      };
    })(this.Document);
    reader.readAsBinaryString(this.Document);
  }
  onFileSelectMD(evt: { target: { files: any[]; }; }) {
    this.Medical = evt.target.files[0];
    var reader = new FileReader();
    reader.onload = (function(theFile) {
      return function(e) {
        var binaryData:any = e.target.result;
        var imgString:any = window.btoa(binaryData) ;
        console.log("my result1====",imgString);
        document.getElementById('topic3').innerHTML =imgString;
      };
    })(this.Medical);
    reader.readAsBinaryString(this.Medical);
  }
  onFileSelectDl(evt: { target: { files: any[]; }; }) {
    this.Driving = evt.target.files[0];
    var reader = new FileReader();
    reader.onload = (function(theFile) {
      return function(e) {
        var binaryData:any = e.target.result;
        var imgString:any = window.btoa(binaryData) ;
        console.log("my result1====",imgString);
        document.getElementById('topic4').innerHTML =imgString;
        
      };
    })(this.Driving);
    reader.readAsBinaryString(this.Driving);
  }
  // image upload section
  getJobberbyRow(jID: number) {
    this.jobberID = jID;
  }

  onDLSelect(file: any) {
    this.Driving = file.target.files[0];
  }
  onMCSelect(file: any) {
    this.Medical = file.target.files[0];
  }
  onDocumentSelect(file: any) {
    this.Document = file.target.files[0];
  }
  uploadLicence(jobberID: number) {
    console.log('jobber id => ' + jobberID);

     var dart = new addJobberDocs(jobberID,document.getElementById('topic4').innerText);

    if (this.Driving != null) {
      this.loader.start();
      this.service.uploadIdDrivingLicence(dart).subscribe((res: any) => {
        this.loader.stop();
        if (res.status) {
          this.tosterService.success(res.message);
          this.allJobber();
          jQuery("#drivingModal").modal("hide");
        }
      }, err => {
        this.loader.stop();
        this.tosterService.error(err.message);
      });
    } else {
      this.tosterService.info('Choose any document');
    }
  }
  uploadReport(jobberID: number) {
    console.log('jobber id => ' + jobberID);
    if (this.Medical != null) {
      var dart = new addJobberDocs(jobberID,document.getElementById('topic3').innerText);
      this.loader.start();
      this.service.uploadIdMedicalReport(dart).subscribe((res: any) => {
        this.loader.stop();
        if (res.status) {
          this.tosterService.success(res.message);
          this.allJobber();
          jQuery("#medicalModal").modal("hide");
        }
      }, err => {
        this.loader.stop();
        this.tosterService.error(err.message);
      });
    } else {
      this.tosterService.info('Choose any document');
    }
  }
  uploadDocument(jobberID: number) {
    console.log('jobber id => ' + jobberID);
    if (this.Document != null) {
      var dart = new addJobberDocs(jobberID,document.getElementById('topic2').innerText);
      this.loader.start();
      this.service.uploadIdApprovedDoc(dart).subscribe((res: any) => {
        this.loader.stop();
        if (res.status) {
          this.tosterService.success(res.message);
          this.allJobber();
          jQuery("#docModal").modal("hide");
        }
      }, err => {
        this.loader.stop();
        this.tosterService.error(err.message);
      });
    } else {
      this.tosterService.info('Choose any document');
    }
  }
  openAccount(jobber_id: number) {
    this.loader.start();
    this.service.openAccount(jobber_id).subscribe((res: any) => {
      if (res.status) {
        this.allJobber();
        this.loader.stop();
        this.tosterService.success(res.status);
      } else {
        this.loader.stop();
        this.tosterService.success('Something wrong !!');
      }
    }, err => {
      this.loader.stop();
      this.tosterService.error('Account opening failure !!');
    });
  }

  // jobber edit email & phone duplicate check
  chkEditEmail(control: FormControl): Promise<any> | Observable<any> {
    const promise = new Promise((resolve, reject) => {
      let data = {
        field: 'email',
        jobber_id: this.jobberID,
        value: control.value
      };
      console.log(data);
      this.service.checkDuplicate(data).subscribe(
        resData => {
          console.log(resData);
          if (resData['status']) {
            resolve(null);
          } else {
            resolve({ textIsForbidden: true });
          }
        },
        error => console.log(error)
      );
    });
    return promise;
  }

  chkEditPhone(control: FormControl): Promise<any> | Observable<any> {
    const promise = new Promise((resolve, reject) => {
      let data = {
        field: 'phone',
        jobber_id: this.jobberID,
        value: control.value
      };
      console.log(data);
      this.service.checkDuplicate(data).subscribe(
        resData => {
          console.log(resData);
          if (resData['status']) {
            resolve(null);
          } else {
            resolve({ textIsForbidden: true });
          }
        },
        error => console.log(error)
      );
    });
    return promise;
  }

  editEmailErrorMessage() {
    return this.jobberForm.get('email').hasError('required') && this.jobberForm.get('email').touched ? 'You must enter a email' :
      this.jobberForm.get('email').hasError('email') ? 'You must enter a valid email' :
        this.jobberForm.get('email').hasError('pattern') ? 'You must enter a valid email' :
          this.jobberForm.get('email').hasError('textIsForbidden') ? 'Email already exist!' : '';
  }

  editPhoneErrorMessage() {
    return this.jobberForm.get('phone').hasError('required') && this.jobberForm.get('phone').touched ? 'You must enter phone' :
      this.jobberForm.get('phone').hasError('minlength') ? 'Minimum length is 10!' :
        this.jobberForm.get('phone').hasError('maxlength') ? 'Maximum length is 10!' :
          this.jobberForm.get('phone').hasError('pattern') ? 'Enter Valid no!' :
            this.jobberForm.get('phone').hasError('textIsForbidden') ? 'Number already exist!' : '';
  }
}


export class addJobberDocs{
  jobber_id:Number;
  jobber_approved_doc_image:String;
  constructor(jobber_id:Number,jobber_approved_doc_image:String){
    this.jobber_id=jobber_id;
    this.jobber_approved_doc_image=jobber_approved_doc_image;
  }
}