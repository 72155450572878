import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { BehaviorSubject, forkJoin, of, Observable, throwError } from 'rxjs';
import { environment } from './../../environments/environment';
import { tap, catchError } from 'rxjs/operators';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { ToastrService } from 'ngx-toastr';

@Injectable({
  providedIn: 'root'
})
export class DashboardService {
  httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' })
  };
  constructor(
    private http: HttpClient,
    private loader: NgxUiLoaderService,
  ) { }
  getDashboard() {
    const url = 'dashboard/showDashboard';
    return  this.http.get<any>(environment.baseUrl + url);
  }
  filterDashboard(state, city, area) {
    const url = 'dashboard/filterDashboard/';
    return this.http.get<any>(environment.baseUrl + url + state + '/' + city + '/' + area);
  }
  getDashStas(){
    const url = 'dashboard/getDashboardForVisitor';
    return this.http.get<any>(environment.baseUrl + url);
  }
  getSignallerDetails(appid:any){
    return this.http.get<any>(environment.newUrl+"getSignallers/"+appid);
  }
  getFacilityManager(appid:any){
    return this.http.get<any>(environment.newUrl+"getMManagementList/"+appid);
  }
  getAdminList(appid:any){
    return this.http.get<any>(environment.newUrl+"getAdminList/"+appid);
  }
  getMonthDetails(appid){
    const url = 'dashboard/getDashboardDetailsForVisitor/';
    return this.http.get<any>(environment.baseUrl + url +appid);
  }
  getMonthDetailsForInvoice(appid){
    const url = 'dashboard/getDashboardForMiscellaneous/';
    return this.http.get<any>(environment.baseUrl + url +appid);
  }

  updateApartmentInfo(data:any){
   const url = '/payment/ApartmentPayment';
   return this.http.post<any>(environment.schedularBaseUrl + "payment/ApartmentPayment",data);
  }

  getJobberRequest(){
    return this.http.get<any>(environment.baseUrl1+"jobberrequest/getAllJobberRequest");
  }

  getApiFailureTrace(){
    return this.http.get<any>(environment.baseUrl1+"apifailuretrace/Get/ApiFailureTrace");
  }

  getAdsTrackLog(){
    return this.http.get<any>(environment.baseUrl1+"advertisement/getEnquiryLogs");
  }

}
