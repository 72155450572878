import { City } from './../models/city';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { environment } from './../../environments/environment';
import { catchError} from 'rxjs/operators';
@Injectable({
  providedIn: 'root'
})
export class CityService {
  getAreaById(Sid: any) {
    //location/activeArea
    const url = 'location/activeArea/';
    return this.http.get<any[]>(environment.baseUrl + '' + url + Sid);
  }
  serverUrl = environment.baseUrl;
  httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json'})
  };

  constructor(private http: HttpClient) { }
  getCityById(Sid: any) {
    const url = 'location/allCity/';
    return this.http.get<any[]>(environment.baseUrl + '' + url + Sid);
    console.log("get city Url : "+environment.baseUrl + '' + url + Sid);
  }
  addCity(data: City) {
    const url = 'location/addcities';
    console.log(data);
    return this.http.post<City>(environment.baseUrl + '' + url, data, this.httpOptions);
  }
  activeCity(data: any) {
    const url = 'location/updateCityActive/';
    return this.http.put(environment.baseUrl + '' + url + '' + data.city_id , data );
  }
  getAllCities() {
    const url = 'location/allCity';
    return this.http.get<any[]>(environment.baseUrl + '' + url);
  }
  getActiveCityById(aId: number) {
    const url = 'location/activeCity/';
    return this.http.get<any[]>(environment.baseUrl + '' + url + aId);
  }
  getAllActiveCity() {
    const url = 'location/activeCity';
    return this.http.get<any[]>(environment.baseUrl + '' + url);
  }
}
