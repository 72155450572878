export const environment = {
  production: false,

  // baseUrl: 'https://ohhpro.com:6080/ohhpro_admin/admin/',
  // careerUrl: 'https://ohhpro.com:6080/career/',
  // razorpayUrl: 'https://ohhpro.com:6080/ohhpro_razorpay/payment/razorPay/',
  // imgaeUrl: 'https://ohhpro.com:6080/uploads/app_src/',
  // buserPicUrl: 'https://ohhpro.com:6080/uploads/user_pics/',
  // userPicUrl: 'https://ohhpro.com:6080/uploads/user_pics/',

  
  assetUrl: 'https://ohhpro.com:8443/ohhpro/rest/asset',
  baseUrl: 'https://ohhpro.com:8443/ohhpro_admin/admin/',
  baseUrl1: 'https://ohhpro.com:8443/ohhpro/rest/',
  razorpayUrl: 'https://ohhpro.com:8443/ohhpro_razorpay/payment/razorPay/',
  imgaeUrl: 'https://ohhpro.com:8443/uploads/app_src/',
  adImgUrl:'https://ohhpro.com:8443/uploads/ohhpro_adv/',
  foodImgUrl:'https://ohhpro.com:8443/uploads/covid_food/',
  userPicUrl: 'https://ohhpro.com:8443/uploads/user_pics/',
  userDocUrl: 'https://ohhpro.com:8443/uploads/user_docs/',
   env:'prod',
  localMerchantUrl:'https://ohhpro.com:8443/localMerchant/rest/',
  RazorPay1:'https://ohhpro.com:8443/ohhpro_razorpay/payment/',
  newUrl:'https://ohhpro.com:8443/ohhpro_Junction/rest/subAdmin/',
  schedularBaseUrl:'https://ohhpro.com:8443/ohhpro_Junction/payment/admin/',
  advertisementImage:'https://ohhpro.com:8443/uploads/Advertisement/'


  // assetUrl: 'http://dev01.ohhpro.com:9080/ohhpro/rest/asset',
  // baseUrl: 'http://dev01.ohhpro.com:9080/ohhpro_admin/admin/',
  // baseUrl1: 'http://dev01.ohhpro.com:9080/ohhpro/rest/',
  // razorpayUrl: 'http://dev01.ohhpro.com:9080/ohhpro_razorpay/payment/razorPay/',
  // imgaeUrl: 'http://dev01.ohhpro.com:9080/uploads/app_src/',
  // foodImgUrl:'http://dev01.ohhpro.com:9080/uploads/covid_food/',
  // adImgUrl:'http://dev01.ohhpro.com:9080/uploads/ohhpro_adv/',
  // userPicUrl: 'http://dev01.ohhpro.com:9080/uploads/user_pics/',
  // userDocUrl: 'http://dev01.ohhpro.com:9080/uploads/user_docs/',
  // env:'dev',
  // localMerchantUrl:'http://dev01.ohhpro.com:9080/localMerchant/rest/',
  // RazorPay1:'http://dev01.ohhpro.com:9080/ohhpro_razorpay/payment/',
  // newUrl:'http://dev01.ohhpro.com:9080/ohhpro_Junction/rest/subAdmin/',
  // schedularBaseUrl:'http://dev01.ohhpro.com:9080/ohhpro_Junction/payment/admin/',
  // advertisementImage:'http://dev01.ohhpro.com:9080/uploads/Advertisement/'
};
