import { PaidBillComponent } from './paid-bill/paid-bill.component';
import { SkillComponent } from './skill/skill.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { SubscriptionComponent } from './subscription/subscription.component';
import { UserComponent } from './user/user.component';
import { JobberBillingComponent } from './jobber-billing/jobber-billing.component';
import { CustomerInvoiceComponent } from './customer-invoice/customer-invoice.component';
import { HouseComponent } from './house/house.component';
import { SubAdminComponent } from './sub-admin/sub-admin.component';
import { BookingComponent } from './booking/booking.component';
import { JobberComponent } from './jobber/jobber.component';
import { FloorComponent } from './floor/floor.component';
import { BlockComponent } from './block/block.component';
import { AreaComponent } from './area/area.component';
import { CityComponent } from './city/city.component';
import { StateComponent } from './state/state.component';
import { CountryComponent } from './country/country.component';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AdminComponent } from './admin/admin.component';
import { ApartmentBillingComponent } from './apartment-billing/apartment-billing.component';
import { NormalVisitorComponent } from './normal-visitor/normal-visitor.component';
import { RegularVisitorComponent } from './regular-visitor/regular-visitor.component';
import { ApartmentComponent } from './apartment/apartment.component';
import { DepartmentComponent } from './department/department.component';
import { subscribeOn } from 'rxjs/operators';
import { JobsComponent } from './jobs/jobs.component';
import { CouponsComponent } from './coupons/coupons.component';
import { MallComponent } from './mall/mall.component';
import { PrivateoComponent } from './privateo/privateo.component';
import { GovernmentoComponent } from './governmento/governmento.component';
import { AssetComponent } from './asset/asset.component';
import { NoticeComponent } from './notice/notice.component';
import { CovidComponent } from './covid/covid.component';
import { AdvertisementComponent } from './advertisement/advertisement.component';
import { ShopComponent } from './shop/shop.component';
import { MerchantComponent } from './merchant/merchant.component';
import { ProductComponent } from './product/product.component';
import { CategoryComponent } from './category/category.component';
import { SuperCategoryComponent } from './super-category/super-category.component';

const routes: Routes = [
  {
    path: 'admin',
    component: AdminComponent,
    children: [
      // { path: 'country', component: CountryComponent },
      { path: 'state', component: StateComponent },
      { path: 'notice', component: NoticeComponent },
      { path: 'covid', component: CovidComponent },
      { path: 'advertise', component: AdvertisementComponent },
      { path: 'city', component: CityComponent },
      { path: 'area', component: AreaComponent },
      { path: 'apartment', component: ApartmentComponent },
      { path: 'pOffice', component: PrivateoComponent },
      { path: 'gOffice', component: GovernmentoComponent },
      { path: 'block', component: BlockComponent },
      { path: 'floor', component: FloorComponent },
      { path: 'jobber', component: JobberComponent },
      { path: 'bookings', component: BookingComponent },
      { path: 'sub-admin', component: SubAdminComponent },
      { path: 'house', component: HouseComponent },
      { path: 'invoice', component: CustomerInvoiceComponent },
      { path: 'billing', component: JobberBillingComponent },
      { path: 'user', component: UserComponent },
      { path: 'apratment/bill', component: ApartmentBillingComponent },
      {path: 'normal/visitor', component: NormalVisitorComponent },
      {path: 'regular/visitor', component: RegularVisitorComponent},
      {path: 'department', component: DepartmentComponent},
      {path: 'jobs', component: JobsComponent},
      {path: 'subscription', component: SubscriptionComponent},
      {path: 'dashboard', component: DashboardComponent},
      {path: 'skill', component: SkillComponent},
      {path: 'coupon', component: CouponsComponent},
      {path: 'mall', component: MallComponent},
      {path: 'asset', component: AssetComponent},
      {path: 'paid/bill', component: PaidBillComponent},
      {path: 'shop', component: ShopComponent},
      {path: 'merchant', component: MerchantComponent},
      {path: 'product', component:ProductComponent},
      {path:'category',component:CategoryComponent},
      {path:'super-category', component:SuperCategoryComponent}
    ]
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class AdminRoutingModule { }
