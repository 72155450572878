import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { environment } from './../../environments/environment';
import { catchError} from 'rxjs/operators';
@Injectable({
  providedIn: 'root'
})
export class AssetServiceService {
  deleteAssetbyAssetId(cid: any) {
    const url = '/delete/asset/';
    return this.http.get(environment.assetUrl + '' + url +cid);
    
  }

//http://dev01.ohhpro.com/ohhpro/rest/asset/delete/asset/13
//http://dev01.ohhpro.com/ohhpro/rest/asset/editAsset

//edit asset 

editAssetIndivisually(formdata: any) {
  const url = '/editAsset';
  return this.http.post(environment.assetUrl + '' + url, formdata);
}

  
  httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json'})
  };
  constructor(private http: HttpClient) { }
  addAsset(formdata: any) {
    const url = '/add/addAsset';
    return this.http.post(environment.assetUrl + '' + url, formdata);
  }
  showAllAssetList(){
    const url = '/allAsset';
    return this.http.get(environment.assetUrl + '' + url);
  }


  
}
