import { Component, ElementRef, OnInit,ViewChild } from '@angular/core';
import { MerchantService } from 'src/app/services/merchant.service';
import { HttpClient } from '@angular/common/http';
import { Observable,Subject } from 'rxjs';
import { DataTableDirective } from 'angular-datatables';
import { FormGroup, FormBuilder, FormArray, AbstractControl, Validators,FormControl, NgForm } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import * as XLSX from 'xlsx';
@Component({
  selector: 'app-product',
  templateUrl: './product.component.html',
  styleUrls: ['./product.component.css']
})
export class ProductComponent implements OnInit {
  employee:Products={
    product_category_id : null,
    product_name : null,
    mrp_price :null,
    product_pic :null,
    perishable :null,   
  }
  single: Array<Products> = []; 
  allProducts: Array<Products> = [];  
  @ViewChild(DataTableDirective, { static: false })
    dtElement: DataTableDirective;
    isDtInitialized: boolean = false;
    dtOptions: DataTables.Settings = {};
    dtTrigger: Subject<any> = new Subject();
  cl: any[];
  product: any[];
  ProductForm: FormGroup;
  imageSrc: any;
  MerchantForm: FormGroup;
  ngOnDestroy(): void {
    this.dtTrigger.unsubscribe();
  }

  constructor(
    private http:HttpClient,
    private tosterService: ToastrService,
    private loader: NgxUiLoaderService,  
    private merchantService:MerchantService,
    private fb: FormBuilder
  ) {
    this.ProductForm=this.fb.group({
      product_category_id: [, Validators.required],
      product_name: ["", Validators.required], 
      product_pic: ["", Validators.required],
      mrp_price: [, Validators.required],  
      perishable: ["", Validators.required],
    });
    this.loader.start();
    this.merchantService.categoryList().subscribe(data=>{this.cl=data;console.log(this.cl);this.loader.stop();}, err => {
      this.loader.stop();
      this.tosterService.error('Unable to get category');
    });
   }

  ngOnInit() {
  }
  Product(product_category:any){   
    this.loader.start();
      this.merchantService.ProductList(product_category).subscribe((res: any) => {
        this.product = res;
        console.log(this.product);
        this.loader.stop();
        if (this.isDtInitialized) {
          this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
            dtInstance.destroy();
            this.dtTrigger.next();
          });
        } else {
          this.isDtInitialized = true;
          this.dtTrigger.next();
        }
      }, err => {
        this.loader.stop();
        this.tosterService.error('Unable to get Shop');
      });
  }


  
  submit(formdata: FormGroup) {
    this.loader.start();
    this.merchantService.AddProduct(new AddProductRequest(this.allProducts)).subscribe((res:any)=>{
      this.loader.stop();
      this.tosterService.success(res.message);          
    }, error => {
      this.loader.stop();
      this.tosterService.error("Unable to add product");
    });
  }
  handleInputChange(e) {
    var file = e.dataTransfer ? e.dataTransfer.files[0] : e.target.files[0];
    var pattern = /image-*/;
    var reader = new FileReader();
    if (!file.type.match(pattern)) {
      alert('invalid format');
      return;
    }
    reader.onload = this._handleReaderLoaded.bind(this);
    reader.readAsDataURL(file);
  }
  _handleReaderLoaded(e) {
    let reader = e.target;
    this.imageSrc = reader.result;
    console.log(this.imageSrc)
  }
  
  spinnerEnabled = false;
  keys: string[];
  dataSheet = new Subject();
  @ViewChild('inputFile',{static:true}) inputFile: ElementRef
  isExcelFile: boolean;

  onChange(evt) {
    let data, header;
    const target: DataTransfer = <DataTransfer>(evt.target);
    this.isExcelFile = !!target.files[0].name.match(/(.xls|.xlsx)/);
    if (target.files.length > 1) {
      this.inputFile.nativeElement.value = '';
    }
    if (this.isExcelFile) {
      this.spinnerEnabled = true;
      const reader: FileReader = new FileReader();
      reader.onload = (e: any) => {
        /* read workbook */
        const bstr: string = e.target.result;
        const wb: XLSX.WorkBook = XLSX.read(bstr, { type: 'binary' });

        /* grab first sheet */
        const wsname: string = wb.SheetNames[0];
        const ws: XLSX.WorkSheet = wb.Sheets[wsname];

        /* save data */
        data = XLSX.utils.sheet_to_json(ws);
      };

      reader.readAsBinaryString(target.files[0]);

      reader.onloadend = (e) => {
        this.spinnerEnabled = false;
        this.keys = Object.keys(data[0]);
        console.log(Object.keys(data[0]));
        this.dataSheet.next(data);
        this.allProducts=data;
        console.log(this.allProducts);
      }
    } else {
      this.inputFile.nativeElement.value = '';
    }
  }

  removeData() {
    console.log("hi");
    console.log(this.allProducts);
    console.log(this.allProducts[2]);
    console.log(this.allProducts[2].mrp_price);
  }
  try(newProducts:Products){
    console.log("hi",newProducts);
    this.allProducts[0]=newProducts;
    this.loader.start();
    this.merchantService.AddProduct(new AddProductRequest(this.allProducts)).subscribe((res:any)=>{
      this.loader.stop();
      this.tosterService.success(res.message);          
    }, error => {
      this.loader.stop();
      this.tosterService.error("Unable to add product");
    });
  }
}
export class Products {
  product_category_id : Number;
  product_name : String;
  mrp_price : Number;
  product_pic : String;
  perishable : String;
  constructor(prod_cat_num : Number, prod_name : String, mrp:Number, prod_pic : String, perisable : String){
    this.mrp_price= mrp;
    this.product_name=prod_name;
    this.product_category_id = prod_cat_num;
    this.product_pic = prod_pic;
    this.perishable = perisable
  }
}

export class AddProductRequest{
  products : Products[]
  constructor(products : Products[]){
    this.products = products
  }
}
