import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { BehaviorSubject, forkJoin, of, Observable, throwError } from 'rxjs';
import { environment } from './../../environments/environment';
import { tap, catchError } from 'rxjs/operators';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { ToastrService } from 'ngx-toastr';

@Injectable({
  providedIn: 'root'
})
export class AppBillingService {
  httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' })
  };
  constructor(
    private http: HttpClient,
    private loader: NgxUiLoaderService,
    private tosterService: ToastrService,
  ) { }

  getAppInvoice() {
    const url = 'admin/allappInv';
    this.loader.start();
    return this.http.get(environment.baseUrl + url).pipe(tap(res => {
      console.log(res);
      this.loader.stop();
    }));
  }
  getYearly() {
    const url = 'admin/allappInvYearly';
    this.loader.start();
    return this.http.get(environment.baseUrl + url).pipe(tap(res => {
      console.log(res);
      this.loader.stop();
    }, err => {
      this.loader.stop();
      this.tosterService.error(err.message);
    }));
  }
  getHalfYearly() {
    const url = 'admin/allappInvhalf';
    this.loader.start();
    return this.http.get(environment.baseUrl + url).pipe(tap(res => {
      console.log(res);
      this.loader.stop();
    }, err => {
      this.loader.stop();
      this.tosterService.error(err.message);
    }));
  }
  getQuarterly() {
    const url = 'admin/allappInvQuart';
    this.loader.start();
    return this.http.get(environment.baseUrl + url).pipe(tap(res => {
      console.log(res);
      this.loader.stop();
    }, err => {
      this.loader.stop();
      this.tosterService.error(err.message);
    }));
  }
  billFilter(data: any) {
    const url = 'admin/junctionInvFilter/';
    console.log(`${environment.baseUrl}${url}${data.state}/${data.city}/${data.area}/${data.type}/${data.subscription}/${data.status}`);
    // tslint:disable-next-line:max-line-length
    return this.http.get(`${environment.baseUrl}${url}${data.state}/${data.city}/${data.area}/${data.type}/${data.subscription}/${data.status}`);
  }
}
