import { environment } from './../../environments/environment.prod';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { tap } from 'rxjs/operators';
import { of, Subject } from 'rxjs';
@Injectable({
  providedIn: 'root'
})
export class AuthService {
  constructor(
    private http: HttpClient,
    private loader: NgxUiLoaderService
  ) { }
  loginService(data: any) {
    const url = 'lr/logIn/subadmin';
    return this.http.post(environment.baseUrl + url ,data);
    // http://dev01.ohhpro.com:9080/ohhpro_admin/admin/lr/logIn/subadmin
  }
  getAdminDetails() {
    this.loader.start();
    const Admin = sessionStorage.getItem('Admin');
    return of(JSON.parse(Admin)).pipe(tap(res => {
      this.loader.stop();
    }, err => {
      this.loader.stop();
    }));
  }
}
