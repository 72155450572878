import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { environment } from './../../environments/environment';
import { catchError} from 'rxjs/operators';
@Injectable({
  providedIn: 'root'
})
export class BlockService {
  httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json'})
  };
  constructor(private http: HttpClient) { }
  addBlock(data: any) {
    const url = 'appoff/addBlock';
    return this.http.post(environment.baseUrl + '' + url, data, this.httpOptions);
  }
  getBlockById(appID: any) {
    const url = 'appoff/block/';
    return this.http.get<any[]>(environment.baseUrl + '' + url + appID);
  }
  activeBlock(data: any) {
    const url = 'appoff/updateBlockActive/';
    return this.http.put(environment.baseUrl + '' + url + '' + data.blk_id , data);
  }
  getActiveBlockByAppOff(appId: number) {
    const url = 'appoff/activeBlockByAppId/';
    return this.http.get<any>(environment.baseUrl + url + appId);
  }
}
