import { Observable } from 'rxjs';
import { DashboardService } from './../../services/dashboard.service';
import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder, FormArray, AbstractControl, FormControl, Validators } from '@angular/forms';
import { AreaService } from './../../services/area.service';
import { CityService } from './../../services/city.service';
import { StateService } from './../../services/state.service';
import { ToastrService } from 'ngx-toastr';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { environment } from 'src/environments/environment.prod';


export interface DashCount {
  totalApartment: { type: string; count: number };
  activeApartment: { type: string; count: number };
  totalOffice: { type: string; count: number };
  activeOffice: { type: string; count: number };
  noOfJobber: { type: string; count: number };
  noOfJunctionBillPending: { type: string; count: number };
  totalJobberBillPending: { type: string; count: number };
  noOfBookingCreated: { type: string; count: number };
  totalServiceCustomer: { type: string; count: number };
  totalJunctionUsers: { type: string; count: number };
  noOfNewJobber: { type: string; count: number };
  approvedJob: { type: string; count: number };
  canceledJob: { type: string; count: number };
  declinedJob: { type: string; count: number };
  apartmentAdminActive: { type: string; count: number };
  officeAdminActive: { type: string; count: number };
}


@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent implements OnInit {

  TotalCount: DashCount;
  tc: Observable<any>;
  imageUrl = environment.userPicUrl;
  StateFilter: any[];
  CityFilter: any[];
  AreaFilter: any[];
  TotalStas: any;
  DashboardArray:Array<any>=[];
  DashboardArrayMonth:Array<any>=[];
  TotalMonthData: any;
  ApprtmentName: any;
  TotalMonthDataInvoice: any;
  SigDetails: any;
  FacManDetails: any;
  AdminDetails: any;
  AppID: any;
  AppName: any;
  PaymentDetails: any;
  JobberResponse: any;
  ApiFailureResponse: any;
  AdsLogs: any;
 

  constructor(
    private fb: FormBuilder,
    private loader: NgxUiLoaderService,
    private tosterService: ToastrService,
    private service: DashboardService,
    private stateService: StateService,
    private cityService: CityService,
    private areaService: AreaService
  ) { 
    



  }

  ngOnInit() {
    this.getAllCount();
    this.getAllActiveState();
    this.GetApartmentDashboardStas();
    this.getJobberRequest();
    this.getApiFailure();
    this.getAdsTrackLogs();
  }
  
  getJobberRequest(){

    this.loader.start();
    this.service.getJobberRequest().subscribe(res=>{
      this.JobberResponse=res;
      this.loader.stop();
    }, err=>{
      this.loader.stop();
      this.tosterService.error(err.message);
    });
    
  }


  getApiFailure(){
    this.loader.start();
    this.service.getApiFailureTrace().subscribe(res=>{
      this.ApiFailureResponse=res;
      this.loader.stop();
    }, err=>{
      this.loader.stop();
      this.tosterService.error(err.message);
    });
  }
   
  getAdsTrackLogs(){
    this.loader.start();
    this.service.getAdsTrackLog().subscribe(res=>{
      this.AdsLogs=res;
      this.loader.stop();
    }, err=>{
      this.loader.stop();
      this.tosterService.error(err.message);
    });
  }

  GetApartmentDashboardStas(){
    this.DashboardArray.splice(0);
    this.loader.start();
    this.service.getDashStas().subscribe(res => {
      this.TotalStas = res;
      for(let i=0; i<this.TotalStas.length; i++){
        var bc = this.TotalStas[i].counts;
        for(let j=0; j<bc.length; j++){
          if(bc[j].type == "Normal Visitor"){
           var normalvisitor=bc[j].count;
          }else if(bc[j].type == "Regular Visitor"){
            var regularvisitor=bc[j].count;
          }else if(bc[j].type == "Parcel"){
            var parcel=bc[j].count;
          }else if(bc[j].type == "Miscellaneous Invoice"){
            var MiscInvoice=bc[j].count;
          }else if(bc[j].type == "Total User"){
            var totuser =bc[j].count;
          }else if(bc[j].type == "Logged In User"){
            var loguser =bc[j].count;
          }else if(bc[j].type == "Expected Regular Visitor"){
            var exregvis =bc[j].count;
          }
        }
        var newData={
          "lastgenerated":this.TotalStas[i].last_generated,
          "appname":this.TotalStas[i].app_name,
          "appid":this.TotalStas[i].app_id,
          "pay_due_date":this.TotalStas[i].pay_due_date,
          "frequency_type":this.TotalStas[i].payment_frequency,
          "NormalVisitor":normalvisitor,
          "RegularVisitor":regularvisitor,
          "Parcel":parcel,
          "miscinvoice":MiscInvoice,
          "TotalUser":totuser,
          "LoggedUser":loguser,
          "ExpectedRegularVisitor":exregvis
        }
        this.DashboardArray.push(newData);
      }
      console.log("check",this.DashboardArray);
      
      console.log(res);
      this.loader.stop();
    }, err => {
      this.loader.stop();
      this.tosterService.error(err.message);
    });
  }
  paidMoney(money:any,date:any){
    
    var dart = new paymentModel(this.AppID,this.AppName,money,date,"Yes");
    console.log(dart);
    this.loader.start();
    this.service.updateApartmentInfo(dart).subscribe(res => {
      this.PaymentDetails = res;
      console.log(res);
      document.getElementById("paymentModal").click();
      $("#paidAmount").val("");
      $("#payDate").val("");
      this.loader.stop();
    }, err => {
      this.loader.stop();
      this.tosterService.error(err.message);
    });

  }
getAppDetails(appid:any,appname:any){
  this.AppID=appid;
  this.AppName=appname;
}


  GetMonthDetailsData(appid:any,appname:any){
    this.ApprtmentName=appname;
    this.DashboardArrayMonth.splice(0);
    this.loader.start();
    this.service.getMonthDetails(appid).subscribe(res => {
      this.TotalMonthData = res;
      for(let i=0; i<this.TotalMonthData.length; i++){
        var bc = this.TotalMonthData[i].counts;
        for(let j=0; j<bc.length; j++){
          if(bc[j].type == "Normal Visitor"){
           var normalvisitor=bc[j].count;
          }else if(bc[j].type == "Regular Visitor"){
            var regularvisitor=bc[j].count;
          }else if(bc[j].type == "Parcel"){
            var parcel=bc[j].count;
          }
        }
        var newData={
          "date":this.TotalMonthData[i].date,
          "NormalVisitor":normalvisitor,
          "RegularVisitor":regularvisitor,
          "Parcel":parcel
        }
        this.DashboardArrayMonth.push(newData);
        
      }
      let b=Object.keys(res).length;
        console.log("b",b);
        if(b == 0){
           $(".tbl8").css("display","none");
           $(".noData8").css("display","inline-block");
        }else{
          $(".tbl8").css("display","inline-block");
          $(".noData8").css("display","none");
        }
      console.log("check1",this.DashboardArrayMonth);
      
      console.log(res);
      this.loader.stop();
    }, err => {
      this.loader.stop();
      this.tosterService.error(err.message);
    });
  }
  GetMonthDetailsDataNew(appid:any,appname:any){
    this.ApprtmentName=appname;
    this.loader.start();
    this.service.getMonthDetailsForInvoice(appid).subscribe(res => {
      this.TotalMonthDataInvoice = res;
      let b=Object.keys(res).length;
        console.log("b",b);
        if(b == 0){
           $(".tbl9").css("display","none");
           $(".noData9").css("display","inline-block");
        }else{
          $(".tbl9").css("display","inline-block");
          $(".noData9").css("display","none");
        }
      console.log(res);
      this.loader.stop();
    }, err => {
      this.loader.stop();
      this.tosterService.error(err.message);
    });
  }
  getAllCount() {
    this.loader.start();
    this.service.getDashboard().subscribe(res => {
      this.TotalCount = res;
      this.tc = res;
      console.log(res);
      this.loader.stop();
    }, err => {
      this.loader.stop();
      this.tosterService.error(err.message);
    });
  }
  GetSignallerData(appid:any){
    this.loader.start();
    this.service.getSignallerDetails(appid).subscribe(res => {
      this.SigDetails = res;
      console.log(res);
      this.loader.stop();
    }, err => {
      this.loader.stop();
      this.tosterService.error(err.message);
    });
  }
  GetFacilityManagerData(appid:any){
    this.loader.start();
    this.service.getFacilityManager(appid).subscribe(res => {
      this.FacManDetails = res;
      console.log(res);
      this.loader.stop();
    }, err => {
      this.loader.stop();
      this.tosterService.error(err.message);
    });
  }
  GetAdminData(appid:any){
    this.loader.start();
    this.service.getAdminList(appid).subscribe(res => {
      this.AdminDetails = res;
      console.log(res);
      this.loader.stop();
    }, err => {
      this.loader.stop();
      this.tosterService.error(err.message);
    });
  }
  // filter dashboard
  getAllActiveState() {
    this.stateService.getActiveState().subscribe(res => {
      this.StateFilter = res;
    }, err => {
      this.tosterService.error(err.message);
    });
  }
  getFactiveCity(s_id) {
    this.AreaFilter = null;
    this.loader.start();
    this.cityService.getActiveCityById(s_id).subscribe(res => {
      this.loader.stop();
      this.CityFilter = res;
    }, err => {
      this.loader.stop();
      this.tosterService.error(err.message);
    });
  }
  getFactiveArea(c_id) {
    this.loader.start();
    this.areaService.getActiveAreaByID(c_id).subscribe(res => {
      this.AreaFilter = res;
      this.loader.stop();
    }, err => {
      this.loader.stop();
      this.tosterService.error(err.message);
    });
  }
  getFilterCount(state, city, area) {
    if (state !== '' && city !== '' && area !== '') {
      this.loader.start();
      this.service.filterDashboard(state, city, area).subscribe(res => {
        this.TotalCount = res;
        this.loader.stop();
      }, err => {
        this.loader.stop();
        this.tosterService.error(err.message);
      });
    } else {
      this.tosterService.info('choose all fields');
    }
  }
}


export class paymentModel{
  app_id:Number;
  app_name:String;
  pay_amount:String;
  payment_date:String;
  payment_status:String;
  constructor(app_id:Number,app_name:String,pay_amount:String,payment_date:String,payment_status:String){
   this.app_id=app_id;
   this.app_name=app_name;
   this.pay_amount=pay_amount;
   this.payment_date=payment_date;
   this.payment_status=payment_status;
  }
}