import { Component, OnInit } from '@angular/core';
import { MerchantService } from 'src/app/services/merchant.service';
import { DataTableDirective } from 'angular-datatables';
import {Subject} from 'rxjs';
import {FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { NgxUiLoaderService } from 'ngx-ui-loader';


@Component({
  selector: 'app-super-category',
  templateUrl: './super-category.component.html',
  styleUrls: ['./super-category.component.css']
})
export class SuperCategoryComponent implements OnInit {
  dtOptions: DataTables.Settings = {};
  dtTrigger: Subject<any> = new Subject();
  dtElement: DataTableDirective;
  isDtInitialized: boolean = false;
  ShopCategory: any[];
  sCategory: any[];
  SuperCategoryForm: FormGroup;

  constructor(
      private merchantService:MerchantService,
      private fb: FormBuilder,
      private tosterService: ToastrService,
      private loader: NgxUiLoaderService,
  ) {
    this.SuperCategoryForm=this.fb.group({
      merchant_shop_category_id: [, Validators.required],
      merchant_super_category: ["",Validators.required],
    })
    this.merchantService.getShopCategoryData().subscribe(data=>{
      console.log(data);
      this.ShopCategory=data;
    })
   }

  ngOnInit() {
  }
  getSuperCategory(merchant_shop_category_id:any){
    if(merchant_shop_category_id>0){
      this.loader.start();
      this.merchantService.SuperCategory(merchant_shop_category_id).subscribe(data=>{
        this.sCategory=data;
        this.loader.stop();
      }, error => {
        console.log(error);
        this.loader.stop();
        this.tosterService.error('Unable to get Shop');
      });
    }
    
  }
  submit(formdata: any) {
    if(formdata.merchant_shop_category_id>0){}
    this.loader.start();
    this.merchantService.add_super_category(formdata).subscribe((res:any)=>{
      this.loader.stop();
      this.tosterService.success(res.message);
    },
      error => {
        this.loader.stop();
        this.tosterService.error("Unable to add super category.")
    });
  
  }

}
