import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { environment } from './../../environments/environment';
import { catchError} from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class FloorService {
  httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json'})
  };
  constructor(private http: HttpClient) { }
  addFloor(data: any) {
    const url = 'appoff/addFloor';
    return this.http.post(environment.baseUrl + '' + url, data, this.httpOptions);
  }
  getFloorById(blkID: any) {
    const url = 'appoff/floor/';
    return this.http.get<any[]>(environment.baseUrl + url  + blkID);
  }
  activateFloor(fID: number, editdata: any) {
  const url = 'appoff/updateFloorActive/';
  return this.http.put(environment.baseUrl + url + fID,  editdata);
  }
  getActiveFloorByBlock(blk_ID: number) {
    const url = 'appoff/activeFloorByBlockId/';
    return this.http.get<any>(environment.baseUrl + url + blk_ID);
  }
}
