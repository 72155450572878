import { environment } from './../../../environments/environment';
import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { Subject } from 'rxjs';
import { DataTableDirective } from 'angular-datatables';
import { VisitorService } from 'src/app/services/visitor.service';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { ToastrService } from 'ngx-toastr';
import { ApartmentService } from 'src/app/services/apartment.service';
import { BlockService } from 'src/app/services/block.service';
import { FloorService } from 'src/app/services/floor.service';
import { BsDatepickerConfig } from 'ngx-bootstrap/datepicker';
import { ExcelServicesService } from 'src/app/services/excel-services.service';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { CityService } from 'src/app/services/city.service';

@Component({
  selector: 'app-normal-visitor',
  templateUrl: './normal-visitor.component.html',
  styleUrls: ['./normal-visitor.component.css']
})
export class NormalVisitorComponent implements OnInit, OnDestroy {
  @ViewChild(DataTableDirective, { static: false })
  dtElement: DataTableDirective;
  // tslint:disable-next-line: no-inferrable-types
  isDtInitialized: boolean = false;
  datePickerConfig: Partial<BsDatepickerConfig>;
  userPicUrl = environment.userPicUrl;
  visitorForm: FormGroup;
  Visitors: any[];
  Apartment: any[];
  Blocks: any[];
  City: any[];
  dtOptions: DataTables.Settings = {};
  dtTrigger: Subject<any> = new Subject();
  ngOnDestroy(): void {
    this.dtTrigger.unsubscribe();
  }
  constructor(
    private fb: FormBuilder,
    private service: VisitorService,
    private loader: NgxUiLoaderService,
    private tosterService: ToastrService,
    private appService: ApartmentService,
    private blockService: BlockService,
    private cityService: CityService,
    private excelService: ExcelServicesService
  ) {
    let maxdate = new Date();
    this.datePickerConfig = Object.assign({},
      {
        containerClass: 'theme-dark-blue',
        showWeekNumbers: false,
        dateInputFormat: 'DD-MM-YYYY',
        maxDate: maxdate,
      });
    this.visitorForm = this.fb.group({
      city_id: ['0', [Validators.required]],
      app_id: ['0', [Validators.required]],
      blk_id: ['0', [Validators.required]],
      from: ['', [Validators.required]],
      to: ['', [Validators.required]]
    });
  }

  ngOnInit() {
    this.dtOptions = {
      pagingType: 'full_numbers',
      pageLength: 10,
      processing: true,
      stateSave: true
    };
    this.allNormalVisitor();
    this.getActiveApartmentByCity();
  }
  allNormalVisitor() {
    this.loader.start();
    this.service.getNormalVisitor().subscribe((res: any) => {
      this.Visitors = res;
      this.loader.stop();
      if (this.isDtInitialized) {
        this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
          dtInstance.destroy();
          this.dtTrigger.next();
        });
      } else {
        this.isDtInitialized = true;
        this.dtTrigger.next();
      }
    });
  }
  getAllActiveCity() {
    this.cityService.getAllCities().subscribe(res => {
      this.City = res;
    });
  }
  getActiveApartmentByCity() {
    this.appService.getAllActiveApartment().subscribe(res => {
      this.Apartment = res;
    });
  }
  getBlockByApartment(aID: number) {
    this.blockService.getBlockById(aID).subscribe(res => {
      this.Blocks = res;
    });
  }
  exportAsXLSX(): void {
    this.excelService.exportAsExcelFile(this.Visitors, 'visitorLog');
  }
  filterVisitorList(data: any, fdate: string, tdate: string) {
    data.from = fdate;
    data.to = tdate;
    console.log(data);
    this.loader.start();
    this.service.getNormalVisitorBy(data).subscribe((res: any) => {
      console.log(res);
      this.Visitors = res;
      if (this.isDtInitialized) {
        this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
          dtInstance.destroy();
          this.dtTrigger.next();
        });
      } else {
        this.isDtInitialized = true;
        this.dtTrigger.next();
      }
      this.loader.stop();
    }, err => {
      this.loader.stop();
      this.tosterService.error(err);
    });
  }
}
