import { Component,OnInit,ViewChild } from '@angular/core';
import { DataTableDirective } from 'angular-datatables';
import { ToastrService } from 'ngx-toastr';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { Subject } from 'rxjs';
import { CityService } from 'src/app/services/city.service';
import { MerchantService } from 'src/app/services/merchant.service';
import { HttpClient } from '@angular/common/http';
import { StateService } from 'src/app/services/state.service';
import { AreaService } from 'src/app/services/area.service';
import { ApartmentService } from 'src/app/services/apartment.service';
import { FormArray, FormBuilder,FormGroup, Validators } from '@angular/forms';
@Component({
  selector: 'app-shop',
  templateUrl: './shop.component.html',
  styleUrls: ['./shop.component.css']
})

 export class ShopComponent implements OnInit {
    shopForm: FormGroup;
    @ViewChild(DataTableDirective, { static: false })
    dtElement: DataTableDirective;
    isDtInitialized: boolean = false;
    City: any[];
    ShopCategory: any[];
    dtOptions: DataTables.Settings = {};
    dtTrigger: Subject<any> = new Subject();
    Shop: any[];
    Country:any[];
    State: any[];
    Area: any[];      
    cityForm: any;
    Email: any;
    cTime:string;
    oTime:string;
    a: string;
  str: string;
    ngOnDestroy(): void {
      this.dtTrigger.unsubscribe();
    }
    constructor(
      private http:HttpClient,
      private stateService: StateService,
      private areaService: AreaService,
      private service: ApartmentService,
      private tosterService: ToastrService,
      private loader: NgxUiLoaderService,      
      private cityService: CityService,
      private merchantService:MerchantService,
      private fb: FormBuilder
     ) {
        this.shopForm=this.fb.group({
        shop_name: ['', Validators.required],
        description: ['', Validators.required],
        address: ['', Validators.required],        
        state_id: ['', Validators.required],
        city_id: ['', Validators.required],
        area: ['', Validators.required],
        pin: [''],
        store_timing: ["", Validators.required],
        store_open_days: ['',Validators.required],
        merchant_shop_category_id: ['', Validators.required],
        phone: ['', Validators.required],
        email: ['', [Validators.email, Validators.pattern("^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\\.[A-Za-z]{2,50}$"),
        Validators.required],],
        flexibility: ['', Validators.required],
        shop_type: ['', Validators.required]
      });
      this.loader.start();
      this.cityService.getAllActiveCity().subscribe(res=>{
        console.log(res);
        this.City=res;
        this.loader.stop();
      }, error => {
        console.log(error);
        this.tosterService.error("unable to submit");
      })
      this.loader.start();
      this.merchantService.getShopCategoryData().subscribe(data=>{
        console.log(data);
        this.ShopCategory=data;
        this.loader.stop();
      }, error => {
        console.log(error);
        this.tosterService.error("unable to submit");
      })
     
    }
    ngOnInit() {
      this.dtOptions = {
        pagingType: 'full_numbers',
        pageLength: 10,
        processing: true,
        stateSave: true
      };
      this.getAllActiveCity();      
      this.getActiveState();
      this.getShopCategoryData();
    }
    onSubmit(formdata: FormGroup) {
      console.log(formdata);
      this.loader.start();
      this.merchantService.addShop(this.shopForm.getRawValue()).subscribe((res:any)=>{
        console.log(res);        
        if (res.status === true){
          this.loader.stop();
        this.tosterService.success(res.message);
        this.shopForm.reset();
        (this.shopForm.controls['block'] as FormArray).clear();
        this.addShop();
        }
        else{this.loader.stop();
          this.tosterService.success(res.message);
          this.shopForm.reset();
          (this.shopForm.controls['block'] as FormArray).clear();
          this.addShop();

        }
      }, error => {
        console.log(error);
        this.tosterService.error("unable to submit");
      });
    }
  addShop() {
    throw new Error('Method not implemented.');
  }
  getShopCategoryData() {
    this.merchantService.getShopCategoryData().subscribe(res=>{this.ShopCategory=res;});
  }
   
    getAllActiveCity() {
      this.cityService.getAllActiveCity().subscribe(res => {
        this.City = res;
      });
    }
    getActiveState(){
      this.stateService.getActiveState().subscribe(data=>{this.State=data;});
    }
    getUsersFormData(data:any){ console.log(data);}
   
    
    getShop(merchant_shop_category_id: number, City_id: number){
      if ( City_id<0) {
      alert('Select City First');}
      else if(merchant_shop_category_id<0){alert('Select Category');}      
      else {
        this.loader.start();
        this.merchantService.getShopList(City_id,merchant_shop_category_id).subscribe((res: any) => {
          this.Shop = res;
          console.log(this.Shop);
          this.loader.stop();
          if (this.isDtInitialized) {
            this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
              dtInstance.destroy();
              this.dtTrigger.next();
            });
          } else {
            this.isDtInitialized = true;
            this.dtTrigger.next();
          }
        }, err => {
          this.loader.stop();
          this.tosterService.error('Unable to get Shop');
        });
      }
    }
  getActiveStateById(Cid: any) {
    this.loader.start();
    this.stateService.getActiveStateById(Cid).subscribe(res => {
      this.State = res;
      console.log(this.State);
      this.loader.stop();
    }, err => {
      this.loader.stop();
      this.tosterService.error('Not find state');
    });
  }
  getActiveCityById(Sid: any) {
    this.loader.start();
    this.cityService.getActiveCityById(Sid).subscribe(res => {
      this.City = res;
      console.log(this.City);
      this.loader.stop();
    }, err => {
      this.loader.stop();
      this.tosterService.error('Not find city');
    });
  }
  getActiveAreaById(CtId: any) {
    this.loader.start();
    this.areaService.getActiveAreaByID(CtId).subscribe(res => {
      this.Area = res;
      console.log(this.Area);
      this.loader.stop();
    }, err => {
      this.loader.stop();
      this.tosterService.error('Not Getting Area');
    });
  }  
  getPincode(Aid: any) {
    this.service.getPinCode(Aid).subscribe((res: any) => {
      this.shopForm.patchValue({
        pin: res.pin_code,
      });
    });
  }
 closeing(time){
   if(time.value !== ""){
     var hours=time.split(":")[0];
     var minutes=time.split(":")[1];
     var suffix=hours>=12?"pm":"am";
     hours=hours%12||12;
     this.cTime=hours+":"+minutes+" "+suffix;
     this.a=this.oTime+" to "+this.cTime;
     document.getElementById("opentime").innerHTML=this.a;
   }
 }
 opening(time){
  if(time.value !== ""){
    var hours=time.split(":")[0];
    var minutes=time.split(":")[1];
    var suffix=hours>=12?"pm":"am";
    hours=hours%12||12;
    this.oTime=hours+":"+minutes+" "+suffix;        
 }
}
openDays(data){
  if((document.getElementById("days").innerHTML).indexOf(data)!=(-1)){
    (document.getElementById("days").innerHTML)=(document.getElementById("days").innerHTML).replace(data,"");
    data="";
  }
  document.getElementById("days").innerHTML=document.getElementById("days").innerHTML+" "+data;
  this.str=document.getElementById("days").innerHTML; 
}



} 

