import { AreaService } from './../../services/area.service';
import { StateService } from './../../services/state.service';
import { CountryService } from './../../services/country.service';
import { CityService } from './../../services/city.service';
import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { Subject } from 'rxjs';
import { DataTableDirective } from 'angular-datatables';
import { FormGroup, FormBuilder, FormArray, AbstractControl, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { NgxUiLoaderService } from 'ngx-ui-loader';
declare var jQuery: any;
@Component({
  selector: 'app-area',
  templateUrl: './area.component.html',
  styleUrls: ['./area.component.css']
})
export class AreaComponent implements OnInit, OnDestroy {
  areaForm: FormGroup;
  @ViewChild(DataTableDirective, { static: false })
  dtElement: DataTableDirective;
  // tslint:disable-next-line: no-inferrable-types
  isDtInitialized: boolean = false;
  City: any[];
  Country: any[];
  State: any[];
  Area: any[] = null;
  // datatable
  dtOptions: DataTables.Settings = {};
  dtTrigger: Subject<any> = new Subject();
  ngOnDestroy(): void {
    this.dtTrigger.unsubscribe();
  }
  constructor(
    private service: AreaService,
    private tosterService: ToastrService,
    private loader: NgxUiLoaderService,
    private fb: FormBuilder,
    private cityService: CityService,
    private countryService: CountryService,
    private stateService: StateService) {
    this.areaForm = this.fb.group({
      country_id: ['0'],
      state_id: ['0'],
      city_id: ['0'],
      arealst: this.fb.array([
        this.addAreaFormgroup()
      ])
    });
  }
  ngOnInit() {
    this.dtOptions = {
      pagingType: 'full_numbers',
      pageLength: 10,
      processing: true,
      stateSave: true
    };
    this.allArea();
    this.allActiveCountry();
  }
  onSubmit(formdata: any) {
    for (let index in formdata.arealst) {
      if (formdata.arealst[index].active === true) {
        formdata.arealst[index].active = 'Yes';
      } else {
        formdata.arealst[index].active = 'No';
      }
    }
    this.loader.start();
    this.service.addArea(formdata).subscribe((res: any) => {
      if (res.status === true) {
        this.loader.stop();
        this.tosterService.success(res.message);
        this.areaForm.reset();
        (this.areaForm.controls['arealst'] as FormArray).clear();
        this.addArea();
        // tslint:disable-next-line:quotemark
        jQuery("#exampleModalCenter").modal("hide");
      } else {
        this.loader.stop();
        this.tosterService.success(res.message);
        this.areaForm.reset();
        (this.areaForm.controls['arealst'] as FormArray).clear();
        this.addArea();
        // tslint:disable-next-line:quotemark
        jQuery("#exampleModalCenter").modal("hide");
      }
    },
      error => {
        this.tosterService.error(error);
      });
  }
  addAreaFormgroup(): FormGroup {
    return this.fb.group({
      area_name: ['', Validators.required],
      pin_code: ['', Validators.required],
      active: [false]
    });
  }
  getAreasFormControls(): AbstractControl[] {
    return (this.areaForm.get('arealst') as FormArray).controls;
  }
  addArea(): void {
    (this.areaForm.get('arealst') as FormArray).push(this.addAreaFormgroup());
  }
  removeArea(index) {
    (this.areaForm.get('arealst') as FormArray).removeAt(index);
  }
  allArea() {
    this.service.getAreById(561).subscribe((res: any) => {
      this.Area = res;
      if (this.isDtInitialized) {
        this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
          dtInstance.destroy();
          this.dtTrigger.next();
        });
      } else {
        this.isDtInitialized = true;
        this.dtTrigger.next();
      }
    });
  }
  allActiveCountry() {
    this.countryService.getActiveCountry().subscribe((res: any) => {
      this.Country = res;
    });
  }
  getActiveStateByCountry(Cid: any) {
    this.loader.start();
    this.City = null;
    this.stateService.getActiveStateById(Cid).subscribe((res: any) => {
      this.State = res;
      this.loader.stop();
    }, err => {
      this.loader.stop();
      this.tosterService.error('Unable to get State');
    });
  }
  getActiveCityByState(Sid: any) {
    this.loader.start();
    this.cityService.getActiveCityById(Sid).subscribe((res: any) => {
      this.City = res;
      this.loader.stop();
    }, err => {
      this.loader.stop();
      this.tosterService.error('Unable to find City');
    });
  }
  getAreaByCity(CtId: any) {
    if (CtId == 'Select City') {
      alert('Select City First');
    } else {
      this.loader.start();
      this.service.getAreById(CtId).subscribe((res: any) => {
        this.Area = res;
        this.loader.stop();
        if (this.isDtInitialized) {
          this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
            dtInstance.destroy();
            this.dtTrigger.next();
          });
        } else {
          this.isDtInitialized = true;
          this.dtTrigger.next();
        }
      }, err => {
        this.loader.stop();
        this.tosterService.error('Unable to get Area');
      });
    }
  }
  activeArea(data: any) {
    const editdata = { area_id: data.area_id, active: 'Yes' };
    this.service.activeArea(editdata).subscribe((res: any) => {
      this.getAreaByCity(data.city_id);
    });
  }
  deActiveArea(data: any) {
    const editdata = { area_id: data.area_id, active: 'No' };
    this.service.activeArea(editdata).subscribe((res: any) => {
      this.getAreaByCity(data.city_id);
    });
  }
}
