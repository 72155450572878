import { UrlSegment } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from './../../environments/environment';
@Injectable({
  providedIn: 'root'
})
export class StateService {
  serverUrl = environment.baseUrl;
  constructor( private http: HttpClient) { }
  getAllstate(): Observable<any[]> {
    const url = 'location/allState';
    return this.http.get<any[]>(environment.baseUrl + '' + url);
    }
  getActiveStateById(cId: number) {
    const url = 'location/activeState/';
    return this.http.get<any[]>(environment.baseUrl + '' + url + cId);
  }
  activeState(data: any) {
    const url = 'location/updateState/';
    return this.http.put(environment.baseUrl + '' + url + '' + data.state_id , data );
  }
  getStateById(Cid: any): Observable<any[]> {
    const url = 'location/allState/';
    return this.http.get<any[]>(environment.baseUrl + '' + url + '' + Cid);
    }
  getActiveState() {
    const url = 'location/activeState';
    return this.http.get<any[]>(environment.baseUrl + url);
  }
}
