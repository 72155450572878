import { SubscriptionService } from './../../services/subscription.service';
import { Component, OnInit, OnDestroy, ViewChild, AfterViewInit } from '@angular/core';
import { DataTableDirective } from 'angular-datatables';
import { FormGroup, FormBuilder, FormArray, AbstractControl, FormControl, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { SubAdminService } from 'src/app/services/sub-admin.service';
import { BsDatepickerConfig } from 'ngx-bootstrap/datepicker';
import { take, first, tap, min } from 'rxjs/operators';
import { forkJoin, Subject, Observable, timer } from 'rxjs';
import { NgxUiLoaderService } from 'ngx-ui-loader';

declare var jQuery: any;
@Component({
  selector: 'app-subscription',
  templateUrl: './subscription.component.html',
  styleUrls: ['./subscription.component.css']
})
export class SubscriptionComponent implements OnInit, OnDestroy {

  subscriptionForm: FormGroup;
  Subscription: any[];
  // datatable
  @ViewChild(DataTableDirective, { static: false })
  dtElement: DataTableDirective;
  // tslint:disable-next-line: no-inferrable-types
  isDtInitialized: boolean = false;
  dtOptions: DataTables.Settings = {};
  dtTrigger: Subject<any> = new Subject();
  ngOnDestroy(): void {
    this.dtTrigger.unsubscribe();
  }

  constructor(
    private service: SubscriptionService,
    private tosterService: ToastrService,
    private loader: NgxUiLoaderService,
    private fb: FormBuilder,
  ) {
    this.subscriptionForm = this.fb.group({
      sub_name: ['', Validators.required],
      sub_amount: ['', Validators.required],
      gst: ['', Validators.required],
      service_tax: ['', Validators.required],
      discount: ['', Validators.required],
      sub_validity_months: ['', Validators.required],
      active: [false]
    });
  }

  ngOnInit() {
    this.dtOptions = {
      pagingType: 'full_numbers',
      pageLength: 10,
      processing: true,
      stateSave: true
    };
    this.allSubscription();
  }
  addSubscription(formdata: any) {
    if (formdata.active) {
      formdata.active = 'Yes';
    } else {
      formdata.active = 'No';
    }
    this.loader.start();
    console.log(formdata);
    this.service.addSubscription(formdata).subscribe((res: any) => {
      if (res.status) {
        this.loader.stop();
        this.tosterService.success(res.message);
        this.subscriptionForm.reset();
        this.allSubscription();
        // tslint:disable-next-line:quotemark
        jQuery("#addSubscription").modal("hide");
      }
    }, err => {
      this.loader.stop();
      this.tosterService.error(err.message);
    });
  }
  allSubscription() {
    this.service.getSubscription().subscribe(res => {
      this.Subscription = res;
      if (this.isDtInitialized) {
        this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
          dtInstance.destroy();
          this.dtTrigger.next();
        });
      } else {
        this.isDtInitialized = true;
        this.dtTrigger.next();
      }
    }, err => {
      this.tosterService.error(err.message);
    });
  }
  activateSubscription(data: any) {
    console.log(data);
    let active;
    if (data.active === 'Yes') {
      active = { active: 'No' };
    } else if (data.active === 'No') {
      active = { active: 'Yes' };
    }
    this.loader.start();
    this.service.activateSubscription(data.sub_id, active).subscribe(
      (res: any) => {
        this.allSubscription();
        this.loader.stop();
        this.tosterService.success(res.message);
      },
      err => {
        this.loader.stop();
        this.tosterService.error(err.mesage);
      }
    );
  }
}
