import { JobsService } from './../../services/jobs.service';
import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { Subject } from 'rxjs';
import { DataTableDirective } from 'angular-datatables';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { ToastrService } from 'ngx-toastr';
import { FormGroup, FormBuilder, FormArray, AbstractControl, Validators } from '@angular/forms';
declare var jQuery: any;

@Component({
  selector: 'app-jobs',
  templateUrl: './jobs.component.html',
  styleUrls: ['./jobs.component.css']
})
export class JobsComponent implements OnInit, OnDestroy {
  @ViewChild(DataTableDirective, { static: false })
  dtElement: DataTableDirective;
  jobForm: FormGroup;
  jobFormEdit: FormGroup;
  // tslint:disable-next-line: no-inferrable-types
  isDtInitialized: boolean = false;
  jobs: any[];
  Cities: any[];
  Departments: any[];
  ckeConfig: any;

  dtOptions: DataTables.Settings = {};
  dtTrigger: Subject<any> = new Subject();
  ngOnDestroy(): void {
    this.dtTrigger.unsubscribe();
  }
  constructor(
    private service: JobsService,
    private loader: NgxUiLoaderService,
    private fb: FormBuilder,
    private tosterService: ToastrService,
  ) {
    this.jobForm = this.fb.group({
      job_location_id: ['', Validators.required],
      job_department_id: ['', Validators.required],
      job_title: ['', Validators.required],
      job_message: ['', Validators.required],
      experience_level: ['', Validators.required],
      employment_type: ['', Validators.required],
      number_of_positions: ['', Validators.required],
      active: ['']
    });
    this.jobFormEdit = this.fb.group({
      job_id: ['', Validators.required],
      job_location_id: ['', Validators.required],
      job_department_id: ['', Validators.required],
      job_title: ['', Validators.required],
      job_message: ['', Validators.required],
      experience_level: ['', Validators.required],
      employment_type: ['', Validators.required],
      number_of_positions: ['', Validators.required],
      active: ['']
    });
  }

  ngOnInit() {
    this.dtOptions = {
      pagingType: 'full_numbers',
      pageLength: 10,
      processing: true,
      stateSave: true
    };
    this.ckeConfig = {
      allowedContent: false,
      // extraPlugins: 'divarea',
      forcePasteAsPlainText: true,
      toolbarGroups: [
        { name: 'basicstyles', groups: ['basicstyles', 'cleanup'] },
        { name: 'paragraph', groups: ['list', 'indent', 'blocks', 'align', 'bidi', 'paragraph'] },
        { name: 'links', groups: ['links'] },
        { name: 'styles', groups: ['styles'] },
        { name: 'colors', groups: ['colors'] },
      ]
    };
    this.alljobs();
  }

  alljobs() {
    this.service.getAlljobs().subscribe((res: any) => {
      this.jobs = res;
      if (this.isDtInitialized) {
        this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
          dtInstance.destroy();
          this.dtTrigger.next();
        });
      } else {
        this.isDtInitialized = true;
        this.dtTrigger.next();
      }
    });
  }
  getJobByID(id: number) {
    this.readyAddForm();
    this.loader.start();
    this.service.getJobByID(id).subscribe((res: any) => {
      if (res.active === 'Yes') {
        this.jobFormEdit.patchValue({
          active: true
        });
      } else {
        this.jobFormEdit.patchValue({
          active: ''
        });
      }
      this.jobFormEdit.patchValue({
        job_id: res.job_id,
        job_location_id: res.job_location_id,
        job_department_id: res.job_department_id,
        job_title: res.job_title,
        job_message: res.job_message,
        experience_level: res.experience_level,
        employment_type: res.employment_type,
        number_of_positions: res.number_of_positions,
      });
      this.loader.stop();
    }, err => {
      this.loader.stop();
      this.tosterService.error(err.message);
    });
  }
  readyAddForm() {
    this.loader.start();
    this.getAllLocation();
    this.getAllDepartments();
    this.loader.stop();
  }

  getAllLocation() {
    this.service.getAlllocation().subscribe((res: any) => {
      this.Cities = res;
    });
  }
  getAllDepartments() {
    this.service.getAlldepartment().subscribe((res: any) => {
      this.Departments = res;
    });
  }
  add(formdata: any) {
    console.log(formdata.active);
    if (formdata.active === true) {
      formdata.active = 'Yes';
    } else {
      formdata.active = 'No';
    }
    console.log(formdata);
    this.loader.start();
    this.service.addJob(formdata).subscribe((res: any) => {
      if (res.status === true) {
        this.loader.stop();
        this.jobForm.reset();
        this.jobForm.get('job_location_id').setValue('');
        this.jobForm.get('job_department_id').setValue('');
        this.jobForm.get('experience_level').setValue('');
        this.jobForm.get('employment_type').setValue('');
        this.tosterService.success(res.message);
        this.alljobs();
        // tslint:disable-next-line:quotemark
        jQuery("#exampleModalCenter").modal("hide");
      } else {
        this.loader.stop();
        this.tosterService.success(res.message);
      }
    },
      error => {
        this.loader.stop();
        this.tosterService.error(error);
      });
  }
  edit(data: any) {
    if (data.active === true) {
      data.active = 'Yes';
    } else {
      data.active = 'No';
    }
    console.log(data);
    this.loader.start();
    this.service.updatejob(data, data.job_id).subscribe((res: any) => {
      if (res.status) {
        this.loader.stop();
        this.jobFormEdit.reset();
        this.jobFormEdit.get('job_location_id').setValue('');
        this.jobFormEdit.get('job_department_id').setValue('');
        this.jobFormEdit.get('experience_level').setValue('');
        this.jobFormEdit.get('employment_type').setValue('');
        this.tosterService.success(res.message);
        this.jobFormEdit.reset();
        this.alljobs();
        jQuery('#EditJob').modal('hide');
      } else {
        this.loader.stop();
        this.tosterService.success(res.message);
      }
    }, err => {
      this.loader.stop();
      this.tosterService.error(err.message);
    });
  }

  deleteJobByID(id: number) {
    this.loader.start();
    this.service.deletejob(id).subscribe((res: any) => {
      if (res.status) {
        this.loader.stop();
        this.tosterService.success(res.message);
        this.alljobs();
      }
    }, err => {
      this.loader.stop();
      this.tosterService.error(err.message);
    });
  }

}
