import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { BehaviorSubject, forkJoin, of, Observable, throwError } from 'rxjs';
import { environment } from './../../environments/environment';
import { tap, catchError } from 'rxjs/operators';
import { NgxUiLoaderService } from 'ngx-ui-loader';



@Injectable({
  providedIn: 'root'
})
export class SubscriptionService {
  httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' })
  };
  constructor(
    private http: HttpClient,
    private loader: NgxUiLoaderService,
  ) { }
  addSubscription(formdata: any) {
    const url = 'appoff/subscription';
    return this.http.post(environment.baseUrl + url, formdata, this.httpOptions);
  }
  getSubscription() {
    const url = 'appoff/getSubscription';
    return this.http.get<any[]>(environment.baseUrl + url);
  }
  getActiveSubscription() {
    const url = 'appoff/getActiveSubscription';
    return this.http.get<any[]>(environment.baseUrl + url);
  }
  activateSubscription(sId: any, data: any) {
    const url = 'appoff/edit/subscription/';
    return this.http.put(environment.baseUrl + url + sId, data);
  }
}
