import { ApartmentService } from './../../services/apartment.service';
import { AreaService } from './../../services/area.service';
import { StateService } from './../../services/state.service';
import { CountryService } from './../../services/country.service';
import { CityService } from './../../services/city.service';
import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { Subject } from 'rxjs';
import { DataTableDirective } from 'angular-datatables';
import { FormGroup, FormBuilder, FormArray, AbstractControl, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { BsDatepickerConfig } from 'ngx-bootstrap/datepicker';
declare var jQuery: any;



import { FormControl } from '@angular/forms';

import { HouseService } from './../../services/house.service';
import { FloorService } from './../../services/floor.service';
import { BlockService } from './../../services/block.service';
import { SubAdminService } from 'src/app/services/sub-admin.service';

import { take, first, tap, delay } from 'rxjs/operators';
import { forkJoin,  Observable, timer } from 'rxjs';

import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';
import { runInThisContext } from 'vm';
import { log } from 'console';
import { DatePipe } from '@angular/common';
declare var jQuery: any;
@Component({
  selector: 'app-apartment',
  templateUrl: './apartment.component.html',
  styleUrls: ['./apartment.component.css']
})
export class ApartmentComponent implements OnInit, OnDestroy {
  
  apartmentForm: FormGroup;
  @ViewChild(DataTableDirective, { static: false })
  dtElement: DataTableDirective;
  // tslint:disable-next-line: no-inferrable-types
  isDtInitialized: boolean = false;
  Apartment: any[];
  City: any[];
  Country: any[];
  State: any[];
  City2: any[];
  Area: any[] = null;
  Subs: any[] = null;
  subscription = false;
  sValue = 0;
  email1: string;
  editEmail: any;
  editPhone: any;
  otpStatus = false;
  eEOTP = true;
  ePOTP = true;
  fShow = true;
  timeLeft = 120;
  interval: any;
  mailOtp: number;
  app_email = null;

  datePickerConfig: Partial<BsDatepickerConfig>;
  dtOptions: DataTables.Settings = {};
  dtTrigger: Subject<any> = new Subject();
  admin: number;
  app_id: any;
  Month: number;
  AddApartment: any;
  ngOnDestroy(): void {
    this.dtTrigger.unsubscribe();
  }
  constructor(
    private tosterService: ToastrService,
    private loader: NgxUiLoaderService,
    private service: ApartmentService,
    private cityService: CityService,
    private countryService: CountryService,
    private stateService: StateService,
    private areaService: AreaService,
    private fb: FormBuilder,
    public datepipe: DatePipe
  ) {
    this.datePickerConfig = Object.assign({},
      {
        containerClass: 'theme-dark-blue',
        showWeekNumbers: false,
        dateInputFormat: 'DD-MM-YYYY',
      });
    this.apartmentForm = this.fb.group({
      country: ['0', Validators.required],
      state: ['0', Validators.required],
      city: ['0', Validators.required],
      area: ['0', Validators.required],
      app_name: ['', Validators.required],
      app_address: ['', Validators.required],
      app_email: ['', [Validators.email, Validators.pattern("^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\\.[A-Za-z]{2,50}$"),
      Validators.required], this.chkEmail.bind(this)],
      // Validators.pattern("^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$")])
      app_type: ['A'],
      pin: [''],
      police_station: ['', Validators.required],
      fire_station: ['', Validators.required],
      hospital: ['', Validators.required],
      ambulance: ['', Validators.required],
      community_help_desk: ['', Validators.required],
      subscription_id: ['0', Validators.required],
      app_gate_no1: [''],
      app_gate_no2: [''],
      app_gate_no3: [''],
      app_gate_no4: [''],
      gate_no1_phone: [''],
      gate_no2_phone: [''],
      gate_no3_phone: [''],
      gate_no4_phone: [''],
      frequency_type:[''],
      pay_due_date:[''],
      next_pay_due_date:[''],
      app_email_verified:[''],
      // service_end_date: ['', Validators.required],
      // service_start_date: ['', Validators.required],
      active: [false]
    });
  }

  ngOnInit() {
    this.dtOptions = {
      pagingType: 'full_numbers',
      pageLength: 10,
      processing: true,
      stateSave: true
    };
    this.getActiveCountry();
    this.getAllActiveCity();
    this.getSubscription();
  }

  startTimer() {
    this.interval = setInterval(() => {
      if (this.timeLeft > 0) {
        this.timeLeft--;
      } else {
        this.timeLeft = 0;
      }
    }, 1000);
  }
  
  onSubmit(apart: any, ssd: any, sed: any) {
    console.log("Apartment = "+apart);
    this.email1=apart.app_email;
    apart.service_start_date = ssd;
    apart.service_end_date = sed;
    if (apart.active === true) {
      apart.active = 'Yes';
    } else {
      apart.active = 'No';
    }
    this.app_email = apart["email"];
    var d = new Date(apart.pay_due_date);
    if(apart.frequency_type == "M"){
      d.setMonth(d.getMonth()+1);
      apart.next_pay_due_date=this.datepipe.transform(d, "yyyy-MM-dd");
    }
    else if(apart.frequency_type == "Q"){
      d.setMonth(d.getMonth()+3);
      apart.next_pay_due_date=this.datepipe.transform(d, "yyyy-MM-dd");
    }
    else if(apart.frequency_type == "HL"){
      d.setMonth(d.getMonth()+6);
      apart.next_pay_due_date=this.datepipe.transform(d, "yyyy-MM-dd");
    }
    else if(apart.frequency_type == "Y"){
      d.setMonth(d.getMonth()+12);
      apart.next_pay_due_date=this.datepipe.transform(d, "yyyy-MM-dd");
    }

   this.AddApartment=apart;

   this.loader.start();
   this.service.addApartment(apart).subscribe((res: any) => {
     console.log("res = "+res);
     this.admin = res["appId"]["app_id"];
     this.app_id=res["appId"]["app_id"];
     console.log("App_id = "+this.app_id);
     if (res.status === true) {
       this.loader.start();
           clearInterval(this.interval);
           forkJoin(this.service.sendEmailOTP(apart["app_email"]))
             .subscribe(([emailRes]) => {
               if (emailRes["status"]) {
                 this.loader.stop();
                 
                 this.mailOtp = emailRes["otp"];
                 
                 console.log('Email-' + emailRes["otp"]);
                 this.tosterService.success("OTP sent Successful");
                 this.fShow = false;
                 this.startTimer();
               }
             }, error => {
               this.loader.stop();
               this.tosterService.error(error);
             });
       // this.loader.stop();
       // this.tosterService.success('Apartment Added !!');
       // // tslint:disable-next-line:quotemark
       // jQuery("#exampleModalCenter").modal("hide");
     }
   },
     err => {
       this.loader.stop();
       this.tosterService.error(err.message);
     });


  }
  
  verifyOTP(eOTP: number) {
    console.log("eotp = "+eOTP);
    // log('your stuff');
    console.log("eotp = "+this.admin);
    if (eOTP === this.mailOtp) {
      this.loader.start();
      forkJoin( this.service.veryfyMailOTP(this.admin))
        .subscribe(([res2]) => {
          if (res2["status"]) {
            this.loader.stop();
            //this.getAdmin();
            this.tosterService.success("Email  verified");
            // tslint:disable-next-line:quotemark
            jQuery("#exampleModalCenter").modal("hide");
            this.fShow = true;
          }
        }, error => {
          this.loader.stop();
          this.tosterService.error(error);
        });
    } else {
      this.tosterService.error('OTP invalid');
    }
  }

  addNewApartment(){
    this.AddApartment.app_email_verified="Yes";
  
    this.loader.start();
    this.service.addApartment(this.AddApartment).subscribe((res: any) => {
      console.log("res = "+res);
      this.admin = res["appId"]["app_id"];
      this.app_id=res["appId"]["app_id"];
     this.loader.stop();
    },
      err => {
        this.loader.stop();
        this.tosterService.error(err.message);
      });

  }




  activeApartment(data: any) {
    const editdata = { app_id: data.app_id, active: 'Yes' };
    this.service.activeApartment(editdata).subscribe(res => {
      this.getApartByAreaId(data.area);
    });
  }
  deActiveApartment(data: any) {
    const editdata = { app_id: data.app_id, active: 'No' };
    this.service.activeApartment(editdata).subscribe(res => {
      this.getApartByAreaId(data.area);
    });
  }



  // added by ashis


  resendOTP() {
    clearInterval(this.interval);
    this.loader.start();
    forkJoin( this.service.sendEmailOTP(this.email1))
      .subscribe(([ emailRes]) => {
        if (emailRes["status"]) {
          this.loader.stop();
          
          this.mailOtp = emailRes["otp"];
    
          console.log('Email-' + emailRes["otp"]);
          this.tosterService.success("OTP sent Successful");
          this.timeLeft = 120;
          this.startTimer();
        }
      }, error => {
        this.loader.stop();
        this.tosterService.error(error);
      });
  }

  chkEmail(control: FormControl): Promise<any> | Observable<any> {
    const promise = new Promise((resolve, reject) => {
      let data = {
        app_email: control.value
      };
      console.log(data);
      this.service.checkDuplicateAppEmail(data).subscribe(
        resData => {
          console.log(resData);
          console.log("data = "+resData);
          if (resData['status']) {
            resolve(null);
          } else {
            resolve({ 'textIsForbidden': true });
          }
        },
        error => console.log(error)
      );
    });
    return promise;
  }
  
  emailErrorMessage() {
    return this.apartmentForm.get('app_email').hasError('required') && this.apartmentForm.get('app_email').touched ? 'You must enter an email' :
      this.apartmentForm.get('app_email').hasError('pattern') ? 'You must enter a valid email' :
        this.apartmentForm.get('app_email').hasError('textIsForbidden') ? 'Email already exist!' : '';
  }

  //added by ashuis
  getActiveCountry() {
    this.countryService.getActiveCountry().subscribe(res => {
      this.Country = res;
    });
  }
  getActiveStateById(Cid: any) {
    this.loader.start();
    this.stateService.getActiveStateById(Cid).subscribe(res => {
      this.State = res;
      this.loader.stop();
    }, err => {
      this.loader.stop();
      this.tosterService.error('Not find state');
    });
  }
  getAllActiveCity() {
    this.cityService.getAllActiveCity().subscribe(res => {
      this.City2 = res;
    });
  }
  getActiveCityById(Sid: any) {
    this.loader.start();
    this.cityService.getActiveCityById(Sid).subscribe(res => {
      this.City = res;
      this.loader.stop();
    }, err => {
      this.loader.stop();
      this.tosterService.error('Not find city');
    });
  }
  getActiveAreaById(CtId: any) {
    this.loader.start();
    this.areaService.getActiveAreaByID(CtId).subscribe(res => {
      this.Area = res;
      this.loader.stop();
    }, err => {
      this.loader.stop();
      this.tosterService.error('Not Getting Area');
    });
  }
  getPincode(Aid: any) {
    this.service.getPinCode(Aid).subscribe((res: any) => {
      this.apartmentForm.patchValue({
        pin: res.pin_code,
      });
    });
  }
  getApartByAreaId(areaId: any) {
    console.log(this.subscription);
    console.log(this.sValue);
    if (areaId == 'Select Area') {
      alert('Select Area First');
    } else {
      if (this.subscription === false && this.sValue === 0) {
        this.loader.start();
        this.service.getActiveApartmentByArea(areaId).subscribe((res: any) => {
          this.Apartment = res;
          console.log(res);
          this.loader.stop();
          if (this.isDtInitialized) {
            this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
              dtInstance.destroy();
              this.dtTrigger.next();
            });
          } else {
            this.isDtInitialized = true;
            this.dtTrigger.next();
          }
        }, err => {
          this.loader.stop();
          this.tosterService.error('Something Wrong');
        });
      } else {
        // apartment by area id and subscription
        this.loader.start();
        this.service.getAppbyFilter(areaId, this.sValue).subscribe((res: any) => {
          this.Apartment = res;
          this.loader.stop();
          if (this.isDtInitialized) {
            this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
              dtInstance.destroy();
              this.dtTrigger.next();
            });
          } else {
            this.isDtInitialized = true;
            this.dtTrigger.next();
          }
        }, err => {
          this.loader.stop();
          this.tosterService.error('Something Wrong');
        });
      }
    }
  }
  // start ashis -- get mall by area_id and app_type
  getMallByAreaId(areaId: any, app_type : any) {
    //console.log(this.subscription);
    //console.log(this.sValue);
    if (areaId == 'Select Area') {
      alert('Select Area First');
    } else {
      if (this.subscription === false && this.sValue === 0) {
        this.loader.start();
        this.service.getMallByAreaId(areaId,).subscribe((res: any) => {
          this.Apartment = res;
          console.log(res);
          this.loader.stop();
          if (this.isDtInitialized) {
            this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
              dtInstance.destroy();
              this.dtTrigger.next();
            });
          } else {
            this.isDtInitialized = true;
            this.dtTrigger.next();
          }
        }, err => {
          this.loader.stop();
          this.tosterService.error('Something Wrong');
        });
      } 
    }
  }

  //end ashis -- get mall by area_id and app_type

  subscriptionChange(e: any) {
    if (e.target.checked == false) {
      this.sValue = 0;
      this.subscription = e.target.checked;
    } else {
      this.subscription = e.target.checked;
    }
  }
  onSubValueChange(s: number) {
    this.sValue = s;
  }
  appSubscriptionChange(appid: number, sub: any) {
    let data = { subscription_id: sub };
    this.loader.start();
    this.service.updateAppSubscription(appid, data).subscribe((res: any) => {
      this.loader.stop();
      if (res.status) {
        this.tosterService.success('Subscription updated');
      }
    }, err => {
      this.loader.stop();
      this.tosterService.error('something wrong');
    });
  }
  getSubscription() {
    this.loader.start();
    this.service.getActiveSubscription().subscribe(res => {
      this.loader.stop();
      this.Subs = res;
    }, err => {
      this.loader.stop();
      this.tosterService.error(err.message);
    });
  }
  activateAdmin(data: any) {
    console.log(data);
    let activeStatus;
    if (data.admin_active === 'Yes') {
      activeStatus = { admin_active: 'No' };
    } else if (data.admin_active === 'No' || data.admin_active == null) {
      activeStatus = { admin_active: 'Yes' };
    }
    this.loader.start();
    this.service.activateAdmin(activeStatus, data.app_id).subscribe((res: any) => {
      this.getApartByAreaId(data.area);
      this.loader.stop();
      this.tosterService.success(res.message);
    }, err => {
      this.loader.stop();
      this.tosterService.error(err.message);
    });
  }
}
