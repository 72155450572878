import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DataTablesModule } from 'angular-datatables';
import { AdminRoutingModule } from './admin-routing.module';
import { AdminComponent } from './admin/admin.component';
import { CountryComponent } from './country/country.component';
import { StateComponent } from './state/state.component';
import { CityComponent } from './city/city.component';
import { AreaComponent } from './area/area.component';
import { BlockComponent } from './block/block.component';
import { FloorComponent } from './floor/floor.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { JobberComponent } from './jobber/jobber.component';
import { BookingComponent } from './booking/booking.component';
import { SubAdminComponent } from './sub-admin/sub-admin.component';
import { HouseComponent } from './house/house.component';
import { CustomerInvoiceComponent } from './customer-invoice/customer-invoice.component';
import { JobberBillingComponent } from './jobber-billing/jobber-billing.component';
import { UserComponent } from './user/user.component';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { ApartmentBillingComponent } from './apartment-billing/apartment-billing.component';
import { NormalVisitorComponent } from './normal-visitor/normal-visitor.component';
import { RegularVisitorComponent } from './regular-visitor/regular-visitor.component';
import { ApartmentComponent } from './apartment/apartment.component';
import { SubscriptionComponent } from './subscription/subscription.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { SkillComponent } from './skill/skill.component';
import { PaidBillComponent } from './paid-bill/paid-bill.component';
import { DepartmentComponent } from './department/department.component';
import { JobsComponent } from './jobs/jobs.component';
import { CKEditorModule } from 'ng2-ckeditor';
import { CouponsComponent } from './coupons/coupons.component';
import { NgxPaginationModule } from 'ngx-pagination';
import { DatePipe } from '@angular/common';
import { MallComponent } from './mall/mall.component';
import { PrivateoComponent } from './privateo/privateo.component';
import { GovernmentoComponent } from './governmento/governmento.component';
import { AssetComponent } from './asset/asset.component';
import { NoticeComponent } from './notice/notice.component';
import { CovidComponent } from './covid/covid.component';
import { AdvertisementComponent } from './advertisement/advertisement.component';
import { ShopComponent } from './shop/shop.component';
import { MerchantComponent } from './merchant/merchant.component';
import { ProductComponent } from './product/product.component';
import { CategoryComponent } from './category/category.component';
import { SuperCategoryComponent } from './super-category/super-category.component';


@NgModule({
  // tslint:disable-next-line: max-line-length
  declarations: [AdminComponent, CountryComponent, StateComponent, CityComponent, AreaComponent, BlockComponent,
    FloorComponent, JobberComponent, BookingComponent, SubAdminComponent, HouseComponent, CustomerInvoiceComponent,
    JobberBillingComponent, UserComponent, ApartmentBillingComponent, NormalVisitorComponent, RegularVisitorComponent,
    ApartmentComponent, SubscriptionComponent, DashboardComponent, SkillComponent,
    PaidBillComponent, DepartmentComponent, JobsComponent, CouponsComponent,
    MallComponent,
    PrivateoComponent,
    GovernmentoComponent,
    NoticeComponent,
    AssetComponent,
    CovidComponent,
    AdvertisementComponent,
    ShopComponent,
    MerchantComponent,
    ProductComponent,
    CategoryComponent,
    SuperCategoryComponent],
  imports: [
    CommonModule,
    AdminRoutingModule,
    DataTablesModule,
    FormsModule,
    ReactiveFormsModule,
    BsDatepickerModule.forRoot(),
    CKEditorModule,
    NgxPaginationModule
  ],
  providers: [
    DatePipe,
  ]
})
export class AdminModule { }
