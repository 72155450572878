import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
declare var $: any;
@Component({
  selector: 'app-admin',
  templateUrl: './admin.component.html',
  styleUrls: ['./admin.component.css']
})
export class AdminComponent implements OnInit {
  admin_fname = (JSON.parse(sessionStorage.getItem('Admin'))).fname;
  admin_mname = (JSON.parse(sessionStorage.getItem('Admin'))).mname;
  admin_lname = (JSON.parse(sessionStorage.getItem('Admin'))).lname;
  constructor(private router: Router) { }

  ngOnInit() {
     this.sideBarToggle();
    //  $(document).click(function (e) {
    //   if (!$(e.target).is('a')) {
    //     $('.collapse').collapse('hide');
    //   }
    // });
  }
  sideBarToggle() {
    // tslint:disable-next-line: quotemark
    $("#sidebarToggle, #sidebarToggleTop").on('click', function (e) {
      // tslint:disable-next-line: quotemark
      $("body").toggleClass("sidebar-toggled");
      // tslint:disable-next-line: quotemark
      $(".sidebar").toggleClass("toggled");
      // tslint:disable-next-line: quotemark
      if ($(".sidebar").hasClass("toggled")) {
        $('.sidebar .collapse').collapse('hide');
      }
    });
  }
  closeToggle() {
    $('.collapse').collapse('hide');
  }
  logout() {
    sessionStorage.clear();
    this.router.navigateByUrl('/login');
  }
}
