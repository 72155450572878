import { environment } from './../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
@Injectable({
  providedIn: 'root'
})
export class CountryService {
  serverUrl = environment.baseUrl;
  constructor(
    private http: HttpClient
  ) { }
  getAllCountry(): Observable<any[]> {
    const url = 'location/allCountry';
    return this.http.get<any[]>(environment.baseUrl + '' + url);
  }
  getActiveCountry() {
    const url = 'location/activeCountry';
    return this.http.get<any[]>(environment.baseUrl + '' + url);
  }
  activeCountry(data: any) {
    const url = 'location/updateCountry/';
    return this.http.put(environment.baseUrl + '' + url + '' + data.country_id, data);
  }
}
