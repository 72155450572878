import { StateService } from './../../services/state.service';
import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { Subject } from 'rxjs';
import { DataTableDirective } from 'angular-datatables';
import { CountryService } from 'src/app/services/country.service';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { ToastrService } from 'ngx-toastr';
declare var $: any;
@Component({
  selector: 'app-state',
  templateUrl: './state.component.html',
  styleUrls: ['./state.component.css']
})
export class StateComponent implements OnInit, OnDestroy {
  @ViewChild(DataTableDirective, { static: false })
  dtElement: DataTableDirective;
  // tslint:disable-next-line: no-inferrable-types
  isDtInitialized: boolean = false;
  Country: any[];
  State: any[];
  dtOptions: DataTables.Settings = {};
  dtTrigger: Subject<any> = new Subject();
  ngOnDestroy(): void {
    this.dtTrigger.unsubscribe();
  }
  constructor(
    private service: StateService,
    private countryService: CountryService,
    private loader: NgxUiLoaderService,
    private tosterService: ToastrService,
  ) { }

  ngOnInit() {
    this.dtOptions = {
      pagingType: 'full_numbers',
      pageLength: 10,
      processing: true,
      stateSave: true
    };
    this.allState();
    this.allActiveCountry();
  }
  allState() {
    this.service.getStateById(77).subscribe((res: any) => {
      this.State = res;
      if (this.isDtInitialized) {
        this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
          dtInstance.destroy();
          this.dtTrigger.next();
        });
      } else {
        this.isDtInitialized = true;
        this.dtTrigger.next();
      }
    });
  }
  activeState(data: any) {
    const editdata = { state_id: data.state_id, active: 'Yes' };
    this.service.activeState(editdata).subscribe((res: any) => {
      this.allStateByCountry(data.country_id);
    });
  }
  deActiveState(data: any) {
    const editdata = { state_id: data.state_id, active: 'No' };
    this.service.activeState(editdata).subscribe((res: any) => {
      this.allStateByCountry(data.country_id);
    });
  }
  allActiveCountry() {
    this.countryService.getActiveCountry().subscribe((res: any) => {
      this.Country = res;
    });
  }
  allStateByCountry(cID: any) {
    if (cID === 'Select Country') {
      alert('Select Country First');
    } else {
      this.loader.start();
      this.service.getStateById(cID).subscribe((res: any) => {
        this.State = res;
        this.loader.stop();
        if (this.isDtInitialized) {
          this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
            dtInstance.destroy();
            this.dtTrigger.next();
          });
        } else {
          this.isDtInitialized = true;
          this.dtTrigger.next();
        }
      }, err => {
        this.loader.stop();
        this.tosterService.error(err.statusText);
      });
    }
  }
}
