import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment.prod';
import { FormGroup,FormControl } from '@angular/forms';
import {AddProductRequest} from 'src/app/admin/product/product.component';
@Injectable({
  providedIn: 'root'
})
export class MerchantService {
  constructor(private http:HttpClient) { }
  SuperCategory(merchant_shop_category_id:any){
    const url='market/getMerchantSuperCategory/';
    return this.http.get<any[]>(environment.localMerchantUrl+''+url+merchant_shop_category_id);
  }
  ProductList(product_category_id:number){
    const url='market/getProduct/';
    return this.http.get<any[]>(environment.localMerchantUrl+''+url+product_category_id);
  }
  AddProduct(data:AddProductRequest){
    const url='market/addProduct';
    console.log("Inside Service")
    console.log(data)
    return this.http.post<AddProductRequest>(environment.localMerchantUrl+''+url,data);
  }
  categoryList(){
    const url='market/getProductCategoryList';
    return this.http.get<any[]>(environment.localMerchantUrl+''+url);
  }
  getShopCategoryData(){
    const url = 'market/getMerchantCategoryForWeb';
    return this.http.get<any[]>(environment.localMerchantUrl + '' + url);
  } 
  getShopList(merchant_shop_category_id:number, city_id:number){
    const url = 'merchant/getMerchantShop/';
    return this.http.get<any[]>(environment.localMerchantUrl+''+url+city_id+'/'+merchant_shop_category_id);
  }
  GetShopList(city_id:number,merchant_shop_category_id:number){
    const url = 'merchant/getMerchantShop/';
    return this.http.get<any[]>(environment.localMerchantUrl+''+url+city_id+'/'+merchant_shop_category_id);    
  }
  addShop(data:FormGroup){
    const url='/merchant/addMerchantShop';
    return this.http.post<any[]>(environment.localMerchantUrl+''+url,data);
  }
  getMerchantShopCategory(){
   const  url="/merchant/getMerchantShopCategory";
   return this.http.get<any[]>(environment.localMerchantUrl + '' + url);
  }
  getProductCategoryList(){
    const url='market/getProductCategoryList';
    return this.http.get<any[]>(environment.localMerchantUrl + '' + url);
  }
  adminAddMerchant(data:FormGroup){
    const url = 'merchant/addMerchant';
    return this.http.post<any[]>(environment.localMerchantUrl+ '' + url,data);
  }
  checkEmail(type:string,value:string){
    const url='merchant/checkDuplicate/';
    return this.http.get<any[]>(environment.localMerchantUrl+''+url+type+'/'+value);
  }
  getMerchant(shop_id:number){
    const url='merchant/getMerchantDetailsByShop/';
    return this.http.get<any>(environment.localMerchantUrl+''+url+shop_id);
  }
  addCategory(data:FormGroup){
    const url='market/addProductCategory';
    return this.http.post<any>(environment.localMerchantUrl+''+url,data);
  }
  product_category(merchant_super_id:number){
    const url='market/getProductCategory/';
    return this.http.get<any>(environment.localMerchantUrl+''+url+merchant_super_id);
  }
  add_super_category(data:FormGroup){
    const url='market/addMerchantSuperCategory';
    return this.http.post<any>(environment.localMerchantUrl+''+url,data);
  }
}
