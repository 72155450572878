import { HouseService } from './../../services/house.service';
import { FloorService } from './../../services/floor.service';
import { BlockService } from './../../services/block.service';
import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { Subject } from 'rxjs';
import { DataTableDirective } from 'angular-datatables';
import { FormGroup, FormBuilder, FormArray, AbstractControl, Validators } from '@angular/forms';
import { AreaService } from './../../services/area.service';
import { StateService } from './../../services/state.service';
import { CountryService } from './../../services/country.service';
import { CityService } from './../../services/city.service';
import { ApartmentService } from 'src/app/services/apartment.service';
import { ToastrService } from 'ngx-toastr';
import { NgxUiLoaderService } from 'ngx-ui-loader';
declare var $: any;
declare var jQuery: any;
@Component({
  selector: 'app-house',
  templateUrl: './house.component.html',
  styleUrls: ['./house.component.css']
})
export class HouseComponent implements OnInit, OnDestroy {
  houseForm: FormGroup;
  @ViewChild(DataTableDirective, { static: false })
  dtElement: DataTableDirective;
  dtInstance: Promise<DataTables.Api>;
  // tslint:disable-next-line: no-inferrable-types
  isDtInitialized: boolean = false;
  City: any[];
  Acity: any[];
  Country: any[];
  State: any[];
  Area: any[];
  appOffice: any[];
  Block: any[];
  Floor: any[];
  House: any[];
  currentType = 0;
  dtOptions: DataTables.Settings = {};
  dtTrigger: Subject<any> = new Subject();
  ngOnDestroy(): void {
    this.dtTrigger.unsubscribe();
  }

  constructor(
    private tosterService: ToastrService,
    private floorService: FloorService,
    private service: HouseService,
    private fb: FormBuilder,
    private cityService: CityService,
    private countryService: CountryService,
    private stateService: StateService,
    private areaService: AreaService,
    private apartService: ApartmentService,
    private blockService: BlockService,
    private loader: NgxUiLoaderService,
  ) {
    this.houseForm = this.fb.group({
      app_id: ['0'],
      blk_id: ['0'],
      flr_id: ['0'],
      house: this.fb.array([
        this.addHouseFormgroup()
      ])
    });
  }
  ngOnInit() {
    this.dtOptions = {
      pagingType: 'full_numbers',
      pageLength: 10,
      processing: true,
      stateSave: true
    };
    this.getAllActiveCountry();
    this.getAllActiveCity();
  }
  onSubmit(formdata: any) {
    for (let index in formdata.house) {
      if (formdata.house[index].active === true) {
        formdata.house[index].active = 'Yes';
      } else {
        formdata.house[index].active = 'No';
      }
    }
    console.log(formdata);
    this.loader.start();
    this.service.addHouse(formdata).subscribe((res: any) => {
      if (res.status === true) {
        this.loader.stop();
        this.tosterService.success(res.message);
        this.houseForm.reset();
        (this.houseForm.controls['house'] as FormArray).clear();
        this.addHouse();
        // tslint:disable-next-line:quotemark
        jQuery("#exampleModalCenter").modal("hide");
      } else {
        this.loader.stop();
        this.tosterService.success(res.message);
        this.houseForm.reset();
        (this.houseForm.controls['house'] as FormArray).clear();
        this.addHouse();
        // tslint:disable-next-line:quotemark
        jQuery("#exampleModalCenter").modal("hide");
      }
    },
      error => {
        console.log(error);
        this.tosterService.error(error);
      });
  }
  addHouseFormgroup(): FormGroup {
    return this.fb.group({
      house_no: [''],
      active: [false]
    });
  }
  getHouseFormControls(): AbstractControl[] {
    return (this.houseForm.get('house') as FormArray).controls;
  }
  addHouse(): void {
    (this.houseForm.get('house') as FormArray).push(this.addHouseFormgroup());
  }
  removeHouse(index) {
    (this.houseForm.get('house') as FormArray).removeAt(index);
  }
  getAllActiveCountry() {
    this.loader.start();
    this.countryService.getActiveCountry().subscribe(res => {
      this.Country = res;
      this.loader.stop();
    }, err => {
      this.loader.stop();
      this.tosterService.error('Not find country');
    });
  }
  getActiveStateById(Cid: any) {
    this.loader.start();
    this.stateService.getActiveStateById(Cid).subscribe(res => {
      this.State = res;
      this.City = null;
      this.Area = null;
      this.appOffice = null;
      this.Block = null;
      this.Floor = null;
      this.loader.stop();
    }, err => {
      this.loader.stop();
      this.tosterService.error('Not find state');
    });
  }
  getActiveCityById(Sid: any) {
    this.loader.start();
    this.cityService.getActiveCityById(Sid).subscribe(res => {
      this.City = res;
      this.Area = null;
      this.appOffice = null;
      this.Block = null;
      this.Floor = null;
      this.loader.stop();
    }, err => {
      this.loader.stop();
      this.tosterService.error('Not find city');
    });
  }
  getActiveAreaById(CtId: any) {
    this.loader.start();
    this.areaService.getActiveAreaByID(CtId).subscribe(res => {
      this.Area = res;
      this.appOffice = null;
      this.Block = null;
      this.Floor = null;
      this.loader.stop();
    }, err => {
      this.loader.stop();
      this.tosterService.error('Not find area');
    });
  }
  getActiveAppOffById(type: string, id: any) {
    this.loader.start();
    console.log(type)
    if (type === 'Apartment') {
      this.apartService.getActiveApartmentByArea(id).subscribe((res: any) => {
        this.appOffice = res;
        this.Block = null;
        this.Floor = null;
        this.loader.stop();
      }, err => {
        this.loader.stop();
        this.tosterService.error(err.message);
      });
    } 
    else if(type === 'Government Office') {
      this.apartService.getActiveOfficeByArea(id,'GO').subscribe((res: any) => {
        this.appOffice = res;
        this.Block = null;
        this.Floor = null;
        this.loader.stop();
      }, err => {
        this.loader.stop();
        this.tosterService.error(err.message);
      });
    }else if(type === 'Private Office') {
      this.apartService.getActiveOfficeByArea(id,'PO').subscribe((res: any) => {
        this.appOffice = res;
        this.Block = null;
        this.Floor = null;
        this.loader.stop();
      }, err => {
        this.loader.stop();
        this.tosterService.error(err.message);
      });
    }else if(type === 'Mall') {
      this.apartService.getActiveOfficeByArea(id,'M').subscribe((res: any) => {
        this.appOffice = res;
        this.Block = null;
        this.Floor = null;
        this.loader.stop();
      }, err => {
        this.loader.stop();
        this.tosterService.error(err.message);
      });}else {
      this.loader.stop();
      this.tosterService.warning('Please choose proper type !');
    }
  }
  getActiveBlockById(appID: any) {
    this.loader.start();
    this.blockService.getActiveBlockByAppOff(appID).subscribe((res: any) => {
      this.Block = res;
      this.Floor = null;
      this.loader.stop();
    }, err => {
      this.loader.stop();
      this.tosterService.error('Not find block');
    });
  }
  getActiveFloorById(blkID: any) {
    this.loader.start();
    this.floorService.getActiveFloorByBlock(blkID).subscribe((res: any) => {
      this.Floor = res;
      this.loader.stop();
    }, err => {
      this.loader.stop();
      this.tosterService.error('Not find floor');
    });
  }
  getAllActiveCity() {
    this.loader.start();
    this.cityService.getAllActiveCity().subscribe((res: any) => {
      this.Acity = res;
      this.loader.stop();
    }, err => {
      this.loader.stop();
      this.tosterService.error('Not find active city');
    });
  }
  getHouseByFloor(FId: any) {
    if (FId === '--Floor--') {
      alert('Please select floor first');
    } else {
      this.loader.start();
      this.service.getHouseByFloor(FId).subscribe(res => {
        this.House = res;
        this.loader.stop();
        if (this.isDtInitialized) {
          this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
            dtInstance.destroy();
            this.dtTrigger.next();
          });
        } else {
          this.isDtInitialized = true;
          this.dtTrigger.next();
        }
      }, err => {
        this.loader.stop();
        this.tosterService.error('Not find floor');
      });
    }

  }
  activeHouse(data: any) {
    const editdata = { id: data.id, active: 'Yes' };
    this.service.activeHouse(editdata).subscribe(res => {
      this.getHouseByFloor(data.flr_id);
    });
  }
  deActiveHouse(data: any) {
    const editdata = { id: data.id, active: 'No' };
    this.service.activeHouse(editdata).subscribe(res => {
      this.getHouseByFloor(data.flr_id);
    });
  }
  onAreaChange() {
    this.currentType = 0;
  }
}
