import { UrlSegment } from '@angular/router';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from './../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class DepartmentService {
  serverUrl = environment.baseUrl1;
  httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json'})
  };
  constructor(private http: HttpClient) { }

  getAlldepartment(): Observable<any[]> {
    const url = 'career/department/get/department';
    return this.http.get<any[]>(environment.baseUrl1 + '' + url);
    };

    addDept(data: any) {
      const url = 'career/department/add/addDepartment';
      console.log(data);
      return this.http.post(environment.baseUrl1 + '' + url, data, this.httpOptions);
    };

    getDeptByID(data: number){
      const url = 'career/department/get/departmentById/';
      return this.http.get<any[]>(environment.baseUrl1 + '' + url + data);
    };

    updateDepartment(data: any, id: number){
      const url = 'career/department/editDepartment/';
      return this.http.put(environment.baseUrl1 + url + id, data);
    };
}
