import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { environment } from './../../environments/environment';
import { catchError, tap } from 'rxjs/operators';
import { NgxUiLoaderService } from 'ngx-ui-loader';
@Injectable({
  providedIn: 'root'
})
export class ApartmentService {
  httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' })
  };
  constructor(
    private http: HttpClient ,
    private loader: NgxUiLoaderService
    ) { }

  addApartment(data: any) {
    const url = 'appoff/addAppOfc';
    return this.http.post(environment.baseUrl + '' + url, data, this.httpOptions);
  }
  //http://dev01.ohhpro.com/ohhpro_admin/admin/appoff/addAppOfc
  //http://dev01.ohhpro.com/ohhpro_admin/admin/appoff/addAppOfc
  getPinCode(Aid: any) {
    const url = 'location/pinbyid/';
    return this.http.get(environment.baseUrl + '' + url + Aid);
  }
  activeApartment(data: any) {
    const url = 'appoff/updateAppOffActive/';
    return this.http.put(environment.baseUrl + '' + url + data.app_id, data);
  }
  getApartByAreaId(areaId: any) {
    const url = 'appoff/appbyarea/';
    return this.http.get(environment.baseUrl + '' + url + areaId);
  }
  //changes done by Ashis 4 ...
  getOfficeByAreaId(areaId: number,app_type:any) {
    const url = 'appoff/officebyarea/';
    return this.http.get(environment.baseUrl + '' + url + areaId + '/' + app_type);
  }
  getMallByAreaId(areaId: number ) {
    const url = 'appoff/activeMall/';
    return this.http.get(environment.baseUrl + '' + url + areaId );
  }
 

  getActiveApartmentByArea(aID: number) {
    const url = 'appoff/activeApartment/';
    return this.http.get<any[]>(environment.baseUrl + '' + url + aID);
  }
  // changes by ashis 3
  getActiveOfficeByArea(aID: number, app_type:any) {
    const url = 'appoff/activeOffice/';
    return this.http.get<any[]>(environment.baseUrl + '' + url + aID + '/'+app_type);
  }
  getAllActiveApartment() {
    const url = 'appoff/allActiveApartment';
    return this.http.get<any[]>(environment.baseUrl + '' + url);
  }
   //changes by Ashis 2
  getAllActiveOffice(app_type: any) {
    const url = 'appoff/allActiveOffice';
    return this.http.get<any>(environment.baseUrl + '' + url +'/' + app_type);
  }
  updateAppSubscription(appID: number, data: any) {
    const url = 'appoff/updateApartmetSub/';
    return this.http.put(environment.baseUrl + url + appID, data);
  }
  // ????? ashis
  updateOffSubscription(offID: number, data: any) {
    const url = 'appoff/updatOfficeSub/';
    return this.http.put(environment.baseUrl + url + offID, data);
  }
  getAppbyFilter(areaID: number, sub: number) {
    const url = 'appoff/ApartmentByArea/';
    return this.http.get<any>(environment.baseUrl + url + areaID + '/' + sub);
  }
  //changes by Ashis 1
  getOffByFilter(areaID: number, sub: number , app_type:any) {
    const url = 'appoff/OfficeByArea/';
    return this.http.get<any>(environment.baseUrl + url + areaID + '/' + sub + '/'+ app_type);
  }
  getMallByFilter(areaID: number, sub: number) {
    const url = 'appoff/MallByArea/';
    return this.http.get<any>(environment.baseUrl + url + areaID + '/' + sub);
  }
  getActiveSubscription() {
    const url = 'appoff/getActiveSubscription';
    return this.http.get<any[]>(environment.baseUrl + url);
  }



  activateAdmin(active_status: any, app_id: number) {
    const url = 'appoff/updateAppOffAdminActive/';
    console.log('hi ' + active_status);
    return this.http.put(environment.baseUrl + url + app_id, active_status);
  }
  //Added by ashis
  checkDuplicateAppEmail(data: any) {
    const url = 'appoff/checkDuplicateEmail';
    return this.http.put(environment.baseUrl + url, data).pipe(tap(res => {
      this.loader.stop();
    }, err => {
      this.loader.stop();
    }));
  }
  sendEmailOTP(email: string) {
    const url = 'lr/emailotp';
    let mail = { 'email': email };
    return this.http.post(environment.baseUrl + '' + url, mail).pipe(tap(res => {
      // this.loader.stop();
    }, err => {
      console.log(err);
      // this.loader.stop();
    }));
  }
  veryfyMailOTP(admin: number) {
    const url = 'appoff/verifyEmail/';
    return this.http.put(environment.baseUrl + '' + url + admin, '');
  }
// get Api for topic name :
// http://dev01.ohhpro.com:9080/ohhpro/rest/fcm/get/ohhproTopics/dev
  getTopicNames() {
    const url = 'fcm/get/ohhproTopics/';
    return this.http.get<any[]>(environment.baseUrl1 + url+environment.env);
  }


// send blast messages
// http://dev01.ohhpro.com:9080/ohhpro/rest/fcm/send/sendNotificationToOhhpro
sendMessage(data: any) {
  const url = 'fcm/send/sendNotificationToOhhpro';
  return this.http.post(environment.baseUrl1 + '' + url, data, this.httpOptions);
}
UploadNotification(data:any){
  // var fd = new FormData();
  // fd.append('file', data);
  return this.http.post<any>(environment.baseUrl1+"schedule/notification/addNotification",data);
}
getAllNotification(){
  return this.http.get<any>(environment.RazorPay1+'notification/getAllNotification');
}
UpdateNotification(data:any){
  return this.http.post<any>(environment.baseUrl1+'schedule/notification/edit/addNotification',data);
}
getAllAds(){
  return this.http.get<any>(environment.baseUrl1+'advertisement/getAdvertisementDetailsList');
}

addAds(data:any){
  return this.http.post<any>(environment.baseUrl1+"advertisement/addAdvertisementDetails",data);
}

getAllFeature(){
  return this.http.get<any>(environment.baseUrl1+'advertisement/getOhhproFeatureList');
}

addInstantNotification(data:any){
  return this.http.post<any>(environment.baseUrl1+"advertisement/postInstantNotification",data);
}

}
