import { BookingService } from './../../services/booking.service';
import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { Subject } from 'rxjs';
import { DataTableDirective } from 'angular-datatables';
import { FormGroup, FormBuilder, FormArray, AbstractControl, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { BsDatepickerConfig } from 'ngx-bootstrap/datepicker';
import { StateService } from 'src/app/services/state.service';
import { AreaService } from './../../services/area.service';
import { CityService } from './../../services/city.service';
import { NgxUiLoaderService } from 'ngx-ui-loader';

@Component({
  selector: 'app-booking',
  templateUrl: './booking.component.html',
  styleUrls: ['./booking.component.css']
})
export class BookingComponent implements OnInit, OnDestroy {
  datePickerConfig: Partial<BsDatepickerConfig>;
  bookingForm: FormGroup;
  Booking: any[];
  State: any[];
  City: any[];
  Area: any[];
  // booking entity
  booking_id: any;
  cust_id: any;
  cust_name: any;
  jobber_name: any;
  photo = 'user.png';
  serv_address: any;
  serv_house_no: any;
  serv_state: any;
  serv_city: any;
  serv_area: any;
  serv_pin: any;
  serv_type: any;
  no_of_hours: any;
  serv_start_date: any;
  serv_end_date: any;
  assigned_jobber: any;
  serv_rate_hr: any;
  jobber_rate_hr: any;
  status: any;
  work_status: any;
  is_approved: any;
  real_start_time: any;
  real_end_time: any;
  @ViewChild(DataTableDirective, { static: false })
  dtElement: DataTableDirective;
  // tslint:disable-next-line: no-inferrable-types
  isDtInitialized: boolean = false;
  dtOptions: DataTables.Settings = {};
  dtTrigger: Subject<any> = new Subject();
  ngOnDestroy(): void {
    this.dtTrigger.unsubscribe();
  }
  constructor(
    private service: BookingService,
    private stateService: StateService,
    private cityService: CityService,
    private areaService: AreaService,
    private tosterService: ToastrService,
    private fb: FormBuilder,
    private loader: NgxUiLoaderService,
  ) {
    this.bookingForm = this.fb.group({
    });
    this.datePickerConfig = Object.assign({},
      {
        containerClass: 'theme-dark-blue',
        showWeekNumbers: false,
        dateInputFormat: 'DD-MM-YYYY',
      });
  }

  ngOnInit() {
    this.dtOptions = {
      pagingType: 'full_numbers',
      pageLength: 10,
      processing: true,
      stateSave: true
    };
    this.getAllBookings();
    this.getActiveState();
  }
  getAllBookings() {
    this.service.getBookings().subscribe((res: any) => {
      this.Booking = res;
      if (this.isDtInitialized) {
        this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
          dtInstance.destroy();
          this.dtTrigger.next();
        });
      } else {
        this.isDtInitialized = true;
        this.dtTrigger.next();
      }
    });
  }
  getBookingByID(Bid: any) {
    return this.service.getBookingById(Bid).subscribe((res: any) => {
      this.booking_id = res.booking_id;
      this.cust_id = res.cust_id;
      this.cust_name = res.cust_name;
      this.jobber_name = res.jobber_name;
      this.photo = res.photo;
      this.serv_address = res.serv_address;
      this.serv_house_no = res.serv_house_no;
      this.serv_state = res.serv_state;
      this.serv_city = res.serv_city;
      this.serv_area = res.serv_area;
      this.serv_pin = res.serv_pin;
      this.serv_type = res.serv_type;
      this.no_of_hours = res.no_of_hours;
      this.serv_start_date = res.serv_start_date;
      this.serv_end_date = res.serv_end_date;
      this.assigned_jobber = res.assigned_jobber;
      this.serv_rate_hr = res.serv_rate_hr;
      this.jobber_rate_hr = res.jobber_rate_hr;
      this.status = res.status;
      this.work_status = res.work_status;
      this.is_approved = res.is_approved;
      this.real_start_time = res.real_start_time;
      this.real_end_time = res.real_end_time;
    });
  }
  getActiveState() {
    this.stateService.getActiveState().subscribe(res => {
      this.State = res;
    }, err => {
      this.tosterService.error(err.message);
    });
  }
  getActiveCityByState(sID: number) {
    this.loader.start();
    this.cityService.getActiveCityById(sID).subscribe(res => {
      this.City = res;
      this.loader.stop();
    }, err => {
      this.loader.stop();
      this.tosterService.error(err.message);
    });
  }
  getActiveAreaByCity(cID: number) {
    this.loader.start();
    this.areaService.getActiveAreaByID(cID).subscribe(res => {
      this.Area = res;
      this.loader.stop();
    }, err => {
      this.loader.stop();
      this.tosterService.error(err.message);
    });
  }
  onFilter(sid: number, cid: number, aid: number, fdate: Date, tdate: Date) {
    this.loader.start();
    this.service.getBookingByFilter(sid, cid, aid, fdate, tdate).subscribe(res => {
      this.Booking = res;
      if (this.isDtInitialized) {
        this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
          dtInstance.destroy();
          this.dtTrigger.next();
        });
      } else {
        this.isDtInitialized = true;
        this.dtTrigger.next();
      }
      this.loader.stop();
    }, err => {
      this.loader.stop();
      this.tosterService.error(err.message);
    });
    console.log(sid + ' =>' + cid + '=>' + aid + '=>' + fdate + '=>' + tdate);
  }
}
