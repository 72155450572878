import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { environment } from './../../environments/environment';
import { catchError } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class BillingService {
  httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' })
  };
  constructor(private http: HttpClient) { }

  getTotalBill() {
    const url = 'jobber/alljobberbill';
    return this.http.get<any[]>(environment.baseUrl  + url);
  }
  getBillDetails(jobberID: number) {
    const url = 'jobber/unpaidJobber/';
    return this.http.get<any[]>(environment.baseUrl  + url + jobberID );
  }
  getJobberBasicDetails(jobberID: number) {
    const  url = 'jobber/jobberBasicDetails/';
    return this.http.get<any[]>(environment.baseUrl  + url + jobberID );
  }
  getFilterBill(state, city, area) {
    const url = 'jobber/filterjobberbill/';
    return this.http.get<any[]>(environment.baseUrl + url + state + '/' + city + '/' + area);
  }
  getPaidBill() {
    const url = 'jobber/allpaidjobberbill';
    return this.http.get<any[]>(environment.baseUrl + url);
  }
  filterPaidBill(state, city, area) {
    const url = 'jobber/filterpaidjobberbill/';
    return this.http.get<any[]>(environment.baseUrl + url + state + '/' + city + '/' + area);
  }
  payToJobber(bill_id) {
    const url = 'routes/transferViaPayment/payments/';
    return this.http.post(environment.razorpayUrl + url + bill_id + '/' + 'transfers', '');
  }
}
