import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { BehaviorSubject, forkJoin, of, Observable, throwError } from 'rxjs';
import { environment } from './../../environments/environment';
import { tap, catchError } from 'rxjs/operators';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { ToastrService } from 'ngx-toastr';

@Injectable({
  providedIn: 'root'
})
export class VisitorService {

  httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' })
  };
  constructor(
    private http: HttpClient,
    private loader: NgxUiLoaderService,
    private tosterService: ToastrService,
  ) { }
  getNormalVisitor() {
    const url = 'visitor/newvisitor';
    return this.http.get(environment.baseUrl + '' + url);
  }
  getRegularVisitor() {
    const url = 'visitor/visitorByRegular';
    return this.http.get(environment.baseUrl + '' + url);
  }
  getNormalVisitorBy(data: any) {
    const url = 'visitor/visitorFilter/';
    return this.http.get(environment.baseUrl + url + data.city_id + '/'
      + data.app_id + '/' + data.blk_id + '/' + data.from + '/' + data.to);
  }
  getRegularVisitorByFilter(data: any) {
    const url = 'report/regularvisitor/';
    return this.http.get<any[]>(environment.baseUrl + url + data.state_id + '/' + data.city_id
      + '/' + data.area + '/' + data.app_type + '/' + data.app_id + '/' + data.from + '/' + data.to);
  }
}
