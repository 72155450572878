import { AreaService } from './../../services/area.service';
import { CityService } from 'src/app/services/city.service';
import { StateService } from './../../services/state.service';
import { BillingService } from './../../services/billing.service';
import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { Subject } from 'rxjs';
import { DataTableDirective } from 'angular-datatables';
import { FormGroup, FormBuilder, FormArray, AbstractControl, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { JsonPipe } from '@angular/common';
@Component({
  selector: 'app-jobber-billing',
  templateUrl: './jobber-billing.component.html',
  styleUrls: ['./jobber-billing.component.css']
})
export class JobberBillingComponent implements OnInit, OnDestroy {
  billingForm: FormGroup;
  Bill: any[];
  splitBill: any[];
  // filter
  StateFilter: any[];
  CityFilter: any[];
  AreaFilter: any[];

  // jobber basic entity
  jobber_id: number;
  fname: string;
  mname: string;
  lname: string;
  house_no: string;
  street: string;
  area_name: string;
  city_name: string;
  state_name: string;
  country_name: string;
  pin: number;
  dob: string;
  email: string;
  phone: any;
  photo: string;

  @ViewChild(DataTableDirective, { static: false })
  dtElement: DataTableDirective;
  // tslint:disable-next-line: no-inferrable-types
  isDtInitialized: boolean = false;
  dtOptions: DataTables.Settings = {};
  dtTrigger: Subject<any> = new Subject();
  ngOnDestroy(): void {
    this.dtTrigger.unsubscribe();
  }
  constructor(
    private service: BillingService,
    private tosterService: ToastrService,
    private fb: FormBuilder,
    private stateService: StateService,
    private cityService: CityService,
    private areaService: AreaService,
    private loader: NgxUiLoaderService,
  ) {
    this.billingForm = this.fb.group({
      bill_number: [''],
      jobber_name: [''],
      jobber_net_billing: [''],
      bill_due_date: ['']
    });
  }

  ngOnInit() {
    this.dtOptions = {
      pagingType: 'full_numbers',
      pageLength: 10,
      processing: true,
      stateSave: true
    };
    this.getAllJobberBill();
    this.getAllActiveState();
  }
  getAllJobberBill() {
    this.service.getTotalBill().subscribe((res: any) => {
      this.Bill = res;
      if (this.isDtInitialized) {
        this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
          dtInstance.destroy();
          this.dtTrigger.next();
        });
      } else {
        this.isDtInitialized = true;
        this.dtTrigger.next();
      }
    });
  }
  getBillDetails(jobberID: number) {
    this.service.getBillDetails(jobberID).subscribe((res: any) => {
      console.log('split bill ==> ' + JSON.stringify(res));
      this.splitBill = res;
    });
  }

  // paymentByBillId(bID: number) {
  //   this.service.getBillById(bID).subscribe((res: any) => {
  //     this.billingForm.patchValue({
  //       bill_number: res.bill_number,
  //       jobber_name: res.jobber_name,
  //       jobber_net_billing: res.jobber_net_billing,
  //       bill_due_date: res.bill_due_date
  //     });
  //   });
  // }

  getJobberBasic(jobberID: number) {
    this.service.getJobberBasicDetails(jobberID).subscribe((res: any) => {
      this.jobber_id = res.jobber_id;
      this.fname = res.fname;
      this.mname = res.mname;
      this.lname = res.lname;
      this.house_no = res.house_no;
      this.street = res.street;
      this.area_name = res.area_name;
      this.city_name = res.city_name;
      this.state_name = res.state_name;
      this.country_name = res.country_name;
      this.pin = res.pin;
      this.dob = res.dob;
      this.email = res.email;
      this.phone = res.phone;
      this.photo = res.photo;
    });
  }
  // filteration
  getAllActiveState() {
    this.stateService.getActiveState().subscribe(res => {
      this.StateFilter = res;
    }, err => {
      this.tosterService.error(err.message);
    });
  }
  getFactiveCity(s_id) {
    this.loader.start();
    this.AreaFilter = null;
    this.cityService.getActiveCityById(s_id).subscribe(res => {
      this.loader.stop();
      this.CityFilter = res;
    }, err => {
      this.loader.stop();
      this.tosterService.error(err.message);
    });
  }
  getFactiveArea(c_id) {
    this.loader.start();
    this.areaService.getActiveAreaByID(c_id).subscribe(res => {
      this.loader.stop();
      this.AreaFilter = res;
    }, err => {
      this.loader.stop();
      this.tosterService.error(err.message);
    });
  }
  getFilterBill(state, city, area) {
    if (state !== '' && city !== '' && area !== '') {
      this.loader.start();
      this.service.getFilterBill(state, city, area).subscribe(res => {
        if (this.isDtInitialized) {
          this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
            dtInstance.destroy();
            this.dtTrigger.next();
          });
        } else {
          this.isDtInitialized = true;
          this.dtTrigger.next();
        }
        this.Bill = res;
        this.loader.stop();
      }, err => {
        this.loader.stop();
        this.tosterService.error(err.message);
      });
    } else {
      this.tosterService.info('choose all fields');
    }
  }
  payToJobber(bill_id: any) {
    this.loader.start();
    this.service.payToJobber(bill_id).subscribe((res: any) => {
      this.loader.stop();
      this.tosterService.success('Payment Successful !');
    }, err => {
      this.loader.stop();
      this.tosterService.error(err.message);
    });
  }
}
