import { Component, OnInit } from '@angular/core';
import { BlockService } from './../../services/block.service';
import { ApartmentService } from './../../services/apartment.service';
import { AreaService } from './../../services/area.service';
import { StateService } from './../../services/state.service';
import { CountryService } from './../../services/country.service';
import { CityService } from './../../services/city.service';
import { OnDestroy, ViewChild } from '@angular/core';
import { Subject } from 'rxjs';
import { DataTableDirective } from 'angular-datatables';
import { FormGroup, FormBuilder, FormArray, AbstractControl, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { AssetServiceService } from 'src/app/services/asset-service.service';
declare var jQuery: any;

@Component({
  selector: 'app-asset',
  templateUrl: './asset.component.html',
  styleUrls: ['./asset.component.css']
})
export class AssetComponent implements OnInit, OnDestroy {
  assetForm: FormGroup;
  assetEditForm : FormGroup;
  @ViewChild(DataTableDirective, { static: false })
  dtElement: DataTableDirective;
  // tslint:disable-next-line: no-inferrable-types
  isDtInitialized: boolean = false;
  
  Assets: any[];
  
  dtOptions: DataTables.Settings = {};
  dtTrigger: Subject<any> = new Subject();
  d: Object;
  ngOnDestroy(): void {
    this.dtTrigger.unsubscribe();
  }
  constructor(
    private tosterService: ToastrService,
    private loader: NgxUiLoaderService,
    private service: AssetServiceService,
    private fb: FormBuilder,
    private fb1: FormBuilder,
   
  ) {
    
    this.assetForm = this.fb1.group({
      asset_name: ['']  // the form control name must be same with swagger body name . be carefull :)ashis
    });
    this.assetEditForm = this.fb.group({
      asset_name: [''] ,// the form control name must be same with swagger body name . be carefull :)ashis
      active : [''],
      asset_id:[0]
    });
  }
  
 
  ngOnInit() {
    this.dtOptions = {
      pagingType: 'full_numbers',
      pageLength: 10,
      processing: true,
      stateSave: true
    };
   
  }
  onSubmit(formdata: any) {
    console.log(formdata);
    
    console.log(formdata);
    this.loader.start();
    this.service.addAsset(formdata).subscribe((res: any) => {
      console.log(res);

      if (res.status === true) {
        this.loader.stop();
        this.tosterService.success(res.message);
        this.assetForm.reset();
        jQuery("#exampleModalCenter").modal("hide");
        this.showAllAssets();
      } else {
        this.loader.stop();
        this.tosterService.success(res.message);
        this.assetForm.reset();
        jQuery("#exampleModalCenter").modal("hide");
        this.showAllAssets(); // change it .. it should show the last asset added :)
      }
    },
      error => {
        console.log(error);
        this.showAllAssets();
        
      });
      
  }

  //update asset 
  updateAsset(formdata: any){
    console.log(formdata);
    
    console.log(formdata);
    this.loader.start();
    this.service.editAssetIndivisually(formdata).subscribe((res: any) => {
      console.log(res);

      if (res.status === true) {
        this.loader.stop();
        this.tosterService.success(res.message);
        this.assetForm.reset();
        jQuery("#exampleModalCenter").modal("hide");
        this.showAllAssets();
      } else {
        this.loader.stop();
        this.tosterService.success(res.message);
        this.assetForm.reset();
        jQuery("#exampleModalCenter").modal("hide");
        this.showAllAssets(); // change it .. it should show the last asset added :)
      }
    },
      error => {
        console.log(error);
        this.showAllAssets();
        
      });


  }
  // Show all assets function called here 
  
  showAllAssets() {
    
      this.loader.start();
      this.service.showAllAssetList().subscribe((res: any) => {
        this.Assets = res;
        console.log(res);
        this.loader.stop();
        if (this.isDtInitialized) {
          this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
            dtInstance.destroy();
            this.dtTrigger.next();
          });
        } else {
          this.isDtInitialized = true;
          this.dtTrigger.next();
        }
      }, err => {
        this.loader.stop();
        this.tosterService.error('Assets Not Found');
      });
    
  }
  // delete asset by asset id 
  deleteAssetbyAssetId1(asset_id: any){
    this.service.deleteAssetbyAssetId(asset_id).subscribe(
      data=>{
        this.d=data; 
        this.tosterService.success("Delete 1 asset");
        this.showAllAssets();
        
        console.log("delete return message : "+this.d);
  
    },
      error => {
        console.log(error);
        
      }
    )
  }
  //edit asset 
  patchAsset(a_id,a_name,a_active){
    

      this.assetEditForm.patchValue({
        asset_name: a_name,
        active :a_active,
        asset_id : a_id
        
      });
     
      

      
    }

  // Patch function for edit asset 
  partialUpdate(c,j){
    console.log(c);
    console.log(this.assetEditForm);
    console.log("c.category: "+c.category);
     this.assetEditForm.patchValue({
      city:c.city,
      
      area:c.area,
      
      coupon_code:c.coupon_code,
      coupon_desc:c.coupon_desc,
      coupon_id:c.coupon_id,
      coupon_pic:c.coupon_pic,
      vendor:c.vendor
      
      
     
     });
   }





  }

  // getBlockByAppId(appID: any) {
  //   if (appID == 0) {
  //     alert('Select ' + this.sType + ' First');
  //   } else {
  //     this.loader.start();
  //     this.service.getBlockById(appID).subscribe((res: any) => {
  //       this.Block = res;
  //       this.loader.stop();
  //       if (this.isDtInitialized) {
  //         this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
  //           dtInstance.destroy();
  //           this.dtTrigger.next();
  //         });
  //       } else {
  //         this.isDtInitialized = true;
  //         this.dtTrigger.next();
  //       }
  //     }, err => {
  //       this.loader.stop();
  //       this.tosterService.error('Block Not Found');
  //     });
  //   }
  // }
  // activeBlock(data: any) {
  //   const editdata = { blk_id: data.blk_id, active: 'Yes' };
  //   this.service.activeBlock(editdata).subscribe((res: any) => {
  //     this.getBlockByAppId(data.app_id);
  //   });
  // }
  // deActiveBlock(data: any) {
  //   const editdata = { blk_id: data.blk_id, active: 'No' };
  //   this.service.activeBlock(editdata).subscribe((res: any) => {
  //     this.getBlockByAppId(data.app_id);
  //   });
  // }
  // getAllActiveCountry() {
  //   this.countryService.getActiveCountry().subscribe(res => {
  //     this.Country = res;
  //   });
  // }
  // getActiveStateById(Cid: any) {
  //   this.loader.start();
  //   this.stateService.getActiveStateById(Cid).subscribe(res => {
  //     this.State = res;
  //     this.loader.stop();
  //   }, err => {
  //     this.loader.stop();
  //     this.tosterService.error('Unable to find State')
  //   });
  // }
  // getActiveCityById(Sid: any) {
  //   this.loader.start();
  //   this.cityService.getActiveCityById(Sid).subscribe(res => {
  //     this.City = res;
  //     this.loader.stop();
  //   }, err => {
  //     this.loader.stop();
  //     this.tosterService.error('Unable to find City');
  //   });
  // }
  // getActiveAreaById(CtId: any) {
  //   this.loader.start();
  //   this.areaService.getActiveAreaByID(CtId).subscribe(res => {
  //     this.Area = res;
  //     this.loader.stop();
  //   }, err => {
  //     this.loader.stop();
  //     this.tosterService.error('Unable to find Area');
  //   });
  // }
  // getActiveAppOffById(Aid: any, aoType: string) {
  //   this.sType = aoType;
  //   console.log(aoType);
  //   if (aoType == 'Apartment') {
  //     this.loader.start();
  //     this.apartService.getActiveApartmentByArea(Aid).subscribe((res: any) => {
  //       this.AppOff = res;
  //       this.loader.stop();
  //     }, err => {
  //       this.loader.stop();
  //       this.tosterService.error('Unable to find Apartment');
  //     });
  //   } 
  //   else if (aoType == 'Government Office') {
  //     this.loader.start();
  //     this.apartService.getActiveOfficeByArea(Aid, 'GO').subscribe((res: any) => {
  //       this.AppOff = res;
  //       this.loader.stop();
  //     }, err => {
  //       this.loader.stop();
  //       this.tosterService.error('Unable to find Apartment');
  //     });
  //   }
  //   else {
  //     this.loader.start();
  //     this.apartService.getActiveOfficeByArea(Aid,'PO').subscribe((res: any) => {
  //       this.AppOff = res;
  //       this.loader.stop();
  //     }, err => {
  //       this.loader.stop();
  //       this.tosterService.error('Unable to find Apartment');
  //     });
  //   }
  // }
  // getAllActiveCity() {
  //   this.cityService.getAllActiveCity().subscribe(res => {
  //     this.city2 = res;
  //   });
  // }


