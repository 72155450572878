import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { environment } from './../../environments/environment';
import { catchError} from 'rxjs/operators';
@Injectable({
  providedIn: 'root'
})
export class AreaService {
  httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json'})
  };
  constructor(private http: HttpClient) { }
  getAreById(Cid: any) {
   const url = 'location/allArea/';
   return this.http.get<any[]>(environment.baseUrl + '' + url + Cid );
  }
  addArea(data: any) {
    const url = 'location/addareas';
    return this.http.post(environment.baseUrl + '' + url, data, this.httpOptions);
  }
  activeArea(data: any) {
    const url = 'location/updateAreaActive/';
    return this.http.put(environment.baseUrl + '' + url + '' + data.area_id , data);
  }
  getPinByArea(aID: number) {
    const url = 'location/pinbyid/';
    return this.http.get(environment.baseUrl + '' + url + aID);
  }
  getActiveAreaByID(cityId: number) {
    const url = 'location/activeArea/';
    return this.http.get<any[]>(environment.baseUrl + '' + url + cityId);
  }
}
