import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { environment } from './../../environments/environment';
import { catchError} from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class BookingService {
  httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json'})
  };
  constructor(private http: HttpClient) { }
  getBookings() {
    const url = 'booking/allBooking';
    return this.http.get<any[]>(environment.baseUrl + '' + url);
  }
  getBookingById(Bid: any) {
    const url = 'booking/BookingById/';
    return this.http.get<any[]>(environment.baseUrl + '' + url + '' + Bid);
  }
  getBookingByFilter(sid: number, cid: number, aid: number, fdate: Date, tdate: Date) {
    const url = '/booking/allBooking/';
    return this.http.get<any[]>(environment.baseUrl + url + sid + '/' + cid + '/' + aid + '/' + fdate + '/' + tdate);
  }
}
