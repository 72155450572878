import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { BehaviorSubject, forkJoin, of, Observable, throwError } from 'rxjs';
import { environment } from './../../environments/environment';
import { tap, catchError } from 'rxjs/operators';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { ToastrService } from 'ngx-toastr';

@Injectable({
  providedIn: 'root'
})
export class SubAdminService {
  phoneOtp: number;
  mailOtp: number;
  httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' })
  };
  constructor(
    private http: HttpClient,
    private loader: NgxUiLoaderService,
  ) { }
  getAdmin() {
    const url = 'admin/alladmin';
    return this.http.get<any[]>(environment.baseUrl + '' + url);
  }
  activeAdmin(data: any) {
    const url = 'admin/updateAdminActive/';
    return this.http.put(environment.baseUrl + '' + url + '' + data.user_id, data);
  }
  getAdminById(id: any) {
    const url = 'admin/admin/';
    return this.http.get<any[]>(environment.baseUrl + '' + url + id);
  }
  updateAdmin(data: any) {
    const url = 'admin/updateAdmin/';
    return this.http.put(environment.baseUrl + '' + url + data.user_id, data).pipe(tap(res => {
      this.loader.stop();
    }, err => {
      this.loader.stop();
    }));
  }
  checkDuplicateUserEmail(data: any) {
    const url = 'lr/profile/checkDuplicate';
    return this.http.post(environment.baseUrl + url, data).pipe(tap(res => {
      this.loader.stop();
    }, err => {
      this.loader.stop();
    }));
  }
  checkDuplicateUserMob(data: any) {
    const url = 'lr/profile/checkDuplicate';
    return this.http.post(environment.baseUrl + url, data).pipe(tap(res => {
      this.loader.stop();
    }, err => {
      this.loader.stop();
    }));
  }
  sendPhoneOTP(phoneNumber: number) {
    const url = 'lr/OTP/';
    return this.http.get(environment.baseUrl + '' + url + phoneNumber).pipe(tap(res => {
      // this.loader.stop();
    }, err => {
      console.log(err);
      // this.loader.stop();
    }));
  }
  sendEmailOTP(email: string) {
    const url = 'lr/emailotp';
    let mail = { 'email': email };
    return this.http.post(environment.baseUrl + '' + url, mail).pipe(tap(res => {
      // this.loader.stop();
    }, err => {
      console.log(err);
      // this.loader.stop();
    }));
  }
  veryfyPhoneOTP(admin: number) {
    const url = 'lr/verifyPhone/';
    return this.http.put(environment.baseUrl + '' + url + admin, '');
  }
  veryfyMailOTP(admin: number) {
    const url = 'lr/verifyEmail/';
    return this.http.put(environment.baseUrl + '' + url + admin, '');
  }
  uploadIdProof(userID: number, idProof: File) {
    const url = 'lr/updatedoc/';
    const formData = new FormData();
    formData.append('files', idProof, idProof.name);
    console.log(idProof);
    return this.http.put(environment.baseUrl + '' + url + userID, formData).pipe(tap(res => {
      this.loader.stop();
      console.log('Upload', res);
    }, err => {
      this.loader.stop();
    }));
  }

  // ADMIN CREATION API 
  // 1. ADMIN WITH USER : http://dev01.ohhpro.com:9080/ohhpro_admin/admin/lr/register/registerAdminWithUser
  // http://dev01.ohhpro.com:9080/ohhpro_admin/admin/lr/register1/registerAdminWithUser
  createAdminWithUser(data: any) {
    const url = 'lr/register/registerAdminWithUser';
    return this.http.post(environment.baseUrl + '' + url, data);
  }

  // 2. only admin : http://dev01.ohhpro.com:9080/ohhpro_admin/admin/lr/register/subAdmin
 
  createAdmin(data: any) {
    const url = 'lr/register/subAdmin';
    return this.http.post(environment.baseUrl + '' + url, data);
  }

  filterAdmin(s_id, c_id, a_id, a_type, sta) {
    const url = 'admin/filterAdmin/';
    return this.http.get<any[]>(environment.baseUrl + url + s_id + '/' + c_id + '/' + a_id + '/' + a_type + '/' + sta);
  }
  checkDuplicate(data: any) {
    const url = 'lr/edit/profile/checkDuplicateAdminProfile';
    return this.http.put(environment.baseUrl + url, data).pipe(tap(res => {
      this.loader.stop();
    }, err => {
      this.loader.stop();
    }));
  }

}
