import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { environment } from './../../environments/environment';
import { catchError} from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class HouseService {
  httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json'})
  };
  constructor(private http: HttpClient) { }
  addHouse(data: any) {
    const url = 'appoff/addHouse';
    return this.http.post(environment.baseUrl + '' + url, data, this.httpOptions);
  }
  getHouseByFloor(FId: any) {
    const url = 'appoff/house/';
    return this.http.get<any[]>(environment.baseUrl + '' + url + FId);
  }
  activeHouse(data: any) {
    const url = 'appoff/updateHouseActive/';
    return this.http.put(environment.baseUrl + '' + url + data.id, data);
  }
}
