import { MerchantService } from 'src/app/services/merchant.service';
import { HttpClient } from '@angular/common/http';
import { Component,OnInit,ViewChild } from '@angular/core';
import { DataTableDirective } from 'angular-datatables';
import {FormBuilder,FormGroup, Validators } from '@angular/forms';
import { Subject } from 'rxjs';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { ToastrService } from 'ngx-toastr';


@Component({
  selector: 'app-category',
  templateUrl: './category.component.html',
  styleUrls: ['./category.component.css']
})
export class CategoryComponent implements OnInit {
  @ViewChild(DataTableDirective, { static: false })
    dtElement: DataTableDirective;
    isDtInitialized: boolean = false;
    dtOptions: DataTables.Settings = {};
    dtTrigger: Subject<any> = new Subject();
  ShopCategory: any[];
  super_cat: any[];
  categori: any;
  a: any[];
  categoryForm: FormGroup;
  imageSrc: any;
  ngOnDestroy(): void {
    this.dtTrigger.unsubscribe();
  }
  constructor(
    private http:HttpClient,
    private loader: NgxUiLoaderService, 
    private tosterService: ToastrService,
    private merchantService:MerchantService,
    private fb: FormBuilder
   ) {
       
      this.categoryForm=this.fb.group({
        merchant_super_id: [, Validators.required],
        product_category_name: ['', Validators.required],
        product_category_pic: ['', Validators.required], 
    })
    this.loader.start();
    this.merchantService.getShopCategoryData().subscribe(data=>{
      console.log(data);
      this.ShopCategory=data;
    }) 
    this.loader.stop();
   
  }
  ngOnInit(){}
  getSuperCategory(merchant_shop_category_id:any){
    this.loader.start();
    this.merchantService.SuperCategory(merchant_shop_category_id).subscribe(data=>{
      this.a=data;this.loader.stop();
    },
    error => {
      this.loader.stop();
      this.tosterService.error("Unable to load")
    });
  }
  getProductCategory(merchant_super_id){
        this.loader.start();
        this.merchantService.product_category(merchant_super_id).subscribe(data=>{this.loader.stop();this.categori=data;},
        error => {
          this.loader.stop();
          this.tosterService.error("Unable to load")
        });
  }
  submit(formdata: any) {
    this.loader.start();
    this.merchantService.addCategory(formdata).subscribe((res:any)=>{
      this.loader.stop();
      this.tosterService.success(res.message);
    },
      error => {
        this.loader.stop();
        this.tosterService.error("Unable to add category")
    });
  
  }
  handleInputChange(e) {
    var file = e.dataTransfer ? e.dataTransfer.files[0] : e.target.files[0];
    var pattern = /image-*/;
    var reader = new FileReader();
    if (!file.type.match(pattern)) {
      alert('invalid format');
      return;
    }
    reader.onload = this._handleReaderLoaded.bind(this);
    reader.readAsDataURL(file);
  }
  _handleReaderLoaded(e) {
    let reader = e.target;
    this.imageSrc = reader.result;
    console.log(this.imageSrc)
  }

}
